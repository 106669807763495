/**
 * Components > demoBlock
 * =================================
 * Component used to display demo markup on documentation pages
 */


.demo {
    position: relative;
    margin-top: spacing(x2);
    margin-bottom: spacing(x6);

    & + .demo {
        margin-top: - spacing(x5);
    }
}

    .demo-title {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        margin-top: 0;
        padding: 6px 10px;
        background-color: $red;
        color: $white;

        @include font-size(12);
    }

    .demo-visual {
        border: 1px solid $color-border--interactive;
        padding: spacing(x5) spacing(x5);
        position: relative;
    }

    .demo-visual--noSpacing {
        padding: 0;
    }

    .demo-visual--spacingBottom {
        padding-bottom: 160px;

        @include media('<mid') {
            padding-bottom: spacing(x5);
        }
    }

    .demo-visual--spacingTopAndBottom {
        padding: spacing(x5) 0;
    }

    .demo-code {
        margin-bottom: 0;

        pre {
            margin: 0;
        }
    }


.o-btn--codeToggle {
    width: 100%;
    border-radius: 0;
    border: 1px solid $color-border--interactive;
    border-top: 0;
    background-color: $grey--offWhite;

    &.is-clicked {
        border-bottom: 0;
    }
}
