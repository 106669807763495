/* PrismJS 1.11.0
http://prismjs.com/download.html?themes=prism&languages=markup+css+clike+javascript+bash+scss */
/**
 * prism.js default theme for JavaScript, CSS and HTML
 * Based on dabblet (http://dabblet.com)
 * @author Lea Verou
 */

code[class*='language-'],
pre[class*='language-'] {
    color: black;
    background: none;
    text-shadow: 0 1px white;
    font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
    line-height: 1.5;

    tab-size: 4;
    hyphens: none;
}


pre[class*='language-']::selection,
pre[class*='language-'] ::selection,
code[class*='language-']::selection,
code[class*='language-'] ::selection {
    text-shadow: none;
    background: #b3d4fc;
}

@media print {
    code[class*='language-'],
    pre[class*='language-'] {
        text-shadow: none;
    }
}

/* Code blocks */
pre[class*='language-'] {
    padding: 1em;
    margin: 1em 0;
    overflow: auto;
}

:not(pre) > code[class*='language-'],
pre[class*='language-'] {
    background: #f5f2f0;
}

/* Inline code */
:not(pre) > code[class*='language-'] {
    padding: 0.1em;
    border-radius: 0.3em;
    white-space: normal;
}

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
    color: slategray;
}

.token.punctuation {
    color: #999;
}

.namespace {
    opacity: 0.7;
}

.token.property,
.token.tag,
.token.boolean,
.token.number,
.token.constant,
.token.symbol,
.token.deleted {
    color: #905;
}

.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin,
.token.inserted {
    color: #690;
}

.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string {
    color: #a67f59;
    background: hsla(0, 0%, 100%, 0.5);
}

.token.atrule,
.token.attr-value,
.token.keyword {
    color: #07a;
}

.token.function {
    color: #dd4a68;
}

.token.regex,
.token.important,
.token.variable {
    color: #e90;
}

.token.important,
.token.bold {
    font-weight: bold;
}
.token.italic {
    font-style: italic;
}

.token.entity {
    cursor: help;
}


/**
 * Define associated Code variables - not Prism CSS
 */
$code-textColor               : #444;
$code-bgColor                 : #f9f8f8;
$pre-textColor                : #444;
$pre-bgColor                  : #f9f8f8;

// Inline and block code styles
code,
pre {
    border: 1px solid $color-border--interactive;
    padding: 10px;
    font-family: $font-family-mono;
    @include font-size(base, false);
    color: $code-textColor;
    tab-size: 3;
    overflow: auto;
}

// Inline code
code {
    padding: 2px 4px;
    color: $code-textColor;
    background-color: $code-bgColor;
    white-space: nowrap;
}

// Blocks of code
pre {
    display: block;
    padding: floor($baseline / 2);
    margin: spacing(x2) 0 $baseline;
    // line-height: $leading-base;
    color: $pre-textColor;
    background-color: $pre-bgColor;
    white-space: pre;
    // white-space: pre-wrap;
    // word-break: break-all;
    // word-wrap: break-word;

    // Account for some code outputs that place code tags in pre tags
    code {
        padding: 0;
        color: $pre-textColor;
        background-color: inherit;
        border: 0;
    }
}

// Enable scrollable blocks of code
.pre--scrollable {
    max-height: 340px;
    overflow-y: scroll;
}
