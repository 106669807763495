/**
 * Base > Type
 * =================================
 * Type definitions
 * Specific to styleguide
 */


// Major section title – i.e. homepage of docs
.sg-sectionTitle {
    // used for putting a portion of the title on its own line
    > span {
        display: block;
    }

    @include media('>=mid') {
        @include font-size(50);
    }

    & + .sg-text {
        margin-top: spacing(x2);
    }
}

.sg-index {
    ul {
        & ul {
            margin-top: spacing();
        }
    }

    a {
        // color: currentColor;
        text-decoration: underline;

        &:hover {
            color: $color-link-hover;
        }
    }
}

// bump up the font-size for documentation text
.sg-content {
    > p,
    > ul,
    > ol,
    > ul p,
    > ol p {
        @include font-size(base--scaleUp);
    }
    > p {
        max-width: 50em;

        & > code {
            display: inline-flex;
        }
    }
    > ul,
    > ol {
        line-height: 1.2;

        li {
            margin-bottom: 0;
            max-width: 50em;
        }
    }

    > p > img,
    > ul img,
    > ol img {
        display: block;
        border: 1px solid $grey--light;
        margin: spacing(x3) auto 0;
    }
}

.sg-interfaceType {
    font-weight: 300;
}

.sg-interfaceType--subtle {
    color: #888;
}

.sg-sectionHeading {
    color: $styleguide-color-primary;
    padding: spacing(x2) 0;
    border-top: 1px solid $grey--light;
    border-bottom: 1px solid $grey--light;
}
h2.sg-sectionHeading:first-child {
    margin-top: 0;
    border-top: 0;
    padding-top: 0;
}
h3.sg-sectionHeading {
    border-top: 0;
}

.sg-sectionHeading--main {
    padding: 6vh 10px;
    text-align: center;
}


/**
 * Typography
 */
.sg-type {
    margin-bottom: 1em;
}


@mixin sgTypeTest($size) {
    .sg-type-item {
        @include font-size($size, false);
    }

    code {
        &:after { content: ' :: #{$size}px'; }
    }
}


.sg-type-small { @include sgTypeTest(small); }
.sg-type-base { @include sgTypeTest(base); }
.sg-type-mid { @include sgTypeTest(mid); }
.sg-type-large { @include sgTypeTest(large); }
.sg-type-jumbo { @include sgTypeTest(jumbo); }

.sg-typeSpecimen {
    margin-bottom: 0;
    margin-top: 0;
    -ms-word-wrap: break-word;
    word-wrap: break-word;

    &:after {
        display: block;
        @extend .sg-interfaceType;
        @extend .sg-interfaceType--subtle;
        @include font-size(16);
        margin-top: 5px;
        border-top: 1px solid #ddd;
        font-weight: normal;
    }
}

// h1.typeSpecimen:after { content: 'Font stack: #{$font-family-headings}'; }
// p.typeSpecimen:after  { content: 'Font stack: #{$font-family-base}'; }

.sg-typeSpecimen--primary {
    @include font-size(30);
    font-family: $font-family-headings;
    font-weight: $font-weight-headings;
}

.sg-typeSpecimen--secondary {
    @include font-size(30);
    font-family: $font-family-base;
}

.sg-typeSpecimen--giant {
    @include font-size(120);
    line-height: 1;
}
