/**
 * Components > Nav
 * =================================
 * Stylguide specific Navigation styles
 */

$subnav-padding-bottom: spacing();
$subnav-padding-top: spacing();
$subnav-padding-side: spacing();
$subnav-border-thickness: 3px;

.c-header-wrap {
    flex-wrap: wrap;
}

.c-logo--sg,
.c-nav--sg {
    @include media('>=mid', '<=wide') {
        flex-basis: 100%;
        justify-content: center;
    }
}
.c-logo--sg > a {
    justify-content: center;
}

.nav--sg {
    float: none;
}

.sg-subnav {
    @include font-size();

    @include media('<=mid') {
        flex-basis: 100%;
        justify-content: left;
    }
}
    .sg-subnav-item {
        margin-left: spacing();

        @include media('<=mid') {
            margin-left: 0;
        }

        a {
            display: block;
            padding: $subnav-padding-top $subnav-padding-side $subnav-padding-bottom;
            color: $grey--dark;
            border-bottom: $subnav-border-thickness solid transparent;
            text-decoration: none;
            font-weight: $font-weight-bold;
        }
        a:hover,
        a:focus,
        &.is-active a {
            border-bottom: $subnav-border-thickness solid $color-primary;
            text-decoration: none;
        }
    }


.sg-secondaryBar {
    background-color: $white;
    padding: spacing() 0 0;
    margin-bottom: spacing();
}
    .sg-secondaryBar-title {
        margin-top: 0;
        line-height: 1.8;
    }

/*
   Sidebar Nav
   ========================================================================== */

.sg-sideNav {
    font-family: $font-family-headings;
    background-color: $grey--lightest;

    & a {
        background-color: $white;
        display: block;
        border-left: 3px solid transparent;
        margin: 1px 0;
        padding: 0.4em 0.8em 0.5em;
        color: $black;
        text-decoration: none;
    }
    & .is-active,
    & a:hover {
        border-left: 3px solid $color-primary;
    }

    & a.is-incomplete {
        background-color: $grey--light;
        cursor: default;
        &:hover {
            border-left: 3px solid transparent;
        }
        &:focus {
            outline: none;
        }
    }
}

    .sg-sideNav-subnav {
        margin-top: 0;
        border-left: 15px solid $grey--lightest;
    }
