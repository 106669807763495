/**
 * Components > list--tiled
 * =================================
 * Creates a tiled horizontal list
 * E.g. On the homepage  of the documentation site
 */

$tile-yellow    : #f3cc2c;
$tile-red       : #eb6015;
$tile-blue      : #396ab2;
$tile-green     : #3da432;
$tile-lightblue : #7fc6ef;
$tile-orange    : #f7b64e;

.list--tiled {
    margin-top: spacing(x2);

    & > li {
        margin-bottom: spacing(x2);
    }
}
    .list--tiled-item {
        color: #fff;

        &:nth-child(1) .list--tiled-link {
            background-color: $tile-yellow;

            &:hover {
                background-color: darken($tile-yellow, 10%);
            }
        }
        &:nth-child(2) .list--tiled-link {
            background-color: $tile-red;

            &:hover {
                background-color: darken($tile-red, 10%);
            }
        }
        &:nth-child(3) .list--tiled-link {
            background-color: $tile-blue;

            &:hover {
                background-color: darken($tile-blue, 10%);
            }
        }
        &:nth-child(4) .list--tiled-link {
            background-color: $tile-green;

            &:hover {
                background-color: darken($tile-green, 10%);
            }
        }
        &:nth-child(5) .list--tiled-link {
            background-color: $tile-lightblue;

            &:hover {
                background-color: darken($tile-lightblue, 10%);
            }
        }
        &:nth-child(6) .list--tiled-link {
            background-color: $tile-orange;

            &:hover {
                background-color: darken($tile-orange, 10%);
            }
        }
    }

    .list--tiled-item--dark {
        color: #333;
    }

        .list--tiled-link {
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 200px;

            font-family: $font-family-headings;
            @include font-size(mid, false);
            color: currentColor;
            text-decoration: none;
            text-align: center;

            @include media('>=mid') {
                min-height: 300px;
            }

            &:hover {
                text-decoration: none;
                color: currentColor;
            }
        }
