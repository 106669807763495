@charset "UTF-8";
/**
 * Setup styleguide imports
 */
/**
 * Setup styleguide imports
 */
/**
 * Just Eat Styleguide
 * =================================
 */
/**
 * Just Eat Styleguide
 * =================================
 */
/**
 * px to em converter
 * =================================
 * Convert pixels to ems
 *
 * Usage:
 * font-size : em(12);
 * font-size : em(12, 24); // If the parent is another value say 24px
 */
/**
 * Convert a map of breakpoints to ems
 * =================================
 * Takes a map of breakpoints (in px) and a base-font-size
 *
 * Usage:
 $breakpoints: map-to-em((
 	narrow : 400px,
 	mid    : 750px,
 	wide   : 1000px,
 	huge   : 1250px
 ), 16);
 */
/**
 * Strip units
 * =================================
 * Remove the unit of a measurement
 *
 * Usage:
 * strip-units(400px)  > 400
 */
/* stylelint-disable indentation */
/* stylelint-enable */
/* stylelint-disable indentation */
.c-screen-sizer {
  display: none;
  content: "tiny:375px,narrow:414px,narrowMid:600px,mid:768px,wide:1025px,huge:1280px"; }

/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Correct the line height in all browsers.
     * 2. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  line-height: 1.15;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Chrome 57- and Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Correct the padding in Firefox.
     */
fieldset {
  padding: 0.35em 0.75em 0.625em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

/**
 * Just Eat Reset Styles
 * =================================
 * We use normalize.css for the bulk of our 'reset'
 * but anything else 'reset-y' lives in here
 */
html {
  box-sizing: border-box; }

*,
*:before,
*:after {
  box-sizing: inherit; }

/**
 * Typography
 * =================================
 * - Base
 * - Paragraphs
 * - Headings
 * - Type sizes
 * - Misc
 * - Utilities
 *
 * Body font size, leadings etc have been set in _variables.scss
 *
 * Documentation:
 * TBC
 */
/**
 * Typography preloading styles
 */
html {
  font-size: 14px;
  text-size-adjust: 100%; }

body {
  font-family: "Hind Vadodara", "Helvetica Neue", Helvetica, sans-serif;
  font-size: 14px;
  font-size: 1rem;
  line-height: 1.29;
  color: #535353;
  font-weight: 300;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased; }
  .is-fontsLoading--base body {
    font-family: "Helvetica Neue", Helvetica, sans-serif !important; }

/**
 * Paragraphs
 */
p {
  font-family: "Hind Vadodara", "Helvetica Neue", Helvetica, sans-serif;
  font-size: 14px;
  font-size: 1rem;
  line-height: 1.29;
  margin-top: 16px;
  margin-bottom: 0; }
  .is-fontsLoading--base p {
    font-family: "Helvetica Neue", Helvetica, sans-serif !important; }

/**
 * Headings
 */
h1,
h2,
h3,
h4,
h5,
h6,
.alpha,
.beta,
.gamma,
.delta,
.epsilon {
  color: #333;
  margin: 0;
  margin-bottom: 0;
  font-family: "Ubuntu";
  font-weight: 300; }
  h1 small,
  h2 small,
  h3 small,
  h4 small,
  h5 small,
  h6 small,
  .alpha small,
  .beta small,
  .gamma small,
  .delta small,
  .epsilon small {
    font-weight: normal; }
  .is-fontsLoading--heading h1, .is-fontsLoading--heading
  h2, .is-fontsLoading--heading
  h3, .is-fontsLoading--heading
  h4, .is-fontsLoading--heading
  h5, .is-fontsLoading--heading
  h6, .is-fontsLoading--heading
  .alpha, .is-fontsLoading--heading
  .beta, .is-fontsLoading--heading
  .gamma, .is-fontsLoading--heading
  .delta, .is-fontsLoading--heading
  .epsilon {
    font-family: "Helvetica Neue", Helvetica, sans-serif !important;
    font-weight: 400; }

h1,
.alpha {
  font-size: 24px;
  font-size: 1.71429rem;
  line-height: 1.17;
  margin-top: 32px;
  margin-bottom: 16px; }
  @media (min-width: 48.01em) {
    h1,
    .alpha {
      font-size: 32px;
      font-size: 2.28571rem;
      line-height: 1.25; } }
  h1:first-child,
  .alpha:first-child {
    margin-top: 0; }

h2,
.beta {
  font-size: 20px;
  font-size: 1.42857rem;
  line-height: 1.2; }
  @media (min-width: 48.01em) {
    h2,
    .beta {
      font-size: 24px;
      font-size: 1.71429rem;
      line-height: 1.17; } }

h3,
.gamma,
h4,
.delta,
h5,
.epsilon {
  font-size: 20px;
  font-size: 1.42857rem;
  line-height: 1.2; }

h6,
.zeta {
  font-size: 12px;
  font-size: 0.85714rem;
  line-height: 1.33; }

* + h2,
* + .beta {
  margin-top: 32px; }

* + h3,
* + .gamma,
* + h4,
* + .delta {
  margin-top: 16px; }

small {
  font-size: 80%; }

/**
 * Miscellaneous
 */
strong,
b {
  font-weight: 500; }

em,
i {
  font-style: italic; }

abbr[title] {
  border-bottom: 1px dotted #ddd;
  cursor: help; }

/**
 * Blockquotes
 */
blockquote {
  padding-left: 10px;
  margin: 16px;
  border-left: 4px solid #cccccc; }
  blockquote p {
    margin-bottom: 0;
    font-size: 14px;
    font-size: 1rem;
    line-height: 1.29;
    font-weight: 300; }
  blockquote small {
    display: block;
    color: #b3b3b3; }
    blockquote small:before {
      content: '\2014 \00A0'; }

q:before, q:after,
blockquote:before,
blockquote:after {
  content: ''; }

cite {
  font-style: normal; }

dfn {
  font-style: italic; }

mark {
  background: #ffd700;
  padding: 2px 4px;
  border-radius: 3px; }

address {
  font-style: normal;
  margin-top: 16px;
  margin-bottom: 0; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

samp {
  font-family: Menlo, Monaco, "Courier New", monospace; }

hr {
  margin: 16px 0;
  border: 0;
  border-top: 1px solid #eaeaea;
  border-bottom: 1px solid #fff; }

.hyphenate {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto; }

/**
 * The Kickoff Flexbox Grid
 * =================================
 * Default grid classes
 * Grid gutter options
 * Modifier Classes (column height and cell alignment)
 * Legacy fallbacks for flexbox
 * Grid span classes
 * Breakpoint stacking
 */
/**
 * Colour functions
 */
/*
$color-palette: (
  white: (
    base: #ffffff
  ),

  sky: (
    lighter: #f9fafb,
    light: #f4f6f8,
    base: #dfe3e8,
    dark: #c4cdd5
  )
);
*/
/**
 * Color
 * Returns the color value for a given color name and group.
 * HT Shopify Polaris team
 *
 * @param {String} $hue - The color's hue.
 * @param {String} $value - The darkness/lightness of the color. Defaults to base.
 * @param {Color} $for-background - The background color on which this color will
 * appear. Applies a multiply filter to ensure appropriate contrast.
 * @return {Color} The color value.
 *
 * Usage (based on the Sass map above):
 * ko-color(white)
 * ko-color(sky, dark)
 * ko-color(sky, lighter)
 *
 */
/**
 * Darkens the foreground color by the background color. This is the same as the
 * “multiply” filter in graphics apps.
 *
 * @param {Color} $foreground - The color to darken.
 * @param {Color} $background - The background to base darkening on.
 * @return {Color} The modified color.
 */
/**
 * Tint
 * Add percentage of white to a color
 * Usage:
 * background-color: ko-color-tint(blue, 20%);
 */
/**
 * Shade
 * Add percentage of black to a color
 * Usage:
 * background-color: ko-color-shade(blue, 20%);
 */
/**
 * Usage:
 *
 * ko-getVal(100, $map) - uses an integer
 * ko-getVal(mid, $map) - uses a Sass map
 * ko-getVal($foo, $map) - uses a Sass variable
 */
/**
 * Get breakpoints
 *
 * ko-bp()
 *
 * Usage:
 * ko-bp(100) - uses an integer
 * ko-bp(mid) - uses the $breakpoints Sass map
 * ko-bp($foo) - uses a Sass variable
 */
/**
 * Get type size
 *
 * ko-type()
 *
 * Usage:
 * ko-type(100) - uses an integer
 * ko-type(mid) - uses the $type Sass map
 * ko-type($foo) - uses a Sass variable
 */
/**
 * Get font-family
 *
 * ko-font()
 *
 * Usage:
 * ko-font(system) - uses 'system' font stack
 * ko-font(sans) - uses the 'sans' font stack
 */
/**
 * Get z-index value
 *
 * ko-zIndex()
 *
 * Usage:
 * ko-zIndex(low) - uses 'low' z-index value
 * ko-zIndex(mid) - uses 'mid' z-index value
 */
/**
 * Usage:
 *
 $grid-configuration: (
   'columns': 12,
   'layouts': (
     'small': 800px,
     'medium': 1000px,
     'large': 1200px,
   ),
 );

 div {
   font-size: ko-map-deep-get($grid-configuration, 'columns');
   width: ko-map-deep-get($grid-configuration, 'layouts', 'medium');
 }
 */
/**
 * Modular scale
 * =================================
 * Usage: ko-modular-scale($font-size-base, 3, $type-scale)
 * or with the shorthand version: ko-ms(3)
 */
/**
 * Shorthand modular scale
 * Uses the $font-size-base and $type-scale framework variables so that you
 * only need to provide the scale increment
 *
 * e.g. ko-ms(5)
 */
/**
 * Multiply
 * =================
 * Multiply any value
 *
 * Usage:
 * ko-multiply(15, 2)
 * ko-multiply($baseline, 1.5)
 */
/**
 * Sass Mixins
 * - Responsive
 * - Dimensions
 * - Positioning
 * - Utility
 * - Forms
 * - Module Naming Helpers
 */
/**
 * Responsive media query mixins
 * - These are used so that we can define separate media-query content
 * - Arguments can use:
 *   - a named breakpoint in the $breakpoints map, e.g. mid
 *   - a pixel value e.g. 500px
 *   - a unitless value that is eqivalent to a pixel e.g. 500
 */
/**
 * Min-width media query:
 * - Equivalent to: @media screen and (min-width: 20em) { ... }
 * - Usage: @include ko-respond-min(mid) { ... };
 */
/**
 * Max-width media query:
 * - Equivalent to: @media screen and (max-width: 20em) { ... }
 * - Usage: @include ko-respond-max(mid) { ... };
 */
/**
 * Min-max-width media query:
 * - Equivalent to: @media screen and (min-width: 10em) and (max-width: 20em) { ... }
 * - Usage: @include ko-respond-min-max(narrow, 600) { ... };
 */
/**
 *  HiDPI mixin.
 *  @include ko-hidpi { ... };
 *  Default value set to 1.3 to target Google Nexus 7 (http://bjango.com/articles/min-device-pixel-ratio/)
 */
/**
 * Hidpi with a minimum width media query
 * @include ko-hidpi-min(bp(mid), 1.5) { ... };
 */
/**
 * Hidpi with a max width media query
 * @include ko-hidpi-max(bp(mid), 1.3) { ... };
 */
/**
 * Dimensions-based mixins
 * - REM calculation
 * - Typography mixins based on the REM mixin
 *   - font-size
 *   - line-height
 *   - font-size-ems
 */
/**
 * Typography mixins
 */
/**
 * ko-position
 * Usage:
 * @include ko-position(absolute, 10px 20px 30px 10px);
 */
/**
 * vertical-center
 * Vertically center any element. Needs support for CSS tranforms.
 * Usage:
 * @include ko-vertical-center;
 */
/**
 * Utility Mixins
 * - clearfix
 * - font-face
 * -
 * - sizing shortcuts
 *   - size
 *   - square
 * - resizable
 */
/**
 * Sizing shortcuts
 */
/**
 * bemify [modified for Kickoff]
 * https://gtihub.com/franzheidl/bemify
 * Franz Heidl 2015
 *
 * Usage: https://gist.github.com/mrmartineau/0cd2010bf265d712bafb
 */
/* Import Grid helpers and mixins */
/**
 * Grid Variables
 * =================================
 * These are defined here so that the grid can run as a standalone module (outside of Kickoff),
 * as well as allowing these files to be generated into standard CSS.
 *
 * When importing the grid into your own project, copy the variables from this file into your own variables.scss
 * They will then override the values specified here (as long as the grid is imported below where your own variables are defined).
 */
/**
 * Kickoff grid helpers
 */
/**
 * Grid stacking
 * Stack grids based on a given breakpoint
 *
 * Usage:
 * .g--stack--narrow
 * .g--stack--wide
 */
/**
 * Gutter Calculations
 * Default: percent
 * Usage: ko-gutterCalc() or ko-gutterCalc(false)
 * When show-unit is true, returns the percentage, otherwise return a decimal
 */
/**
 * Grid columns width calculations
 * This is where the magic of working out the column widths happens
 *
 * $column-span: define the width for the number of columns required
 * $show-unit: Switch return value between percentage (default) and decimal
 * $include-gutters: if gutters should be included in the calculations. Default = false
 * $legacy-calc: if we are working out a legacy column width calculation, or not. Default = false
 */
/**
 * Column width mixin
 * Usage:
 * @include column(2);
 */
/**
 * Basic Usage:
 * =================================
	<div class="g">
		<div class="g-col g-span4 g-span6--mid"></div>
		<div class="g-col g-span8 g-span6--mid"></div>
	</div>
 */
.g {
  display: flex !important;
  flex-wrap: wrap;
  flex-direction: row; }
  .g img {
    max-width: 100%; }

.g-col {
  display: block;
  box-sizing: border-box;
  flex: 1 0 0;
  min-width: 0; }

/**
 * Gutter calcs – applied to grid columns in our grid (direct descendants only)
 * Default: pixels (can look at changing to percentage)
 * Usage: gutterCalc() or gutterCalc(false)
 */
.g--gutter {
  margin-left: -8px;
  margin-right: -8px; }
  .g--gutter > .g-col {
    padding-left: 8px;
    padding-right: 8px; }

@media (min-width: 23.4375em) {
  .g--gutter--tiny {
    margin-left: -8px;
    margin-right: -8px; }
    .g--gutter--tiny > .g-col {
      padding-left: 8px;
      padding-right: 8px; } }

@media (min-width: 25.875em) {
  .g--gutter--narrow {
    margin-left: -8px;
    margin-right: -8px; }
    .g--gutter--narrow > .g-col {
      padding-left: 8px;
      padding-right: 8px; } }

@media (min-width: 37.5em) {
  .g--gutter--narrowMid {
    margin-left: -8px;
    margin-right: -8px; }
    .g--gutter--narrowMid > .g-col {
      padding-left: 8px;
      padding-right: 8px; } }

@media (min-width: 48em) {
  .g--gutter--mid {
    margin-left: -8px;
    margin-right: -8px; }
    .g--gutter--mid > .g-col {
      padding-left: 8px;
      padding-right: 8px; } }

@media (min-width: 64.0625em) {
  .g--gutter--wide {
    margin-left: -8px;
    margin-right: -8px; }
    .g--gutter--wide > .g-col {
      padding-left: 8px;
      padding-right: 8px; } }

@media (min-width: 80em) {
  .g--gutter--huge {
    margin-left: -8px;
    margin-right: -8px; }
    .g--gutter--huge > .g-col {
      padding-left: 8px;
      padding-right: 8px; } }

.g--gutter--scaled > .g-col {
  margin-left: 1.25786%;
  margin-right: 0; }
  .g--gutter--scaled > .g-col:first-child {
    margin-left: 0; }

.g--stack > .g-col {
  flex-basis: 100%;
  max-width: 100%; }

.g--stack.g--gutter--scaled > .g-col {
  margin-left: 0; }

/**
 * .g--equalHeight – Equal Height Child Elements
 */
.g--equalHeight > .g-col {
  display: flex; }
  .g--equalHeight > .g-col > * {
    flex-basis: 100%; }

/**
 * Alignment
 * Modifier classes to move our grid elements around
 */
.g--alignTop {
  align-items: flex-start; }

.g--alignBottom {
  align-items: flex-end; }

.g--alignSelfBottom {
  align-self: flex-end; }

.g--alignRight {
  justify-content: flex-end; }

.g--alignCenter {
  justify-content: center; }

.g--alignCenter--v {
  align-items: center; }

/**
 * Centering
 * Centers an individual column, rather than the entire grid
 */
.g-col--centered {
  margin: 0 auto; }

/**
 * Shrinking Content
 * Shrink a .g-col down to only the space it needs (flexbox only)
 *
 * Effectively just changes it’s values back to the default flex properties
 */
.g-col--shrink {
  flex: 0 1 auto; }

/**
 * Add fallbacks for non-flexbox supporting browsers (for example, IE8/9)
 */
.no-flexbox .g {
  display: block !important; }
  .no-flexbox .g:after {
    content: "";
    display: table;
    clear: both; }

.no-flexbox .g-col {
  float: left;
  min-height: 1px;
  clear: none;
  box-sizing: border-box; }

.no-flexbox .g--stack .g-col {
  width: 100%; }

.no-flexbox .g--equalHeight > .g-col {
  display: block; }

/**
 * Grid Span classes (for different breakpoints)
 *
 * Applied by using .g-spanx to .g-col elements, where x is the number of columns
 */
.g-span1 {
  flex-basis: 8.33333% !important;
  flex-grow: 0;
  max-width: 8.33333% !important; }
  .g-span1.g-holdWidth {
    min-width: 106px; }
    .g--gutter--scaled .g-span1.g-holdWidth {
      min-width: 90px; }
  .g--gutter--scaled .g-span1 {
    flex-basis: 7.18029% !important;
    max-width: 7.18029%;
    margin-left: 1.25786%; }
  .no-flexbox .g-span1 {
    width: 8.33333% !important; }

.g-offset1 {
  margin-left: 8.33333% !important; }

.g-span2 {
  flex-basis: 16.66667% !important;
  flex-grow: 0;
  max-width: 16.66667% !important; }
  .g-span2.g-holdWidth {
    min-width: 212px; }
    .g--gutter--scaled .g-span2.g-holdWidth {
      min-width: 196px; }
  .g--gutter--scaled .g-span2 {
    flex-basis: 15.61845% !important;
    max-width: 15.61845%;
    margin-left: 1.25786%; }
  .no-flexbox .g-span2 {
    width: 16.66667% !important; }

.g-offset2 {
  margin-left: 16.66667% !important; }

.g-span3 {
  flex-basis: 25% !important;
  flex-grow: 0;
  max-width: 25% !important; }
  .g-span3.g-holdWidth {
    min-width: 318px; }
    .g--gutter--scaled .g-span3.g-holdWidth {
      min-width: 302px; }
  .g--gutter--scaled .g-span3 {
    flex-basis: 24.0566% !important;
    max-width: 24.0566%;
    margin-left: 1.25786%; }
  .no-flexbox .g-span3 {
    width: 25% !important; }

.g-offset3 {
  margin-left: 25% !important; }

.g-span4 {
  flex-basis: 33.33333% !important;
  flex-grow: 0;
  max-width: 33.33333% !important; }
  .g-span4.g-holdWidth {
    min-width: 424px; }
    .g--gutter--scaled .g-span4.g-holdWidth {
      min-width: 408px; }
  .g--gutter--scaled .g-span4 {
    flex-basis: 32.49476% !important;
    max-width: 32.49476%;
    margin-left: 1.25786%; }
  .no-flexbox .g-span4 {
    width: 33.33333% !important; }

.g-offset4 {
  margin-left: 33.33333% !important; }

.g-span5 {
  flex-basis: 41.66667% !important;
  flex-grow: 0;
  max-width: 41.66667% !important; }
  .g-span5.g-holdWidth {
    min-width: 530px; }
    .g--gutter--scaled .g-span5.g-holdWidth {
      min-width: 514px; }
  .g--gutter--scaled .g-span5 {
    flex-basis: 40.93291% !important;
    max-width: 40.93291%;
    margin-left: 1.25786%; }
  .no-flexbox .g-span5 {
    width: 41.66667% !important; }

.g-offset5 {
  margin-left: 41.66667% !important; }

.g-span6 {
  flex-basis: 50% !important;
  flex-grow: 0;
  max-width: 50% !important; }
  .g-span6.g-holdWidth {
    min-width: 636px; }
    .g--gutter--scaled .g-span6.g-holdWidth {
      min-width: 620px; }
  .g--gutter--scaled .g-span6 {
    flex-basis: 49.37107% !important;
    max-width: 49.37107%;
    margin-left: 1.25786%; }
  .no-flexbox .g-span6 {
    width: 50% !important; }

.g-offset6 {
  margin-left: 50% !important; }

.g-span7 {
  flex-basis: 58.33333% !important;
  flex-grow: 0;
  max-width: 58.33333% !important; }
  .g-span7.g-holdWidth {
    min-width: 742px; }
    .g--gutter--scaled .g-span7.g-holdWidth {
      min-width: 726px; }
  .g--gutter--scaled .g-span7 {
    flex-basis: 57.80922% !important;
    max-width: 57.80922%;
    margin-left: 1.25786%; }
  .no-flexbox .g-span7 {
    width: 58.33333% !important; }

.g-offset7 {
  margin-left: 58.33333% !important; }

.g-span8 {
  flex-basis: 66.66667% !important;
  flex-grow: 0;
  max-width: 66.66667% !important; }
  .g-span8.g-holdWidth {
    min-width: 848px; }
    .g--gutter--scaled .g-span8.g-holdWidth {
      min-width: 832px; }
  .g--gutter--scaled .g-span8 {
    flex-basis: 66.24738% !important;
    max-width: 66.24738%;
    margin-left: 1.25786%; }
  .no-flexbox .g-span8 {
    width: 66.66667% !important; }

.g-offset8 {
  margin-left: 66.66667% !important; }

.g-span9 {
  flex-basis: 75% !important;
  flex-grow: 0;
  max-width: 75% !important; }
  .g-span9.g-holdWidth {
    min-width: 954px; }
    .g--gutter--scaled .g-span9.g-holdWidth {
      min-width: 938px; }
  .g--gutter--scaled .g-span9 {
    flex-basis: 74.68553% !important;
    max-width: 74.68553%;
    margin-left: 1.25786%; }
  .no-flexbox .g-span9 {
    width: 75% !important; }

.g-offset9 {
  margin-left: 75% !important; }

.g-span10 {
  flex-basis: 83.33333% !important;
  flex-grow: 0;
  max-width: 83.33333% !important; }
  .g-span10.g-holdWidth {
    min-width: 1060px; }
    .g--gutter--scaled .g-span10.g-holdWidth {
      min-width: 1044px; }
  .g--gutter--scaled .g-span10 {
    flex-basis: 83.12369% !important;
    max-width: 83.12369%;
    margin-left: 1.25786%; }
  .no-flexbox .g-span10 {
    width: 83.33333% !important; }

.g-offset10 {
  margin-left: 83.33333% !important; }

.g-span11 {
  flex-basis: 91.66667% !important;
  flex-grow: 0;
  max-width: 91.66667% !important; }
  .g-span11.g-holdWidth {
    min-width: 1166px; }
    .g--gutter--scaled .g-span11.g-holdWidth {
      min-width: 1150px; }
  .g--gutter--scaled .g-span11 {
    flex-basis: 91.56184% !important;
    max-width: 91.56184%;
    margin-left: 1.25786%; }
  .no-flexbox .g-span11 {
    width: 91.66667% !important; }

.g-offset11 {
  margin-left: 91.66667% !important; }

.g-span12 {
  flex-basis: 100% !important;
  flex-grow: 0;
  max-width: 100% !important; }
  .g-span12.g-holdWidth {
    min-width: 1272px; }
    .g--gutter--scaled .g-span12.g-holdWidth {
      min-width: 1256px; }
  .g--gutter--scaled .g-span12 {
    flex-basis: 100% !important;
    max-width: 100%;
    margin-left: 0; }
  .no-flexbox .g-span12 {
    width: 100% !important; }

@media (min-width: 23.4375em) {
  .g-span1--tiny {
    flex-basis: 8.33333% !important;
    flex-grow: 0;
    max-width: 8.33333% !important; }
    .g-span1--tiny.g-holdWidth--tiny {
      min-width: 106px; }
      .g--gutter--scaled .g-span1--tiny.g-holdWidth--tiny {
        min-width: 90px; }
    .g--gutter--scaled .g-span1--tiny {
      flex-basis: 7.18029% !important;
      max-width: 7.18029%;
      margin-left: 1.25786%; }
    .no-flexbox .g-span1--tiny {
      width: 8.33333% !important; }
  .g-offset1--tiny {
    margin-left: 8.33333% !important; }
  .g-span2--tiny {
    flex-basis: 16.66667% !important;
    flex-grow: 0;
    max-width: 16.66667% !important; }
    .g-span2--tiny.g-holdWidth--tiny {
      min-width: 212px; }
      .g--gutter--scaled .g-span2--tiny.g-holdWidth--tiny {
        min-width: 196px; }
    .g--gutter--scaled .g-span2--tiny {
      flex-basis: 15.61845% !important;
      max-width: 15.61845%;
      margin-left: 1.25786%; }
    .no-flexbox .g-span2--tiny {
      width: 16.66667% !important; }
  .g-offset2--tiny {
    margin-left: 16.66667% !important; }
  .g-span3--tiny {
    flex-basis: 25% !important;
    flex-grow: 0;
    max-width: 25% !important; }
    .g-span3--tiny.g-holdWidth--tiny {
      min-width: 318px; }
      .g--gutter--scaled .g-span3--tiny.g-holdWidth--tiny {
        min-width: 302px; }
    .g--gutter--scaled .g-span3--tiny {
      flex-basis: 24.0566% !important;
      max-width: 24.0566%;
      margin-left: 1.25786%; }
    .no-flexbox .g-span3--tiny {
      width: 25% !important; }
  .g-offset3--tiny {
    margin-left: 25% !important; }
  .g-span4--tiny {
    flex-basis: 33.33333% !important;
    flex-grow: 0;
    max-width: 33.33333% !important; }
    .g-span4--tiny.g-holdWidth--tiny {
      min-width: 424px; }
      .g--gutter--scaled .g-span4--tiny.g-holdWidth--tiny {
        min-width: 408px; }
    .g--gutter--scaled .g-span4--tiny {
      flex-basis: 32.49476% !important;
      max-width: 32.49476%;
      margin-left: 1.25786%; }
    .no-flexbox .g-span4--tiny {
      width: 33.33333% !important; }
  .g-offset4--tiny {
    margin-left: 33.33333% !important; }
  .g-span5--tiny {
    flex-basis: 41.66667% !important;
    flex-grow: 0;
    max-width: 41.66667% !important; }
    .g-span5--tiny.g-holdWidth--tiny {
      min-width: 530px; }
      .g--gutter--scaled .g-span5--tiny.g-holdWidth--tiny {
        min-width: 514px; }
    .g--gutter--scaled .g-span5--tiny {
      flex-basis: 40.93291% !important;
      max-width: 40.93291%;
      margin-left: 1.25786%; }
    .no-flexbox .g-span5--tiny {
      width: 41.66667% !important; }
  .g-offset5--tiny {
    margin-left: 41.66667% !important; }
  .g-span6--tiny {
    flex-basis: 50% !important;
    flex-grow: 0;
    max-width: 50% !important; }
    .g-span6--tiny.g-holdWidth--tiny {
      min-width: 636px; }
      .g--gutter--scaled .g-span6--tiny.g-holdWidth--tiny {
        min-width: 620px; }
    .g--gutter--scaled .g-span6--tiny {
      flex-basis: 49.37107% !important;
      max-width: 49.37107%;
      margin-left: 1.25786%; }
    .no-flexbox .g-span6--tiny {
      width: 50% !important; }
  .g-offset6--tiny {
    margin-left: 50% !important; }
  .g-span7--tiny {
    flex-basis: 58.33333% !important;
    flex-grow: 0;
    max-width: 58.33333% !important; }
    .g-span7--tiny.g-holdWidth--tiny {
      min-width: 742px; }
      .g--gutter--scaled .g-span7--tiny.g-holdWidth--tiny {
        min-width: 726px; }
    .g--gutter--scaled .g-span7--tiny {
      flex-basis: 57.80922% !important;
      max-width: 57.80922%;
      margin-left: 1.25786%; }
    .no-flexbox .g-span7--tiny {
      width: 58.33333% !important; }
  .g-offset7--tiny {
    margin-left: 58.33333% !important; }
  .g-span8--tiny {
    flex-basis: 66.66667% !important;
    flex-grow: 0;
    max-width: 66.66667% !important; }
    .g-span8--tiny.g-holdWidth--tiny {
      min-width: 848px; }
      .g--gutter--scaled .g-span8--tiny.g-holdWidth--tiny {
        min-width: 832px; }
    .g--gutter--scaled .g-span8--tiny {
      flex-basis: 66.24738% !important;
      max-width: 66.24738%;
      margin-left: 1.25786%; }
    .no-flexbox .g-span8--tiny {
      width: 66.66667% !important; }
  .g-offset8--tiny {
    margin-left: 66.66667% !important; }
  .g-span9--tiny {
    flex-basis: 75% !important;
    flex-grow: 0;
    max-width: 75% !important; }
    .g-span9--tiny.g-holdWidth--tiny {
      min-width: 954px; }
      .g--gutter--scaled .g-span9--tiny.g-holdWidth--tiny {
        min-width: 938px; }
    .g--gutter--scaled .g-span9--tiny {
      flex-basis: 74.68553% !important;
      max-width: 74.68553%;
      margin-left: 1.25786%; }
    .no-flexbox .g-span9--tiny {
      width: 75% !important; }
  .g-offset9--tiny {
    margin-left: 75% !important; }
  .g-span10--tiny {
    flex-basis: 83.33333% !important;
    flex-grow: 0;
    max-width: 83.33333% !important; }
    .g-span10--tiny.g-holdWidth--tiny {
      min-width: 1060px; }
      .g--gutter--scaled .g-span10--tiny.g-holdWidth--tiny {
        min-width: 1044px; }
    .g--gutter--scaled .g-span10--tiny {
      flex-basis: 83.12369% !important;
      max-width: 83.12369%;
      margin-left: 1.25786%; }
    .no-flexbox .g-span10--tiny {
      width: 83.33333% !important; }
  .g-offset10--tiny {
    margin-left: 83.33333% !important; }
  .g-span11--tiny {
    flex-basis: 91.66667% !important;
    flex-grow: 0;
    max-width: 91.66667% !important; }
    .g-span11--tiny.g-holdWidth--tiny {
      min-width: 1166px; }
      .g--gutter--scaled .g-span11--tiny.g-holdWidth--tiny {
        min-width: 1150px; }
    .g--gutter--scaled .g-span11--tiny {
      flex-basis: 91.56184% !important;
      max-width: 91.56184%;
      margin-left: 1.25786%; }
    .no-flexbox .g-span11--tiny {
      width: 91.66667% !important; }
  .g-offset11--tiny {
    margin-left: 91.66667% !important; }
  .g-span12--tiny {
    flex-basis: 100% !important;
    flex-grow: 0;
    max-width: 100% !important; }
    .g-span12--tiny.g-holdWidth--tiny {
      min-width: 1272px; }
      .g--gutter--scaled .g-span12--tiny.g-holdWidth--tiny {
        min-width: 1256px; }
    .g--gutter--scaled .g-span12--tiny {
      flex-basis: 100% !important;
      max-width: 100%;
      margin-left: 0; }
    .no-flexbox .g-span12--tiny {
      width: 100% !important; } }

@media (min-width: 25.875em) {
  .g-span1--narrow {
    flex-basis: 8.33333% !important;
    flex-grow: 0;
    max-width: 8.33333% !important; }
    .g-span1--narrow.g-holdWidth--narrow {
      min-width: 106px; }
      .g--gutter--scaled .g-span1--narrow.g-holdWidth--narrow {
        min-width: 90px; }
    .g--gutter--scaled .g-span1--narrow {
      flex-basis: 7.18029% !important;
      max-width: 7.18029%;
      margin-left: 1.25786%; }
    .no-flexbox .g-span1--narrow {
      width: 8.33333% !important; }
  .g-offset1--narrow {
    margin-left: 8.33333% !important; }
  .g-span2--narrow {
    flex-basis: 16.66667% !important;
    flex-grow: 0;
    max-width: 16.66667% !important; }
    .g-span2--narrow.g-holdWidth--narrow {
      min-width: 212px; }
      .g--gutter--scaled .g-span2--narrow.g-holdWidth--narrow {
        min-width: 196px; }
    .g--gutter--scaled .g-span2--narrow {
      flex-basis: 15.61845% !important;
      max-width: 15.61845%;
      margin-left: 1.25786%; }
    .no-flexbox .g-span2--narrow {
      width: 16.66667% !important; }
  .g-offset2--narrow {
    margin-left: 16.66667% !important; }
  .g-span3--narrow {
    flex-basis: 25% !important;
    flex-grow: 0;
    max-width: 25% !important; }
    .g-span3--narrow.g-holdWidth--narrow {
      min-width: 318px; }
      .g--gutter--scaled .g-span3--narrow.g-holdWidth--narrow {
        min-width: 302px; }
    .g--gutter--scaled .g-span3--narrow {
      flex-basis: 24.0566% !important;
      max-width: 24.0566%;
      margin-left: 1.25786%; }
    .no-flexbox .g-span3--narrow {
      width: 25% !important; }
  .g-offset3--narrow {
    margin-left: 25% !important; }
  .g-span4--narrow {
    flex-basis: 33.33333% !important;
    flex-grow: 0;
    max-width: 33.33333% !important; }
    .g-span4--narrow.g-holdWidth--narrow {
      min-width: 424px; }
      .g--gutter--scaled .g-span4--narrow.g-holdWidth--narrow {
        min-width: 408px; }
    .g--gutter--scaled .g-span4--narrow {
      flex-basis: 32.49476% !important;
      max-width: 32.49476%;
      margin-left: 1.25786%; }
    .no-flexbox .g-span4--narrow {
      width: 33.33333% !important; }
  .g-offset4--narrow {
    margin-left: 33.33333% !important; }
  .g-span5--narrow {
    flex-basis: 41.66667% !important;
    flex-grow: 0;
    max-width: 41.66667% !important; }
    .g-span5--narrow.g-holdWidth--narrow {
      min-width: 530px; }
      .g--gutter--scaled .g-span5--narrow.g-holdWidth--narrow {
        min-width: 514px; }
    .g--gutter--scaled .g-span5--narrow {
      flex-basis: 40.93291% !important;
      max-width: 40.93291%;
      margin-left: 1.25786%; }
    .no-flexbox .g-span5--narrow {
      width: 41.66667% !important; }
  .g-offset5--narrow {
    margin-left: 41.66667% !important; }
  .g-span6--narrow {
    flex-basis: 50% !important;
    flex-grow: 0;
    max-width: 50% !important; }
    .g-span6--narrow.g-holdWidth--narrow {
      min-width: 636px; }
      .g--gutter--scaled .g-span6--narrow.g-holdWidth--narrow {
        min-width: 620px; }
    .g--gutter--scaled .g-span6--narrow {
      flex-basis: 49.37107% !important;
      max-width: 49.37107%;
      margin-left: 1.25786%; }
    .no-flexbox .g-span6--narrow {
      width: 50% !important; }
  .g-offset6--narrow {
    margin-left: 50% !important; }
  .g-span7--narrow {
    flex-basis: 58.33333% !important;
    flex-grow: 0;
    max-width: 58.33333% !important; }
    .g-span7--narrow.g-holdWidth--narrow {
      min-width: 742px; }
      .g--gutter--scaled .g-span7--narrow.g-holdWidth--narrow {
        min-width: 726px; }
    .g--gutter--scaled .g-span7--narrow {
      flex-basis: 57.80922% !important;
      max-width: 57.80922%;
      margin-left: 1.25786%; }
    .no-flexbox .g-span7--narrow {
      width: 58.33333% !important; }
  .g-offset7--narrow {
    margin-left: 58.33333% !important; }
  .g-span8--narrow {
    flex-basis: 66.66667% !important;
    flex-grow: 0;
    max-width: 66.66667% !important; }
    .g-span8--narrow.g-holdWidth--narrow {
      min-width: 848px; }
      .g--gutter--scaled .g-span8--narrow.g-holdWidth--narrow {
        min-width: 832px; }
    .g--gutter--scaled .g-span8--narrow {
      flex-basis: 66.24738% !important;
      max-width: 66.24738%;
      margin-left: 1.25786%; }
    .no-flexbox .g-span8--narrow {
      width: 66.66667% !important; }
  .g-offset8--narrow {
    margin-left: 66.66667% !important; }
  .g-span9--narrow {
    flex-basis: 75% !important;
    flex-grow: 0;
    max-width: 75% !important; }
    .g-span9--narrow.g-holdWidth--narrow {
      min-width: 954px; }
      .g--gutter--scaled .g-span9--narrow.g-holdWidth--narrow {
        min-width: 938px; }
    .g--gutter--scaled .g-span9--narrow {
      flex-basis: 74.68553% !important;
      max-width: 74.68553%;
      margin-left: 1.25786%; }
    .no-flexbox .g-span9--narrow {
      width: 75% !important; }
  .g-offset9--narrow {
    margin-left: 75% !important; }
  .g-span10--narrow {
    flex-basis: 83.33333% !important;
    flex-grow: 0;
    max-width: 83.33333% !important; }
    .g-span10--narrow.g-holdWidth--narrow {
      min-width: 1060px; }
      .g--gutter--scaled .g-span10--narrow.g-holdWidth--narrow {
        min-width: 1044px; }
    .g--gutter--scaled .g-span10--narrow {
      flex-basis: 83.12369% !important;
      max-width: 83.12369%;
      margin-left: 1.25786%; }
    .no-flexbox .g-span10--narrow {
      width: 83.33333% !important; }
  .g-offset10--narrow {
    margin-left: 83.33333% !important; }
  .g-span11--narrow {
    flex-basis: 91.66667% !important;
    flex-grow: 0;
    max-width: 91.66667% !important; }
    .g-span11--narrow.g-holdWidth--narrow {
      min-width: 1166px; }
      .g--gutter--scaled .g-span11--narrow.g-holdWidth--narrow {
        min-width: 1150px; }
    .g--gutter--scaled .g-span11--narrow {
      flex-basis: 91.56184% !important;
      max-width: 91.56184%;
      margin-left: 1.25786%; }
    .no-flexbox .g-span11--narrow {
      width: 91.66667% !important; }
  .g-offset11--narrow {
    margin-left: 91.66667% !important; }
  .g-span12--narrow {
    flex-basis: 100% !important;
    flex-grow: 0;
    max-width: 100% !important; }
    .g-span12--narrow.g-holdWidth--narrow {
      min-width: 1272px; }
      .g--gutter--scaled .g-span12--narrow.g-holdWidth--narrow {
        min-width: 1256px; }
    .g--gutter--scaled .g-span12--narrow {
      flex-basis: 100% !important;
      max-width: 100%;
      margin-left: 0; }
    .no-flexbox .g-span12--narrow {
      width: 100% !important; } }

@media (min-width: 37.5em) {
  .g-span1--narrowMid {
    flex-basis: 8.33333% !important;
    flex-grow: 0;
    max-width: 8.33333% !important; }
    .g-span1--narrowMid.g-holdWidth--narrowMid {
      min-width: 106px; }
      .g--gutter--scaled .g-span1--narrowMid.g-holdWidth--narrowMid {
        min-width: 90px; }
    .g--gutter--scaled .g-span1--narrowMid {
      flex-basis: 7.18029% !important;
      max-width: 7.18029%;
      margin-left: 1.25786%; }
    .no-flexbox .g-span1--narrowMid {
      width: 8.33333% !important; }
  .g-offset1--narrowMid {
    margin-left: 8.33333% !important; }
  .g-span2--narrowMid {
    flex-basis: 16.66667% !important;
    flex-grow: 0;
    max-width: 16.66667% !important; }
    .g-span2--narrowMid.g-holdWidth--narrowMid {
      min-width: 212px; }
      .g--gutter--scaled .g-span2--narrowMid.g-holdWidth--narrowMid {
        min-width: 196px; }
    .g--gutter--scaled .g-span2--narrowMid {
      flex-basis: 15.61845% !important;
      max-width: 15.61845%;
      margin-left: 1.25786%; }
    .no-flexbox .g-span2--narrowMid {
      width: 16.66667% !important; }
  .g-offset2--narrowMid {
    margin-left: 16.66667% !important; }
  .g-span3--narrowMid {
    flex-basis: 25% !important;
    flex-grow: 0;
    max-width: 25% !important; }
    .g-span3--narrowMid.g-holdWidth--narrowMid {
      min-width: 318px; }
      .g--gutter--scaled .g-span3--narrowMid.g-holdWidth--narrowMid {
        min-width: 302px; }
    .g--gutter--scaled .g-span3--narrowMid {
      flex-basis: 24.0566% !important;
      max-width: 24.0566%;
      margin-left: 1.25786%; }
    .no-flexbox .g-span3--narrowMid {
      width: 25% !important; }
  .g-offset3--narrowMid {
    margin-left: 25% !important; }
  .g-span4--narrowMid {
    flex-basis: 33.33333% !important;
    flex-grow: 0;
    max-width: 33.33333% !important; }
    .g-span4--narrowMid.g-holdWidth--narrowMid {
      min-width: 424px; }
      .g--gutter--scaled .g-span4--narrowMid.g-holdWidth--narrowMid {
        min-width: 408px; }
    .g--gutter--scaled .g-span4--narrowMid {
      flex-basis: 32.49476% !important;
      max-width: 32.49476%;
      margin-left: 1.25786%; }
    .no-flexbox .g-span4--narrowMid {
      width: 33.33333% !important; }
  .g-offset4--narrowMid {
    margin-left: 33.33333% !important; }
  .g-span5--narrowMid {
    flex-basis: 41.66667% !important;
    flex-grow: 0;
    max-width: 41.66667% !important; }
    .g-span5--narrowMid.g-holdWidth--narrowMid {
      min-width: 530px; }
      .g--gutter--scaled .g-span5--narrowMid.g-holdWidth--narrowMid {
        min-width: 514px; }
    .g--gutter--scaled .g-span5--narrowMid {
      flex-basis: 40.93291% !important;
      max-width: 40.93291%;
      margin-left: 1.25786%; }
    .no-flexbox .g-span5--narrowMid {
      width: 41.66667% !important; }
  .g-offset5--narrowMid {
    margin-left: 41.66667% !important; }
  .g-span6--narrowMid {
    flex-basis: 50% !important;
    flex-grow: 0;
    max-width: 50% !important; }
    .g-span6--narrowMid.g-holdWidth--narrowMid {
      min-width: 636px; }
      .g--gutter--scaled .g-span6--narrowMid.g-holdWidth--narrowMid {
        min-width: 620px; }
    .g--gutter--scaled .g-span6--narrowMid {
      flex-basis: 49.37107% !important;
      max-width: 49.37107%;
      margin-left: 1.25786%; }
    .no-flexbox .g-span6--narrowMid {
      width: 50% !important; }
  .g-offset6--narrowMid {
    margin-left: 50% !important; }
  .g-span7--narrowMid {
    flex-basis: 58.33333% !important;
    flex-grow: 0;
    max-width: 58.33333% !important; }
    .g-span7--narrowMid.g-holdWidth--narrowMid {
      min-width: 742px; }
      .g--gutter--scaled .g-span7--narrowMid.g-holdWidth--narrowMid {
        min-width: 726px; }
    .g--gutter--scaled .g-span7--narrowMid {
      flex-basis: 57.80922% !important;
      max-width: 57.80922%;
      margin-left: 1.25786%; }
    .no-flexbox .g-span7--narrowMid {
      width: 58.33333% !important; }
  .g-offset7--narrowMid {
    margin-left: 58.33333% !important; }
  .g-span8--narrowMid {
    flex-basis: 66.66667% !important;
    flex-grow: 0;
    max-width: 66.66667% !important; }
    .g-span8--narrowMid.g-holdWidth--narrowMid {
      min-width: 848px; }
      .g--gutter--scaled .g-span8--narrowMid.g-holdWidth--narrowMid {
        min-width: 832px; }
    .g--gutter--scaled .g-span8--narrowMid {
      flex-basis: 66.24738% !important;
      max-width: 66.24738%;
      margin-left: 1.25786%; }
    .no-flexbox .g-span8--narrowMid {
      width: 66.66667% !important; }
  .g-offset8--narrowMid {
    margin-left: 66.66667% !important; }
  .g-span9--narrowMid {
    flex-basis: 75% !important;
    flex-grow: 0;
    max-width: 75% !important; }
    .g-span9--narrowMid.g-holdWidth--narrowMid {
      min-width: 954px; }
      .g--gutter--scaled .g-span9--narrowMid.g-holdWidth--narrowMid {
        min-width: 938px; }
    .g--gutter--scaled .g-span9--narrowMid {
      flex-basis: 74.68553% !important;
      max-width: 74.68553%;
      margin-left: 1.25786%; }
    .no-flexbox .g-span9--narrowMid {
      width: 75% !important; }
  .g-offset9--narrowMid {
    margin-left: 75% !important; }
  .g-span10--narrowMid {
    flex-basis: 83.33333% !important;
    flex-grow: 0;
    max-width: 83.33333% !important; }
    .g-span10--narrowMid.g-holdWidth--narrowMid {
      min-width: 1060px; }
      .g--gutter--scaled .g-span10--narrowMid.g-holdWidth--narrowMid {
        min-width: 1044px; }
    .g--gutter--scaled .g-span10--narrowMid {
      flex-basis: 83.12369% !important;
      max-width: 83.12369%;
      margin-left: 1.25786%; }
    .no-flexbox .g-span10--narrowMid {
      width: 83.33333% !important; }
  .g-offset10--narrowMid {
    margin-left: 83.33333% !important; }
  .g-span11--narrowMid {
    flex-basis: 91.66667% !important;
    flex-grow: 0;
    max-width: 91.66667% !important; }
    .g-span11--narrowMid.g-holdWidth--narrowMid {
      min-width: 1166px; }
      .g--gutter--scaled .g-span11--narrowMid.g-holdWidth--narrowMid {
        min-width: 1150px; }
    .g--gutter--scaled .g-span11--narrowMid {
      flex-basis: 91.56184% !important;
      max-width: 91.56184%;
      margin-left: 1.25786%; }
    .no-flexbox .g-span11--narrowMid {
      width: 91.66667% !important; }
  .g-offset11--narrowMid {
    margin-left: 91.66667% !important; }
  .g-span12--narrowMid {
    flex-basis: 100% !important;
    flex-grow: 0;
    max-width: 100% !important; }
    .g-span12--narrowMid.g-holdWidth--narrowMid {
      min-width: 1272px; }
      .g--gutter--scaled .g-span12--narrowMid.g-holdWidth--narrowMid {
        min-width: 1256px; }
    .g--gutter--scaled .g-span12--narrowMid {
      flex-basis: 100% !important;
      max-width: 100%;
      margin-left: 0; }
    .no-flexbox .g-span12--narrowMid {
      width: 100% !important; } }

@media (min-width: 48em) {
  .g-span1--mid {
    flex-basis: 8.33333% !important;
    flex-grow: 0;
    max-width: 8.33333% !important; }
    .g-span1--mid.g-holdWidth--mid {
      min-width: 106px; }
      .g--gutter--scaled .g-span1--mid.g-holdWidth--mid {
        min-width: 90px; }
    .g--gutter--scaled .g-span1--mid {
      flex-basis: 7.18029% !important;
      max-width: 7.18029%;
      margin-left: 1.25786%; }
    .no-flexbox .g-span1--mid {
      width: 8.33333% !important; }
  .g-offset1--mid {
    margin-left: 8.33333% !important; }
  .g-span2--mid {
    flex-basis: 16.66667% !important;
    flex-grow: 0;
    max-width: 16.66667% !important; }
    .g-span2--mid.g-holdWidth--mid {
      min-width: 212px; }
      .g--gutter--scaled .g-span2--mid.g-holdWidth--mid {
        min-width: 196px; }
    .g--gutter--scaled .g-span2--mid {
      flex-basis: 15.61845% !important;
      max-width: 15.61845%;
      margin-left: 1.25786%; }
    .no-flexbox .g-span2--mid {
      width: 16.66667% !important; }
  .g-offset2--mid {
    margin-left: 16.66667% !important; }
  .g-span3--mid {
    flex-basis: 25% !important;
    flex-grow: 0;
    max-width: 25% !important; }
    .g-span3--mid.g-holdWidth--mid {
      min-width: 318px; }
      .g--gutter--scaled .g-span3--mid.g-holdWidth--mid {
        min-width: 302px; }
    .g--gutter--scaled .g-span3--mid {
      flex-basis: 24.0566% !important;
      max-width: 24.0566%;
      margin-left: 1.25786%; }
    .no-flexbox .g-span3--mid {
      width: 25% !important; }
  .g-offset3--mid {
    margin-left: 25% !important; }
  .g-span4--mid {
    flex-basis: 33.33333% !important;
    flex-grow: 0;
    max-width: 33.33333% !important; }
    .g-span4--mid.g-holdWidth--mid {
      min-width: 424px; }
      .g--gutter--scaled .g-span4--mid.g-holdWidth--mid {
        min-width: 408px; }
    .g--gutter--scaled .g-span4--mid {
      flex-basis: 32.49476% !important;
      max-width: 32.49476%;
      margin-left: 1.25786%; }
    .no-flexbox .g-span4--mid {
      width: 33.33333% !important; }
  .g-offset4--mid {
    margin-left: 33.33333% !important; }
  .g-span5--mid {
    flex-basis: 41.66667% !important;
    flex-grow: 0;
    max-width: 41.66667% !important; }
    .g-span5--mid.g-holdWidth--mid {
      min-width: 530px; }
      .g--gutter--scaled .g-span5--mid.g-holdWidth--mid {
        min-width: 514px; }
    .g--gutter--scaled .g-span5--mid {
      flex-basis: 40.93291% !important;
      max-width: 40.93291%;
      margin-left: 1.25786%; }
    .no-flexbox .g-span5--mid {
      width: 41.66667% !important; }
  .g-offset5--mid {
    margin-left: 41.66667% !important; }
  .g-span6--mid {
    flex-basis: 50% !important;
    flex-grow: 0;
    max-width: 50% !important; }
    .g-span6--mid.g-holdWidth--mid {
      min-width: 636px; }
      .g--gutter--scaled .g-span6--mid.g-holdWidth--mid {
        min-width: 620px; }
    .g--gutter--scaled .g-span6--mid {
      flex-basis: 49.37107% !important;
      max-width: 49.37107%;
      margin-left: 1.25786%; }
    .no-flexbox .g-span6--mid {
      width: 50% !important; }
  .g-offset6--mid {
    margin-left: 50% !important; }
  .g-span7--mid {
    flex-basis: 58.33333% !important;
    flex-grow: 0;
    max-width: 58.33333% !important; }
    .g-span7--mid.g-holdWidth--mid {
      min-width: 742px; }
      .g--gutter--scaled .g-span7--mid.g-holdWidth--mid {
        min-width: 726px; }
    .g--gutter--scaled .g-span7--mid {
      flex-basis: 57.80922% !important;
      max-width: 57.80922%;
      margin-left: 1.25786%; }
    .no-flexbox .g-span7--mid {
      width: 58.33333% !important; }
  .g-offset7--mid {
    margin-left: 58.33333% !important; }
  .g-span8--mid {
    flex-basis: 66.66667% !important;
    flex-grow: 0;
    max-width: 66.66667% !important; }
    .g-span8--mid.g-holdWidth--mid {
      min-width: 848px; }
      .g--gutter--scaled .g-span8--mid.g-holdWidth--mid {
        min-width: 832px; }
    .g--gutter--scaled .g-span8--mid {
      flex-basis: 66.24738% !important;
      max-width: 66.24738%;
      margin-left: 1.25786%; }
    .no-flexbox .g-span8--mid {
      width: 66.66667% !important; }
  .g-offset8--mid {
    margin-left: 66.66667% !important; }
  .g-span9--mid {
    flex-basis: 75% !important;
    flex-grow: 0;
    max-width: 75% !important; }
    .g-span9--mid.g-holdWidth--mid {
      min-width: 954px; }
      .g--gutter--scaled .g-span9--mid.g-holdWidth--mid {
        min-width: 938px; }
    .g--gutter--scaled .g-span9--mid {
      flex-basis: 74.68553% !important;
      max-width: 74.68553%;
      margin-left: 1.25786%; }
    .no-flexbox .g-span9--mid {
      width: 75% !important; }
  .g-offset9--mid {
    margin-left: 75% !important; }
  .g-span10--mid {
    flex-basis: 83.33333% !important;
    flex-grow: 0;
    max-width: 83.33333% !important; }
    .g-span10--mid.g-holdWidth--mid {
      min-width: 1060px; }
      .g--gutter--scaled .g-span10--mid.g-holdWidth--mid {
        min-width: 1044px; }
    .g--gutter--scaled .g-span10--mid {
      flex-basis: 83.12369% !important;
      max-width: 83.12369%;
      margin-left: 1.25786%; }
    .no-flexbox .g-span10--mid {
      width: 83.33333% !important; }
  .g-offset10--mid {
    margin-left: 83.33333% !important; }
  .g-span11--mid {
    flex-basis: 91.66667% !important;
    flex-grow: 0;
    max-width: 91.66667% !important; }
    .g-span11--mid.g-holdWidth--mid {
      min-width: 1166px; }
      .g--gutter--scaled .g-span11--mid.g-holdWidth--mid {
        min-width: 1150px; }
    .g--gutter--scaled .g-span11--mid {
      flex-basis: 91.56184% !important;
      max-width: 91.56184%;
      margin-left: 1.25786%; }
    .no-flexbox .g-span11--mid {
      width: 91.66667% !important; }
  .g-offset11--mid {
    margin-left: 91.66667% !important; }
  .g-span12--mid {
    flex-basis: 100% !important;
    flex-grow: 0;
    max-width: 100% !important; }
    .g-span12--mid.g-holdWidth--mid {
      min-width: 1272px; }
      .g--gutter--scaled .g-span12--mid.g-holdWidth--mid {
        min-width: 1256px; }
    .g--gutter--scaled .g-span12--mid {
      flex-basis: 100% !important;
      max-width: 100%;
      margin-left: 0; }
    .no-flexbox .g-span12--mid {
      width: 100% !important; } }

@media (min-width: 64.0625em) {
  .g-span1--wide {
    flex-basis: 8.33333% !important;
    flex-grow: 0;
    max-width: 8.33333% !important; }
    .g-span1--wide.g-holdWidth--wide {
      min-width: 106px; }
      .g--gutter--scaled .g-span1--wide.g-holdWidth--wide {
        min-width: 90px; }
    .g--gutter--scaled .g-span1--wide {
      flex-basis: 7.18029% !important;
      max-width: 7.18029%;
      margin-left: 1.25786%; }
    .no-flexbox .g-span1--wide {
      width: 8.33333% !important; }
  .g-offset1--wide {
    margin-left: 8.33333% !important; }
  .g-span2--wide {
    flex-basis: 16.66667% !important;
    flex-grow: 0;
    max-width: 16.66667% !important; }
    .g-span2--wide.g-holdWidth--wide {
      min-width: 212px; }
      .g--gutter--scaled .g-span2--wide.g-holdWidth--wide {
        min-width: 196px; }
    .g--gutter--scaled .g-span2--wide {
      flex-basis: 15.61845% !important;
      max-width: 15.61845%;
      margin-left: 1.25786%; }
    .no-flexbox .g-span2--wide {
      width: 16.66667% !important; }
  .g-offset2--wide {
    margin-left: 16.66667% !important; }
  .g-span3--wide {
    flex-basis: 25% !important;
    flex-grow: 0;
    max-width: 25% !important; }
    .g-span3--wide.g-holdWidth--wide {
      min-width: 318px; }
      .g--gutter--scaled .g-span3--wide.g-holdWidth--wide {
        min-width: 302px; }
    .g--gutter--scaled .g-span3--wide {
      flex-basis: 24.0566% !important;
      max-width: 24.0566%;
      margin-left: 1.25786%; }
    .no-flexbox .g-span3--wide {
      width: 25% !important; }
  .g-offset3--wide {
    margin-left: 25% !important; }
  .g-span4--wide {
    flex-basis: 33.33333% !important;
    flex-grow: 0;
    max-width: 33.33333% !important; }
    .g-span4--wide.g-holdWidth--wide {
      min-width: 424px; }
      .g--gutter--scaled .g-span4--wide.g-holdWidth--wide {
        min-width: 408px; }
    .g--gutter--scaled .g-span4--wide {
      flex-basis: 32.49476% !important;
      max-width: 32.49476%;
      margin-left: 1.25786%; }
    .no-flexbox .g-span4--wide {
      width: 33.33333% !important; }
  .g-offset4--wide {
    margin-left: 33.33333% !important; }
  .g-span5--wide {
    flex-basis: 41.66667% !important;
    flex-grow: 0;
    max-width: 41.66667% !important; }
    .g-span5--wide.g-holdWidth--wide {
      min-width: 530px; }
      .g--gutter--scaled .g-span5--wide.g-holdWidth--wide {
        min-width: 514px; }
    .g--gutter--scaled .g-span5--wide {
      flex-basis: 40.93291% !important;
      max-width: 40.93291%;
      margin-left: 1.25786%; }
    .no-flexbox .g-span5--wide {
      width: 41.66667% !important; }
  .g-offset5--wide {
    margin-left: 41.66667% !important; }
  .g-span6--wide {
    flex-basis: 50% !important;
    flex-grow: 0;
    max-width: 50% !important; }
    .g-span6--wide.g-holdWidth--wide {
      min-width: 636px; }
      .g--gutter--scaled .g-span6--wide.g-holdWidth--wide {
        min-width: 620px; }
    .g--gutter--scaled .g-span6--wide {
      flex-basis: 49.37107% !important;
      max-width: 49.37107%;
      margin-left: 1.25786%; }
    .no-flexbox .g-span6--wide {
      width: 50% !important; }
  .g-offset6--wide {
    margin-left: 50% !important; }
  .g-span7--wide {
    flex-basis: 58.33333% !important;
    flex-grow: 0;
    max-width: 58.33333% !important; }
    .g-span7--wide.g-holdWidth--wide {
      min-width: 742px; }
      .g--gutter--scaled .g-span7--wide.g-holdWidth--wide {
        min-width: 726px; }
    .g--gutter--scaled .g-span7--wide {
      flex-basis: 57.80922% !important;
      max-width: 57.80922%;
      margin-left: 1.25786%; }
    .no-flexbox .g-span7--wide {
      width: 58.33333% !important; }
  .g-offset7--wide {
    margin-left: 58.33333% !important; }
  .g-span8--wide {
    flex-basis: 66.66667% !important;
    flex-grow: 0;
    max-width: 66.66667% !important; }
    .g-span8--wide.g-holdWidth--wide {
      min-width: 848px; }
      .g--gutter--scaled .g-span8--wide.g-holdWidth--wide {
        min-width: 832px; }
    .g--gutter--scaled .g-span8--wide {
      flex-basis: 66.24738% !important;
      max-width: 66.24738%;
      margin-left: 1.25786%; }
    .no-flexbox .g-span8--wide {
      width: 66.66667% !important; }
  .g-offset8--wide {
    margin-left: 66.66667% !important; }
  .g-span9--wide {
    flex-basis: 75% !important;
    flex-grow: 0;
    max-width: 75% !important; }
    .g-span9--wide.g-holdWidth--wide {
      min-width: 954px; }
      .g--gutter--scaled .g-span9--wide.g-holdWidth--wide {
        min-width: 938px; }
    .g--gutter--scaled .g-span9--wide {
      flex-basis: 74.68553% !important;
      max-width: 74.68553%;
      margin-left: 1.25786%; }
    .no-flexbox .g-span9--wide {
      width: 75% !important; }
  .g-offset9--wide {
    margin-left: 75% !important; }
  .g-span10--wide {
    flex-basis: 83.33333% !important;
    flex-grow: 0;
    max-width: 83.33333% !important; }
    .g-span10--wide.g-holdWidth--wide {
      min-width: 1060px; }
      .g--gutter--scaled .g-span10--wide.g-holdWidth--wide {
        min-width: 1044px; }
    .g--gutter--scaled .g-span10--wide {
      flex-basis: 83.12369% !important;
      max-width: 83.12369%;
      margin-left: 1.25786%; }
    .no-flexbox .g-span10--wide {
      width: 83.33333% !important; }
  .g-offset10--wide {
    margin-left: 83.33333% !important; }
  .g-span11--wide {
    flex-basis: 91.66667% !important;
    flex-grow: 0;
    max-width: 91.66667% !important; }
    .g-span11--wide.g-holdWidth--wide {
      min-width: 1166px; }
      .g--gutter--scaled .g-span11--wide.g-holdWidth--wide {
        min-width: 1150px; }
    .g--gutter--scaled .g-span11--wide {
      flex-basis: 91.56184% !important;
      max-width: 91.56184%;
      margin-left: 1.25786%; }
    .no-flexbox .g-span11--wide {
      width: 91.66667% !important; }
  .g-offset11--wide {
    margin-left: 91.66667% !important; }
  .g-span12--wide {
    flex-basis: 100% !important;
    flex-grow: 0;
    max-width: 100% !important; }
    .g-span12--wide.g-holdWidth--wide {
      min-width: 1272px; }
      .g--gutter--scaled .g-span12--wide.g-holdWidth--wide {
        min-width: 1256px; }
    .g--gutter--scaled .g-span12--wide {
      flex-basis: 100% !important;
      max-width: 100%;
      margin-left: 0; }
    .no-flexbox .g-span12--wide {
      width: 100% !important; } }

@media (min-width: 80em) {
  .g-span1--huge {
    flex-basis: 8.33333% !important;
    flex-grow: 0;
    max-width: 8.33333% !important; }
    .g-span1--huge.g-holdWidth--huge {
      min-width: 106px; }
      .g--gutter--scaled .g-span1--huge.g-holdWidth--huge {
        min-width: 90px; }
    .g--gutter--scaled .g-span1--huge {
      flex-basis: 7.18029% !important;
      max-width: 7.18029%;
      margin-left: 1.25786%; }
    .no-flexbox .g-span1--huge {
      width: 8.33333% !important; }
  .g-offset1--huge {
    margin-left: 8.33333% !important; }
  .g-span2--huge {
    flex-basis: 16.66667% !important;
    flex-grow: 0;
    max-width: 16.66667% !important; }
    .g-span2--huge.g-holdWidth--huge {
      min-width: 212px; }
      .g--gutter--scaled .g-span2--huge.g-holdWidth--huge {
        min-width: 196px; }
    .g--gutter--scaled .g-span2--huge {
      flex-basis: 15.61845% !important;
      max-width: 15.61845%;
      margin-left: 1.25786%; }
    .no-flexbox .g-span2--huge {
      width: 16.66667% !important; }
  .g-offset2--huge {
    margin-left: 16.66667% !important; }
  .g-span3--huge {
    flex-basis: 25% !important;
    flex-grow: 0;
    max-width: 25% !important; }
    .g-span3--huge.g-holdWidth--huge {
      min-width: 318px; }
      .g--gutter--scaled .g-span3--huge.g-holdWidth--huge {
        min-width: 302px; }
    .g--gutter--scaled .g-span3--huge {
      flex-basis: 24.0566% !important;
      max-width: 24.0566%;
      margin-left: 1.25786%; }
    .no-flexbox .g-span3--huge {
      width: 25% !important; }
  .g-offset3--huge {
    margin-left: 25% !important; }
  .g-span4--huge {
    flex-basis: 33.33333% !important;
    flex-grow: 0;
    max-width: 33.33333% !important; }
    .g-span4--huge.g-holdWidth--huge {
      min-width: 424px; }
      .g--gutter--scaled .g-span4--huge.g-holdWidth--huge {
        min-width: 408px; }
    .g--gutter--scaled .g-span4--huge {
      flex-basis: 32.49476% !important;
      max-width: 32.49476%;
      margin-left: 1.25786%; }
    .no-flexbox .g-span4--huge {
      width: 33.33333% !important; }
  .g-offset4--huge {
    margin-left: 33.33333% !important; }
  .g-span5--huge {
    flex-basis: 41.66667% !important;
    flex-grow: 0;
    max-width: 41.66667% !important; }
    .g-span5--huge.g-holdWidth--huge {
      min-width: 530px; }
      .g--gutter--scaled .g-span5--huge.g-holdWidth--huge {
        min-width: 514px; }
    .g--gutter--scaled .g-span5--huge {
      flex-basis: 40.93291% !important;
      max-width: 40.93291%;
      margin-left: 1.25786%; }
    .no-flexbox .g-span5--huge {
      width: 41.66667% !important; }
  .g-offset5--huge {
    margin-left: 41.66667% !important; }
  .g-span6--huge {
    flex-basis: 50% !important;
    flex-grow: 0;
    max-width: 50% !important; }
    .g-span6--huge.g-holdWidth--huge {
      min-width: 636px; }
      .g--gutter--scaled .g-span6--huge.g-holdWidth--huge {
        min-width: 620px; }
    .g--gutter--scaled .g-span6--huge {
      flex-basis: 49.37107% !important;
      max-width: 49.37107%;
      margin-left: 1.25786%; }
    .no-flexbox .g-span6--huge {
      width: 50% !important; }
  .g-offset6--huge {
    margin-left: 50% !important; }
  .g-span7--huge {
    flex-basis: 58.33333% !important;
    flex-grow: 0;
    max-width: 58.33333% !important; }
    .g-span7--huge.g-holdWidth--huge {
      min-width: 742px; }
      .g--gutter--scaled .g-span7--huge.g-holdWidth--huge {
        min-width: 726px; }
    .g--gutter--scaled .g-span7--huge {
      flex-basis: 57.80922% !important;
      max-width: 57.80922%;
      margin-left: 1.25786%; }
    .no-flexbox .g-span7--huge {
      width: 58.33333% !important; }
  .g-offset7--huge {
    margin-left: 58.33333% !important; }
  .g-span8--huge {
    flex-basis: 66.66667% !important;
    flex-grow: 0;
    max-width: 66.66667% !important; }
    .g-span8--huge.g-holdWidth--huge {
      min-width: 848px; }
      .g--gutter--scaled .g-span8--huge.g-holdWidth--huge {
        min-width: 832px; }
    .g--gutter--scaled .g-span8--huge {
      flex-basis: 66.24738% !important;
      max-width: 66.24738%;
      margin-left: 1.25786%; }
    .no-flexbox .g-span8--huge {
      width: 66.66667% !important; }
  .g-offset8--huge {
    margin-left: 66.66667% !important; }
  .g-span9--huge {
    flex-basis: 75% !important;
    flex-grow: 0;
    max-width: 75% !important; }
    .g-span9--huge.g-holdWidth--huge {
      min-width: 954px; }
      .g--gutter--scaled .g-span9--huge.g-holdWidth--huge {
        min-width: 938px; }
    .g--gutter--scaled .g-span9--huge {
      flex-basis: 74.68553% !important;
      max-width: 74.68553%;
      margin-left: 1.25786%; }
    .no-flexbox .g-span9--huge {
      width: 75% !important; }
  .g-offset9--huge {
    margin-left: 75% !important; }
  .g-span10--huge {
    flex-basis: 83.33333% !important;
    flex-grow: 0;
    max-width: 83.33333% !important; }
    .g-span10--huge.g-holdWidth--huge {
      min-width: 1060px; }
      .g--gutter--scaled .g-span10--huge.g-holdWidth--huge {
        min-width: 1044px; }
    .g--gutter--scaled .g-span10--huge {
      flex-basis: 83.12369% !important;
      max-width: 83.12369%;
      margin-left: 1.25786%; }
    .no-flexbox .g-span10--huge {
      width: 83.33333% !important; }
  .g-offset10--huge {
    margin-left: 83.33333% !important; }
  .g-span11--huge {
    flex-basis: 91.66667% !important;
    flex-grow: 0;
    max-width: 91.66667% !important; }
    .g-span11--huge.g-holdWidth--huge {
      min-width: 1166px; }
      .g--gutter--scaled .g-span11--huge.g-holdWidth--huge {
        min-width: 1150px; }
    .g--gutter--scaled .g-span11--huge {
      flex-basis: 91.56184% !important;
      max-width: 91.56184%;
      margin-left: 1.25786%; }
    .no-flexbox .g-span11--huge {
      width: 91.66667% !important; }
  .g-offset11--huge {
    margin-left: 91.66667% !important; }
  .g-span12--huge {
    flex-basis: 100% !important;
    flex-grow: 0;
    max-width: 100% !important; }
    .g-span12--huge.g-holdWidth--huge {
      min-width: 1272px; }
      .g--gutter--scaled .g-span12--huge.g-holdWidth--huge {
        min-width: 1256px; }
    .g--gutter--scaled .g-span12--huge {
      flex-basis: 100% !important;
      max-width: 100%;
      margin-left: 0; }
    .no-flexbox .g-span12--huge {
      width: 100% !important; } }

@media (max-width: 23.4275em) {
  .g--stack--tiny {
    flex-flow: column nowrap; }
    .g--stack--tiny > .g-col {
      flex-basis: auto !important;
      max-width: 100% !important;
      margin-left: 0; }
      .no-flexbox .g--stack--tiny > .g-col {
        width: 100% !important; } }

@media (max-width: 25.865em) {
  .g--stack--narrow {
    flex-flow: column nowrap; }
    .g--stack--narrow > .g-col {
      flex-basis: auto !important;
      max-width: 100% !important;
      margin-left: 0; }
      .no-flexbox .g--stack--narrow > .g-col {
        width: 100% !important; } }

@media (max-width: 37.49em) {
  .g--stack--narrowMid {
    flex-flow: column nowrap; }
    .g--stack--narrowMid > .g-col {
      flex-basis: auto !important;
      max-width: 100% !important;
      margin-left: 0; }
      .no-flexbox .g--stack--narrowMid > .g-col {
        width: 100% !important; } }

@media (max-width: 47.99em) {
  .g--stack--mid {
    flex-flow: column nowrap; }
    .g--stack--mid > .g-col {
      flex-basis: auto !important;
      max-width: 100% !important;
      margin-left: 0; }
      .no-flexbox .g--stack--mid > .g-col {
        width: 100% !important; } }

@media (max-width: 64.0525em) {
  .g--stack--wide {
    flex-flow: column nowrap; }
    .g--stack--wide > .g-col {
      flex-basis: auto !important;
      max-width: 100% !important;
      margin-left: 0; }
      .no-flexbox .g--stack--wide > .g-col {
        width: 100% !important; } }

@media (max-width: 79.99em) {
  .g--stack--huge {
    flex-flow: column nowrap; }
    .g--stack--huge > .g-col {
      flex-basis: auto !important;
      max-width: 100% !important;
      margin-left: 0; }
      .no-flexbox .g--stack--huge > .g-col {
        width: 100% !important; } }

/**
 * Objects
 * =================================
 * Styles apply to an object if they are directly associated to a HTML element.
 * If not, the styles should be defined as a component.
 *
 * For example, it would be expected to have:
 * 	links.scss > styles relating to links (<a> tag)
 * 	tables.scss > styles relating to tables
 *
 * Object styles should be prefixed with `.o-`
 */
/**
 * Objects > Body styles
 * ===================================
 * Body styles to control the background-color of a page
 *
 * Documentation:
 * TBC
 */
.o-body--bgWhite {
  background-color: #fff; }

.o-body--bgWhite--belowMid {
  background-color: #f5f5f5; }
  @media (max-width: 47.99em) {
    .o-body--bgWhite--belowMid {
      background-color: #fff; } }

/**
 * Objects > Buttons
 * =================================
 *
 * Documentation:
 * https://fozzie.just-eat.com/styleguide/ui-components/buttons
 */
/**
 * Define associated Button variables
 */
/**
 * Base button styles – Based on csswizardry.com/beautons
 *
 * 1. Allow us to better style box model properties.
 * 2. Line different sized buttons up a little nicer.
 * 3. Make buttons inherit font styles.
 * 4. Force all elements using beautons to appear clickable.
 * 5. Normalise box model styles.
 * 6. If the button’s text is 1em, and the button is (3 * font-size) tall, then
 *    there is 1em of space above and below that text. We therefore apply 1em
 *    of space to the left and right, as padding, to keep consistent spacing.
 * 7. Fixes odd inner spacing in IE7.
 */
.o-btn {
  display: inline-block;
  /* [1] */
  vertical-align: middle;
  /* [2] */
  font-family: "Ubuntu";
  /* [3] */
  font-size: 16px;
  font-size: 1.14286rem;
  line-height: 1.5;
  /* [3] */
  cursor: pointer;
  /* [4] */
  margin: 0;
  /* [5] */
  padding: 11px 1em;
  /* [5, 6] */
  overflow: visible;
  /* [7] */
  text-align: center;
  font-weight: 500;
  line-height: 1;
  background-color: #eaeaea;
  border-radius: 2px;
  border: 1px solid transparent;
  user-select: none;
  color: #535353;
  text-decoration: none; }
  .o-btn:hover, .o-btn:active, .o-btn:focus {
    background-color: #cacaca; }
    .o-btn:hover:not(.o-btnLink), .o-btn:active:not(.o-btnLink), .o-btn:focus:not(.o-btnLink) {
      outline: none; }
  .o-btn, .o-btn:hover, .o-btn:active, .o-btn:focus, .o-btn:visited {
    text-decoration: none; }
  .o-btn.disabled, .o-btn[disabled] {
    cursor: default; }
    .o-btn.disabled, .o-btn.disabled:hover, .o-btn[disabled], .o-btn[disabled]:hover {
      background-color: #cacaca; }
  p + .o-btn {
    margin-top: 16px; }
  .o-btn .note {
    display: none; }
    @media (min-width: 48em) {
      .o-btn .note {
        display: block; } }
  .o-btn[type='submit'] {
    margin-top: 16px; }

/**
 * ==========================================================================
 * Btn Background Colour modifiers
 * ==========================================================================
 */
/**
 * Modifier – .o-btn--primary
 *
 * Sets the btn colour to site primary colour
 */
.o-btn--primary {
  background-color: #266abd; }
  .o-btn--primary, .o-btn--primary:link, .o-btn--primary:visited {
    color: #fff; }
  .o-btn--primary:hover, .o-btn--primary:active, .o-btn--primary:focus {
    color: #fff; }
  .o-btn--primary:hover {
    background-color: #14509c; }
  .o-btn--primary:active, .o-btn--primary:focus {
    background-color: #14427d; }

/**
 * Modifier – .o-btn--secondary
 *
 * Sets the btn colour to positive colour (i.e. Green)
 */
.o-btn--secondary,
.o-btn--tertiary {
  background-color: #eaeaea;
  color: #266abd; }
  .o-btn--secondary:hover,
  .o-btn--tertiary:hover {
    background-color: #cacaca;
    color: #266abd; }
  .o-btn--secondary:active, .o-btn--secondary:focus,
  .o-btn--tertiary:active,
  .o-btn--tertiary:focus {
    background-color: #bbb;
    color: #266abd;
    outline: none; }

.o-btn--tertiary {
  background-color: #fff; }

/**
 * ==========================================================================
 * Btn Layout Modifiers
 * ==========================================================================
 */
/**
 * Modifier – btn-block
 *
 * Makes the btn full width
 */
.o-btn--block {
  display: block;
  width: 100%;
  padding-left: 0;
  padding-right: 0; }
  .o-btn--block + .o-btn--block {
    margin-top: 10px; }

.o-btn--wide {
  padding-left: 2em;
  padding-right: 2em; }

/**
 * Outline Button
 * -------------------------
 * Essentially, a button, with same color bg as container, but with an outline
 */
.o-btn--outline {
  background-color: transparent;
  border: 1px solid #266abd;
  color: #266abd; }
  .o-btn--outline:focus, .o-btn--outline:hover, .o-btn--outline:active, .o-btn--outline.is-active {
    outline: none;
    background-color: #edf5ff;
    color: #14509c; }
  .o-btn--outline:hover, .o-btn--outline:focus {
    border: 1px solid #14509c; }
  .o-btn--outline:active {
    border: 1px solid #7dcaeb; }

.o-btn--icon {
  background-color: transparent;
  padding: 0; }
  .o-btn--icon:hover {
    background-color: transparent; }

input[type='submit'].o-btn--block,
input[type='reset'].o-btn--block,
input[type='button'].o-btn--block {
  width: 100%; }

/**
 * Disabled button
 */
.o-btn--disabled {
  cursor: default; }
  .o-btn--disabled, .o-btn--disabled:hover, .o-btn--disabled:active, .o-btn--disabled:focus {
    background-color: #cacaca;
    color: inherit; }

/**
 * Loading state
 */
.o-btn.is-loading {
  background: url("//dy3erx8o0a6nh.cloudfront.net/images/icon_loader_arrow.gif") 50% 50%/20px 20px no-repeat border-box #cacaca;
  color: transparent !important;
  border-radius: 0;
  /* Fixes rendering bug in Chrome for Android */ }

/**
 * btnLink
 *
 * Make a button visually look like a default link
 * Useful when we semantically want a button but don’t want all the default styling
 *
 * Should only be applied to buttons
 */
.o-btnLink {
  border: 0;
  background-color: transparent;
  padding: 0;
  margin: 0;
  color: #266abd;
  font-weight: 500;
  text-decoration: underline; }
  .o-btnLink:hover {
    cursor: pointer;
    color: #14509c;
    background-color: transparent; }
  .o-btnLink:active, .o-btnLink:focus {
    color: #14427d;
    background-color: transparent; }

/**
 * Objects > Images
 * =================================
 *
 * Documentation:
 * TBC
 */
/**
 * Objects > Links
 * =================================
 * Default Link Styling
 *
 * Documentation:
 * TBC
 */
a {
  color: #266abd; }

a:hover, a:focus {
  color: #14509c; }

a:active {
  color: #14427d; }

.o-link--full {
  width: 100%;
  display: block; }

.o-link--noDecoration {
  text-decoration: none; }

.o-link--bold {
  font-weight: 500; }

/**
 * Objects > Lists
 * =================================
 * Default list styles (for unordered and ordered lists)
 *
 * Documentation:
 * TBC
 */
ul,
ol {
  padding: 0;
  margin: 16px 0 0 16px; }
  ul li,
  ol li {
    margin-bottom: 8px; }
  ul ul,
  ul ol,
  ol ul,
  ol ol {
    margin-top: 8px; }

ul {
  margin-left: 1.5rem;
  list-style: none; }
  ul > li:before,
  ul > li ul > li:before {
    color: #d50525;
    content: '\2022';
    /* Unicode for circle character */
    font-size: 1.8em;
    padding-right: 8px;
    position: relative;
    top: 0.15em;
    margin-left: -1.5rem; }

ol > li {
  padding-left: 8px; }

.c-list-ordered > li {
  font-weight: 500;
  font-size: 20px;
  font-size: 1.42857rem;
  line-height: 1.2;
  margin-bottom: 32px; }
  .c-list-ordered > li > div {
    font-weight: 300;
    font-size: 14px;
    font-size: 1rem;
    line-height: 1.29; }

/**
 * Objects > Tables
 * =================================
 * Default Table styles
 *
 * File Index:
 * - Table specific variables
 * - Default table styles
 *
 * Documentation:
 * TBC
 */
/**
 * Define associated Table variables
 */
/**
 * Customizes the `.table` component with basic values, each used across all table variations.
 */
table,
.table {
  width: 100%;
  max-width: 100%;
  margin-top: 32px;
  margin-bottom: 32px;
  border-spacing: 0;
  background-color: #fff;
  border: 2px solid #edf5ff;
  border-radius: 4px; }
  table > thead th,
  table > thead td,
  table > tbody th,
  table > tbody td,
  table > tfoot th,
  table > tfoot td,
  .table > thead th,
  .table > thead td,
  .table > tbody th,
  .table > tbody td,
  .table > tfoot th,
  .table > tfoot td {
    padding: 14px;
    line-height: 1.29;
    vertical-align: top;
    border-right: 1px solid #cacaca; }
  table > thead th,
  table > tbody th,
  table > tfoot th,
  .table > thead th,
  .table > tbody th,
  .table > tfoot th {
    text-align: left;
    border-top: 1px solid #eaeaea; }
    table > thead th:first-child,
    table > tbody th:first-child,
    table > tfoot th:first-child,
    .table > thead th:first-child,
    .table > tbody th:first-child,
    .table > tfoot th:first-child {
      border-left: 1px solid #eaeaea;
      border-radius: 4px 0 0; }
    table > thead th:last-child,
    table > tbody th:last-child,
    table > tfoot th:last-child,
    .table > thead th:last-child,
    .table > tbody th:last-child,
    .table > tfoot th:last-child {
      border-right: 1px solid #eaeaea;
      border-radius: 0 4px 0 0; }
  table > thead td:last-child,
  table > tbody td:last-child,
  table > tfoot td:last-child,
  .table > thead td:last-child,
  .table > tbody td:last-child,
  .table > tfoot td:last-child {
    border-right: none; }
  table > thead th,
  table > thead td,
  .table > thead th,
  .table > thead td {
    vertical-align: bottom;
    border-bottom: 1px solid #eaeaea; }
  table > tbody + tbody,
  .table > tbody + tbody {
    border-top: 1px solid #eaeaea; }
  table table,
  table .table,
  .table table,
  .table .table {
    background-color: #f5f5f5; }
  table:not(.not-striped) tbody :nth-child(odd) th,
  table:not(.not-striped) tbody :nth-child(odd) td,
  .table:not(.not-striped) tbody :nth-child(odd) th,
  .table:not(.not-striped) tbody :nth-child(odd) td {
    background-color: #edf5ff; }

/**
 * Objects > Checkboxes and radios
 * =================================
 * Custom Checkbox and Radio button styling
 *
 * Documentation:
 * https://fozzie.just-eat.com/styleguide/ui-components/forms/checkboxes
 * https://fozzie.just-eat.com/styleguide/ui-components/forms/radio-buttons
 * --------------------------------
 *
 * Checkbox button example HTML
 *
 *    <label class="o-formControl-label" for="checkbox1">
 *        <input class="o-formControl-input" type="checkbox" id="checkbox1" name="myCheckbox" value="A value"/>
 *        <span class="o-formControl-indicator o-formControl-indicator--checkbox"></span>
 *        My Checkbox Label Text
 *    </label>
 *
 * -------------------------------
 *
 * Radio button example HTML
 *
 *    <label class="o-formControl-label" for="radio1">
 *        <input class="o-formControl-input" type="radio" id="radio1" name="myRadioBtn" value="A value"/>
 *        <span class="o-formControl-indicator o-formControl-indicator--radio"></span>
 *        My Radio Button Label Text
 *     </label>
 *
 */
.o-formControl-label {
  position: relative;
  display: block;
  cursor: pointer;
  user-select: none;
  padding: 8px;
  padding-left: 32px; }
  .o-formControl-label:hover .o-formControl-indicator {
    border-color: #266abd; }
  .o-formControl-label:hover .o-formControl-input:disabled ~ .o-formControl-indicator {
    border-color: #eaeaea;
    cursor: not-allowed; }
  .o-formControl-label .o-formControl-input:disabled ~ .o-formControl-indicator {
    background-color: #cacaca; }

.o-formControl-label--inline {
  display: inline-block;
  padding-right: 2em; }

.o-formControl-input {
  position: absolute;
  opacity: 0;
  z-index: -1; }

.o-formControl-indicator {
  position: absolute;
  left: 0;
  display: inline-block;
  width: 24px;
  height: 24px;
  border: 1px solid #eaeaea;
  background-color: #fff;
  vertical-align: middle;
  margin-left: 0;
  margin-top: 0;
  box-shadow: 0 0 0 2px transparent, 0 0 0 0 transparent; }
  @media (min-width: 48em) {
    .o-formControl-indicator {
      width: 20px;
      height: 20px; } }

.o-formControl-indicator--checkbox,
.o-formControl-indicator--tickbox {
  border-radius: 0.2em; }
  .o-formControl-indicator--checkbox:before, .o-formControl-indicator--checkbox:after,
  .o-formControl-indicator--tickbox:before,
  .o-formControl-indicator--tickbox:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    display: block;
    opacity: 0;
    transition: all 250ms ease-in-out; }

.o-formControl-indicator--checkbox:before, .o-formControl-indicator--checkbox:after {
  top: 50%;
  width: 90%;
  height: 0.1em;
  background-color: #f5f5f5; }

.o-formControl-indicator--checkbox:before {
  transform: translate(-50%, -50%) rotate(45deg) scale(0); }

.o-formControl-indicator--checkbox:after {
  transform: translate(-50%, -50%) rotate(-45deg) scale(0); }

.o-formControl-icon {
  background-position: center 0;
  left: 8px;
  min-width: 12px;
  position: absolute; }

.o-formControl-indicator--tickbox:before {
  top: 0;
  transform: translateX(-50%) rotate(45deg) scale(0);
  border: 2px solid #f5f5f5;
  background-color: transparent;
  width: 40%;
  height: 80%;
  border-top: 0;
  border-left: 0; }

.o-formControl-indicator--tickbox:after {
  display: none; }

label:hover .o-formControl-input:checked ~ .o-formControl-indicator--checkbox, label:hover
.o-formControl-input:checked ~ .o-formControl-indicator--tickbox, label:hover
.o-formControl-input:checked ~ .o-formControl-indicator--radio {
  background-color: #14509c;
  border-color: #14509c; }

.o-formControl-input:checked ~ .o-formControl-indicator--checkbox,
.o-formControl-input:checked ~ .o-formControl-indicator--tickbox {
  background-color: #266abd;
  border: 3px solid #266abd; }
  .o-formControl-input:checked ~ .o-formControl-indicator--checkbox:before, .o-formControl-input:checked ~ .o-formControl-indicator--checkbox:after,
  .o-formControl-input:checked ~ .o-formControl-indicator--tickbox:before,
  .o-formControl-input:checked ~ .o-formControl-indicator--tickbox:after {
    opacity: 1; }

.o-formControl-input:checked ~ .o-formControl-indicator--tickbox:before, .o-formControl-input:checked ~ .o-formControl-indicator--tickbox:after {
  transform: translateX(-50%) rotate(45deg) scale(1); }

.o-formControl-input:checked ~ .o-formControl-indicator--checkbox:before {
  transform: translate(-50%, -50%) rotate(45deg) scale(1); }

.o-formControl-input:checked ~ .o-formControl-indicator--checkbox:after {
  transform: translate(-50%, -50%) rotate(-45deg) scale(1); }

.o-formControl-indicator--radio {
  border-radius: 50%; }
  @media (max-width: 64.0525em) {
    .o-formControl-indicator--radio {
      margin-top: 2px; } }
  @media (max-width: 48em) {
    .o-formControl-indicator--radio {
      margin-top: -2px; } }

.o-formControl-input:checked ~ .o-formControl-indicator--radio {
  background-color: #266abd;
  border: 6px solid #eaeaea; }
  .o-formControl-input:checked ~ .o-formControl-indicator--radio:before {
    content: '';
    background-color: #266abd;
    border: 3px solid #eaeaea;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    position: absolute;
    top: -5px;
    left: -5px; }
    @media (min-width: 48em) {
      .o-formControl-input:checked ~ .o-formControl-indicator--radio:before {
        width: 18px;
        height: 18px; } }

/**
 * Objects > Form Toggle
 * =================================
 * Styles a button on top of a checkbox, to toggle inputs to a form
 *
 * Example: Search page filters (sidebat)
 *
 * The `o-formToggle` object is an optional mixin within Fozzie.
 * If you'd like to use it in your project you can include it by adding `@include formToggle();` into your SCSS dependencies file.
 *
 * Documentation:
 * TBC
 */
/**
 * Components:
 * =================================
 * Components are our resuable chunks of styling that aren’t directly associated to a base tag
 *
 * This is where the majority of our day-to-day styling will take place.  Component styles should be prefixed with `.c-`
 */
/**
 * Components > Alert
 * =================================
 * Messages that appear on the screen to highlight a state (usually with associated text)
 *
 * Example: Most form pages or wherever a message is fed back to the user
 *
 * Documentation:
 * TBC
 */
.c-alert {
  padding: 8px;
  margin-top: 16px;
  border: 1px solid transparent;
  border-radius: 2px; }
  .c-alert:first-child {
    margin-top: 0; }
  .c-alert + * {
    margin-top: 16px; }

.c-alert--success {
  background-color: #f2fae2;
  border-color: #dff3b7;
  color: #04822c; }

.c-alert--warning {
  background-color: #fff1df;
  border-color: #ffdbac;
  color: #cd390c; }

.c-alert--danger {
  background-color: #fee;
  border-color: #ffbbbb;
  color: #d50525; }

/**
 * Components > Breadcrumb
 * =================================
 * Used for website navigation breadcrumbs
 *
 * Documentation:
 * https://fozzie.just-eat.com/styleguide/ui-components/breadcrumbs
 */
.c-breadcrumb {
  width: 100%;
  line-height: 2.5; }
  @media (min-width: 48em) {
    .c-breadcrumb {
      font-size: 16px;
      font-size: 1.14286rem; } }

.c-breadcrumb-item {
  float: left;
  color: #535353; }
  .c-breadcrumb-item svg {
    fill: #000; }

.c-breadcrumb-item--home {
  padding-left: 0; }

.c-breadcrumb-item-link {
  text-decoration: none;
  color: #333;
  font-weight: 500; }
  .c-breadcrumb-item-link:hover {
    text-decoration: underline;
    color: #535353; }

.c-breadcrumb-item-icon {
  margin: 0 8px; }

.c-breadcrumb--transparent {
  background-color: transparent; }
  .c-breadcrumb--transparent .c-breadcrumb-item {
    color: #fff; }
    .c-breadcrumb--transparent .c-breadcrumb-item svg {
      fill: #fff; }
  .c-breadcrumb--transparent .c-breadcrumb-item-link {
    color: #fff; }

@media (max-width: 47.99em) {
  .c-breadcrumb--compact .c-breadcrumb-item {
    display: none; }
  .c-breadcrumb--compact .c-breadcrumb-item:nth-last-child(2) {
    display: list-item;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 16px;
    line-height: 2;
    padding-right: 16px; }
    .c-breadcrumb--compact .c-breadcrumb-item:nth-last-child(2) .c-breadcrumb-item-icon {
      float: left; }
      .c-breadcrumb--compact .c-breadcrumb-item:nth-last-child(2) .c-breadcrumb-item-icon svg {
        transform: translateY(-2px) rotate(90deg); } }

/**
 * c-breadcrumb--pill
 * =================================
 * Adds a black transparent background with rounded corners to the breadcrumb list.
 * On smaller screens the icon and text are re-ordered.
 */
.c-breadcrumb--pill {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 12px;
  line-height: normal;
  display: inline-block;
  width: auto;
  padding-right: 12px; }
  .c-breadcrumb--pill a:hover, .c-breadcrumb--pill a:focus {
    color: #fff; }
  @media (min-width: 48em) {
    .c-breadcrumb--pill {
      padding: 0 12px; } }
  @media (max-width: 48em) {
    .c-breadcrumb--pill .c-breadcrumb-item {
      display: flex;
      align-items: center; }
      .c-breadcrumb--pill .c-breadcrumb-item a {
        order: 2; }
      .c-breadcrumb--pill .c-breadcrumb-item svg {
        transform: rotate(90deg); } }
  .c-breadcrumb--pill .c-breadcrumb-item,
  .c-breadcrumb--pill .c-breadcrumb-item-link {
    color: #fff; }

/**
 * Components > Card
 * =================================
 * Used for content listing cards.
 *
 * Documentation:
 * https://fozzie.just-eat.com/styleguide/ui-components/cards
 */
.c-card {
  background-color: #fff;
  display: block;
  margin-bottom: 8px;
  padding: 16px;
  position: relative; }
  @media (min-width: 48em) {
    .c-card {
      margin-bottom: 0; } }

.c-card--noPad {
  padding: 0; }

.c-card--noPadVertical {
  padding-top: 0;
  padding-bottom: 0; }

.c-card--noPadBottom {
  padding-bottom: 0; }

.c-card--negativeSpacing {
  margin-left: -16px;
  margin-right: -16px;
  padding-left: 16px;
  padding-right: 16px; }

@media (max-width: 47.99em) {
  .c-card--negativeSpacing--belowMid {
    margin-left: -16px;
    margin-right: -16px; } }

@media (max-width: 47.99em) {
  .c-card--negativeSpacing--belowMid--small {
    margin-left: -8px;
    margin-right: -8px; } }

.c-card--rounded {
  border-radius: 4px; }

.c-card--rounded--large {
  border-radius: 8px; }

.c-card--outline {
  border: solid 1px #eaeaea; }

@media (max-width: 47.99em) {
  .c-card--outline--aboveMid {
    border: none;
    border-radius: 0; } }

@media (max-width: 47.99em) {
  .c-card--outlineTop--belowMid {
    border-top: solid 1px #eaeaea; } }

.c-card--disabled {
  background: #eaeaea; }

.c-card--noBgColor {
  background-color: transparent; }

.c-card--normaliseLinks {
  text-decoration: none; }
  .c-card--normaliseLinks:hover, .c-card--normaliseLinks:active, .c-card--normaliseLinks:focus {
    color: inherit; }

.c-card-section {
  margin-left: -16px;
  padding-left: 16px;
  width: calc(100% + 32px); }
  @media (min-width: 48em) {
    .c-card-section {
      border-top: solid 1px #eaeaea;
      margin-left: -16px;
      padding-left: 24px; } }

.c-card-section--bottom {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px; }
  .c-card--rounded--large .c-card-section--bottom {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px; }

.c-card-section--highlight {
  background-color: #cd390c;
  border-top: none;
  color: #fff;
  margin-top: 16px;
  padding: 4px 8px; }

.c-card-section--collapsible {
  overflow: hidden;
  padding-bottom: 16px;
  padding-right: 60px;
  position: relative;
  user-select: none; }
  @media (min-width: 48em) {
    .c-card-section--collapsible {
      padding-bottom: 0;
      padding-right: 60px; } }
  .c-card-section--collapsible:hover {
    cursor: pointer; }

.c-card-section--collapsible--noPad {
  margin-left: -32px;
  padding: 0 60px 0 16px;
  width: calc(100% + 64px); }
  @media (min-width: 48em) {
    .c-card-section--collapsible--noPad {
      margin-left: -16px;
      padding: 0 60px 0 24px;
      width: calc(100% + 32px); } }

.c-card-section--collapsed {
  height: 48px; }
  @media (min-width: 48em) {
    .c-card-section--collapsed {
      height: 50px; } }
  @media (min-width: 48em) {
    .c-card-section--collapsed.c-card-section {
      margin-bottom: -16px; } }
  .c-card-section--collapsed p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
    .c-card-section--collapsed p:not(:first-of-type) {
      display: none; }

.c-card-section-indicator {
  height: 6px;
  display: none;
  position: absolute;
  right: 16px;
  top: 22px;
  transform: rotate(180deg);
  transition: transform 0.4s;
  width: 10px; }
  @media (min-width: 48em) {
    .c-card-section-indicator {
      height: 8px;
      right: 24px;
      width: 14px; } }
  .c-card-section--collapsible .c-card-section-indicator {
    display: block; }
  .c-card-section--collapsed .c-card-section-indicator {
    transform: rotate(0); }
  .c-card-section--collapsible--noPad .c-card-section-indicator {
    right: 21px; }
    @media (min-width: 48em) {
      .c-card-section--collapsible--noPad .c-card-section-indicator {
        right: 24px; } }

/**
 * Components > Media element
 * ===================================
 * A common pattern that sees an image followed by a block of information.
 *
 * Example: SERP – Restaurant logos
 *
 * Documentation:
 * https://fozzie.just-eat.com/styleguide/ui-components/mediaElement
 */
.c-mediaElement {
  display: flex;
  align-items: flex-start;
  width: 100%; }
  @media (min-width: 48em) {
    .c-mediaElement > :not(:last-child) {
      margin-right: 8px; } }

.c-mediaElement--fullstack {
  flex-direction: column;
  align-items: center;
  justify-content: center; }

@media (max-width: 47.99em) {
  .c-mediaElement--stack {
    flex-direction: column;
    align-items: center; } }

.c-mediaElement-img {
  line-height: 0;
  margin-right: 5%; }
  .c-mediaElement-img,
  .c-mediaElement-img > img {
    width: 50px; }
    @media (min-width: 25.885em) {
      .c-mediaElement-img,
      .c-mediaElement-img > img {
        width: 78px; } }
    @media (min-width: 64.0625em) {
      .c-mediaElement-img,
      .c-mediaElement-img > img {
        width: 67px; } }
  .c-mediaElement-img,
  .c-mediaElement-img > img {
    border-radius: 3px; }
  .c-mediaElement--fullstack .c-mediaElement-img {
    margin-right: 0;
    flex-basis: auto; }
  .c-mediaElement--stack .c-mediaElement-img {
    flex-basis: auto; }
    @media (max-width: 47.99em) {
      .c-mediaElement--stack .c-mediaElement-img {
        margin-right: 0;
        flex-grow: 1; } }
    @media (min-width: 48em) {
      .c-mediaElement--stack .c-mediaElement-img {
        margin-top: 2px; } }
    @media (min-width: 64.0625em) {
      .c-mediaElement--stack .c-mediaElement-img {
        margin-top: 0; } }

.c-mediaElement-img--outlined {
  border: solid 1px #eaeaea;
  border-radius: 8px; }

.c-mediaElement-img--small {
  max-width: 70px;
  max-height: 70px; }

.c-mediaElement-img--negativeTop {
  margin-top: -56px; }
  @media (min-width: 25.875em) {
    .c-mediaElement-img--negativeTop {
      margin-top: -80px; } }
  @media (min-width: 48em) {
    .c-mediaElement-img--negativeTop {
      margin-top: -50px; } }

.c-mediaElement-content {
  flex-basis: 0;
  flex-grow: 1; }
  .c-mediaElement--fullstack .c-mediaElement-content {
    flex: auto;
    margin-top: 4px; }
  @media (max-width: 47.99em) {
    .c-mediaElement-content br {
      display: none; } }

.c-mediaElement-text {
  margin-top: 0;
  font-size: 12px;
  font-size: 0.85714rem; }

.c-mediaElement-text--large {
  font-size: 24px;
  font-size: 1.71429rem;
  line-height: 1.2; }

.c-mediaElement-infoLink {
  font-size: 16px;
  font-size: 1.14286rem;
  line-height: 1.5;
  align-items: center;
  color: #266abd;
  display: flex;
  position: absolute;
  right: 16px;
  text-decoration: none;
  top: 16px; }
  .c-mediaElement-infoLink:hover {
    text-decoration: underline; }

/**
 * Apps Banner Component
 * =================================
 * Used to display app promo banner on the page.
 *
 * *The `c-appBaner` component is an optional mixin within Fozzie — if you'd like to use it in your project you can include it by adding `@include appsBanner();` into your SCSS dependencies file.*
 *
 * Documentation:
 * https://fozzie.just-eat.com/styleguide/ui-components/apps-banner
 */
/**
 * Components > Badge
 * =================================
 * Used for styling small pieces of information.
 *
 * Examples: Menu – Restaurant delivery information and Search – listing labels
 *
 * The `c-badge` component is an optional mixin within Fozzie.
 * If you'd like to use it in your project you can include it by adding `@include badge();` into your SCSS dependencies file.
 *
 * Documentation:
 * https://fozzie.just-eat.com/styleguide/ui-components/badges
 */
/**
 * Components > Content Header
 * =================================
 * Used to display important contextual info at the top of a page
 *
 * Example: Restaurant count and location on search page
 *
 * The `c-contentHeader` component is an optional mixin within Fozzie.
 * If you'd like to use it in your project you can include it by adding `@include contentHeader();` into your SCSS dependencies file.
 *
 * Documentation:
 * TBC
 */
/**
 * Components > Content title
 * =================================
 * Used for displaying a title with an associated action next to it
 *
 * Example: Filter titles (Search sidebar)
 *
 * The `c-contentTitle` component is an optional mixin within Fozzie.
 * If you'd like to use it in your project you can include it by adding `@include contentTitle();` into your SCSS dependencies file.
 *
 * Documentation:
 * TBC
 */
/**
* Components > Cuisines Widget
* =================================
*
* Example: Homepage – Popular cuisines.
*
* The `c-cuisinesWidget` component is an optional mixin within Fozzie.
* If you'd like to use it in your project you can include it by adding `@include cuisinesWidget();` into your SCSS dependencies file.
*
* Documentation:
* https://fozzie.just-eat.com/styleguide/ui-components/cuisines-widget
*/
/**
 * Components > Fullscreen Popovers
 * =================================
 * Component that styles a full-page popover/takeover on top of the current content
 *
 * Example: Used for the mobile filters fullscreen popover on the Search Page
 *
 * The `c-fullScreenPopOver` component is an optional mixin within Fozzie.
 * If you'd like to use it in your project you can include it by adding `@include fullScreenPopOver();` into your SCSS dependencies file.
 *
 * Documentation:
 * TBC
 */
/**
 * Components > Listing
 * ===================================
 * Component that styles lists of content
 *
 * Example: SERP Page – Restaurant Information Lists
 *
 * The `c-listing` component is an optional mixin within Fozzie.
 * If you'd like to use it in your project you can include it by adding `@include listing();` into your SCSS dependencies file.
 *
 * Documentation:
 * https://fozzie.just-eat.com/styleguide/ui-components/listings
 */
/*
    The mixins below are to help juggle the badge items between stacked and
    inline and targets viewports inbetween mid and wide (landscape mobiles and very small desktops).

    On narrow, mid and wide you will see:

    item • item

    between mid and wide you will see:

    item
    item

    The mixins are used to stop code duplication as each chunk of
    code needs to be within its own media mixin.
*/
@keyframes skeletonGradient {
  0% {
    background-position: 100% 50%; }
  100% {
    background-position: 0% 50%; } }

/**
 * Components > Menu
 * =================================
 * Used for styling small pieces of information.
 *
 * Example: Menu – Categories list.
 *
 * *The `c-menu` component is an optional mixin within Fozzie — if you'd like to use it in your project you can include it by adding `@include menu();` into your SCSS dependencies file.*
 *
 * Documentation:
 * https://fozzie.just-eat.com/styleguide/ui-components/menus
 */
/**
 * Components > Item Selector
 * =================================
 * Used on menu pages to display a modal to the user.
 *
 * Example: Menu – When selecting an item.
 *
 * The `c-modal` component is an optional mixin within Fozzie.
 * If you'd like to use it in your project you can include it by adding `@include modal();` into your SCSS dependencies file.
 *
 * Documentation:
 * TBC
 */
/**
* Components > Order Card
* =================================
*
* Example: Homepage – "Order it Again" block.
*
* The `c-orderCard` component is an optional mixin within Fozzie.
* If you'd like to use it in your project you can include it by adding `@include orderCard();` into your SCSS dependencies file.
*
* Documentation:
* https://fozzie.just-eat.com/styleguide/ui-components/order-card
*/
/**
 * Components > Overflow Carousel
 * --------------------------------
 * Allows a list of content to be scrolled horizontally
 *
 * Example: Used for mobile filter carousel on the Search Page
 *
 * The `c-overflowCarousel` component is an optional mixin within Fozzie.
 * If you'd like to use it in your project you can include it by adding `@include overflowCarousel();` into your SCSS dependencies file.
 *
 * Documentation:
 * TBC
 */
/**
* Components > Page Banner
* =================================
* Page Banners stretch to the full width of their container and contain banner images which typically use a `picture` element.
* Optionally they can display a white or coloured version of the Just Eat rays at the bottom.
*
* Example: Menu – Cuisine image at top of page.
*
* The `c-pageBanner` component is an optional mixin within Fozzie.
* If you'd like to use it in your project you can include it by adding `@include pageBanner();` into your SCSS dependencies file.
*
* Documentation:
* https://fozzie.just-eat.com/styleguide/ui-components/page-banner
*/
/**
 * Components > Rating
 * =================================
 * Used for displaying restuarant rating stars
 *
 * The `c-rating` component is an optional mixin within Fozzie
 * If you'd like to use it in your project you can include it by adding `@include rating();` into your SCSS dependencies file.
 *
 * Documentation:
 * https://fozzie.just-eat.com/styleguide/ui-components/custom/star-ratings
 */
/**
 * Toast Component
 * =================================
 * Used to display text in a pop-up style notification.
 *
 * *The `c-toast` component is an optional mixin within Fozzie — if you'd like to use it in your project you can include it by adding `@include toast();` into your SCSS dependencies file.*
 *
 * Documentation:
 * https://fozzie.just-eat.com/styleguide/ui-components/toast
 */
/**
 * User Message Component
 * =================================
 * Used to display the user message banner on the page.
 *
 * *The `c-userMessage` component is an optional mixin within Fozzie — if you'd like to use it in your project you can include it by adding `@include userMessage();` into your SCSS dependencies file.*
 */
/**
 * Global Layout Definitions & Helpers
 * ===================================
 */
body {
  background-color: #f5f5f5; }

/*
* By default we want to give focus styling to all elements that are focusable.
*/
/**
 * Default layout container
 */
.l-container {
  width: 100%;
  max-width: 1272px;
  margin: 0 auto;
  padding-left: 32px;
  padding-right: 32px; }
  @media (max-width: 47.99em) {
    .l-container {
      padding-left: 16px;
      padding-right: 16px; } }
  @media (max-width: 25.865em) {
    .l-container {
      padding-left: 16px;
      padding-right: 16px; } }

/**
 * Layout container for blocks that don't need padding on the sides
 */
.l-innerContainer {
  width: 100%;
  max-width: 1272px;
  margin: 0 auto; }

.l-innerContainer--verticalSpacing {
  margin: 32px 0; }
  @media (min-width: 48em) {
    .l-innerContainer--verticalSpacing {
      margin: 40px auto 48px; } }
  @media (min-width: 64.0625em) {
    .l-innerContainer--verticalSpacing {
      margin: 64px auto; } }

/**
 * Layout class for containers that contain content (such as T&cs and Privacy pages)
 */
.l-content {
  padding-top: 16px; }
  @media (min-width: 48.01em) {
    .l-content {
      padding-top: 32px; } }

.l-content-header {
  margin: 0 0 16px; }
  @media (min-width: 48.01em) {
    .l-content-header {
      margin: 16px 0 32px; } }
  .l-content-header > h1 {
    font-size: 32px;
    font-size: 2.28571rem;
    line-height: 1.13; }
    @media (min-width: 48.01em) {
      .l-content-header > h1 {
        font-size: 44px;
        font-size: 3.14286rem;
        line-height: 1.09; } }

.l-content-header--centred {
  text-align: center; }

.l-content-header--highlight {
  color: #f50028; }

.l-divider {
  border-bottom: 1px solid #cacaca; }


.l-inlined {
  display: flex;
  align-items: center; }
  .no-flexbox
  .l-inlined {
    display: inline-block; }

.l-inlined--top {
  display: flex;
  align-items: flex-start; }

.l-centred,
.l-centered {
  align-items: center;
  display: flex;
  justify-content: center; }

/**
 * Experiments
 * =================================
 *
 * These styles relate to experiments running on the platform.
 *
 * The filename should _always include the JIRA number so that its status can be checked
 */
/**
 * Utility classes
 * =====================================
 * These should always come last as they should 'trump' other properties
 */
/**
 * Utilities
 * ===================================
 * Non-semantic helper classes
 */
.u-clearfix:after {
  content: ' ';
  display: table;
  clear: both; }

.u-lightenBg {
  background-color: #fff; }

.u-bordered,
.l-content-main {
  border: 1px solid #eaeaea;
  padding: 24px;
  border-radius: 4px; }

@media (min-width: 48.01em) {
  .u-bordered--mid {
    border: 1px solid #eaeaea;
    padding: 24px;
    border-radius: 4px; } }

.is-hidden,
.no-js .is-hidden--noJS {
  display: none !important;
  visibility: hidden !important; }

.is-shown,
.no-js .is-shown--noJS {
  display: block !important;
  visibility: visible !important; }

.is-visuallyHidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }
  .is-visuallyHidden.focusable:active, .is-visuallyHidden.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto; }

.is-invisible {
  visibility: hidden; }

.u-pointer {
  cursor: pointer; }

.is-sticky {
  width: 100%;
  z-index: 100;
  position: fixed;
  transition: top 0.2s ease; }

.u-sticky {
  position: sticky !important;
  top: 16px; }

.u-absolutelyCentered {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%); }

.u-noWrap {
  white-space: nowrap; }

.u-uppercase {
  text-transform: uppercase; }

.u-textPad {
  padding-left: 32px !important; }

.u-text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.u-noUnderline {
  text-decoration: none; }
  .u-noUnderline:hover, .u-noUnderline:active, .u-noUnderline:focus {
    text-decoration: underline; }

.u-text-centre,
.u-text-center {
  text-align: center; }

.u-text-left {
  text-align: left; }

.u-text-right {
  text-align: right; }

.u-text-highlight {
  color: #d50525; }
  em.u-text-highlight {
    font-style: normal; }

.u-text-indent {
  margin-left: 16px; }

.u-color-text {
  color: #535353; }

.u-color-link {
  color: #266abd; }

.u-color-secondary {
  color: #266abd; }

.u-color-text--hint {
  color: #757575; }

.u-color-text--success {
  color: #04822c; }

.u-color-text--danger {
  color: #d50525; }

.u-color-text--warning {
  color: #cd390c; }

.u-noSpacing {
  margin: 0 !important; }

.u-spacingTop {
  margin-top: 8px !important; }

.u-spacingTop--small {
  margin-top: 4px !important; }

.u-spacingTop--large {
  margin-top: 16px !important; }

.u-spacingRight {
  margin-right: 8px !important; }

.u-spacingBottom {
  margin-bottom: 8px !important; }

.u-spacingBottom--large {
  margin-bottom: 16px !important; }

.u-spacingLeft {
  margin-left: 8px !important; }

.u-noPad {
  padding: 0 !important; }

.u-padTop {
  padding-top: 8px !important; }

.u-padTop--large {
  padding-top: 16px !important; }

@media (min-width: 48em) {
  .u-padTop--large--aboveMid {
    padding-top: 16px !important; } }

.u-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0; }

.u-overlay--after:after {
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  content: '';
  left: 0;
  position: fixed;
  right: 0;
  top: 0; }

.u-preventScroll {
  overflow: hidden; }

[data-lazy-image-src] {
  display: block; }
  .no-js [data-lazy-image-src] {
    display: none; }

.o-btn--icon,
.u-ir {
  background-color: transparent;
  background-repeat: no-repeat;
  border: 0;
  direction: ltr;
  display: block;
  overflow: hidden;
  text-align: left;
  text-indent: -999em; }
  .o-btn--icon br,
  .u-ir br {
    display: none; }

.u-hideText,
.c-icon--star {
  text-indent: 110%;
  white-space: nowrap;
  overflow: hidden; }

.u-unstyled,
.c-nav-list,
.c-nav-popoverList,
.c-countrySelector-list,
.c-footer-list,
.c-menu {
  margin-top: 0;
  margin-left: 0;
  padding: 0;
  list-style: none;
  list-style-image: none; }
  .u-unstyled > li,
  .c-nav-list > li,
  .c-nav-popoverList > li,
  .c-countrySelector-list > li,
  .c-footer-list > li,
  .c-menu > li {
    margin-bottom: 0; }
    .u-unstyled > li:before,
    .c-nav-list > li:before,
    .c-nav-popoverList > li:before,
    .c-countrySelector-list > li:before,
    .c-footer-list > li:before,
    .c-menu > li:before {
      content: none; }

/* Custom outline styling for elements that have a focus state */
.o-formControl-input:focus + .o-formControl-indicator, .o-formControl-input:focus ~ .o-formControl-text, :focus, .o-formToggle:not(.o-formToggle--disabled):focus .o-formToggle-text:after, .o-formToggle-input:not([disabled]):focus ~ .o-formToggle-text:after,
.o-formToggle-input:not(:disabled):focus ~ .o-formToggle-text:after,
.u-elementFocus {
  outline: 2px solid #ff9e16; }

/* Custom visual outline emulation for better appearance on elements with rounded corners */

.u-elementFocus--boxShadow {
  outline: none;
  box-shadow: 0 0 0 2px #ff9e16; }

/**
 * Responsive Trumps
 * ===================================
 */
@media (min-width: 25.875em) {
  .u-showBelowNarrow {
    display: none !important; } }

@media (min-width: 48em) {
  .u-showBelowMid {
    display: none !important; } }

@media (min-width: 64.0625em) {
  .u-showBelowWide {
    display: none !important; } }

@media (min-width: 80em) {
  .u-showBelowHuge {
    display: none !important; } }

@media (max-width: 25.865em) {
  .u-showAboveNarrow {
    display: none !important; } }

@media (max-width: 47.99em) {
  .u-showAboveMid,
  .no-js .u-showAboveMid--noJS {
    display: none !important; } }

@media (max-width: 64.0525em) {
  .u-showAboveWide {
    display: none !important; } }

@media (max-width: 79.99em) {
  .u-showAboveHuge {
    display: none !important; } }

/**
 * Print styles
 */
/**
 * Print styles
 * =================================
 */
@media print {
  *,
  *:before,
  *:after,
  *:first-letter {
    background: transparent !important;
    color: #000 !important;
    /* Black prints faster: http://www.sanbeiji.com/archives/953 */
    box-shadow: none !important;
    text-shadow: none !important; }
  a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  /*
     * Don't show links for images, or javascript/internal links
     */
  .ir a:after,
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: ''; }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group;
    /* h5bp.com/t */ }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: 0.5cm; }
  p, h2, h3 {
    orphans: 3;
    widows: 3; }
  h2, h3 {
    page-break-after: avoid; } }

/**
 * f-icons
 * =================================
 * Common icons for use in Fozzie projects.
 *
 * Documentation: tbc
 * Repo: https://github.com/justeat/f-icons
 *
 * Index:
 * - Imports
 * - Partials
 * - Base Component styles
 *
 */
/**
 * Imports
 */
/**
 * Core variables
 */
/**
 * Variables
 * =================================
 */
/**
 * Partials
 */
/**
 * Appstore icons
 * =================================
 *
 */
/**
 * Base Component styles
 */
.c-icon--android {
  height: 40px;
  width: 135px; }

.c-icon--ios {
  height: 40px;
  width: 135px; }

.c-icon--microsoft {
  height: 40px;
  width: 111px; }

/**
 * Arrow icons
 * =================================
 *
 */
/**
 * Base Component styles
 */
.c-icon--chevron {
  height: 11px;
  width: 20px; }

.c-icon--chevron--small {
  height: 6px;
  width: 14px; }

.c-icon--chevron--up {
  transform: rotate(180deg); }

.c-icon--chevron--left {
  transform: translateY(-2px) rotate(90deg); }

.c-icon--chevron--right {
  transform: translateY(-2px) rotate(-90deg); }

.c-icon--chevron--light path {
  fill: #fff; }

/**
 * Card icons
 * =================================
 *
 */
/**
 * Base Component styles
 */
.c-icon--amex {
  height: 24px;
  width: 40px; }

.c-icon--amex--safekey {
  height: 20px;
  width: 63px; }

.c-icon--dk {
  height: 26px;
  width: 43px; }

.c-icon--interac {
  height: 26px;
  width: 52px; }

.c-icon--maestro {
  height: 24px;
  width: 40px; }

.c-icon--mastercard {
  height: 24px;
  width: 40px; }

.c-icon--mastercard--securecode {
  height: 24px;
  width: 67px; }

.c-icon--paypal {
  height: 25px;
  width: 94px; }

.c-icon--visa {
  height: 24px;
  width: 40px; }

.c-icon--visa--verified {
  height: 24px;
  width: 56px; }

/**
 * Certificate icons
 * =================================
 *
 */
/**
 * Base Component styles
 */
.c-icon--confianza {
  height: 50px;
  width: 50px; }

/**
 * Flag icons
 * =================================
 *
 */
/**
 * Base Component styles
 */
.c-icon--flag {
  height: 40px;
  width: 60px; }

.c-icon--flag--small {
  height: 16px;
  width: 24px; }

/**
 * General icons
 * =================================
 *
 */
/**
 * Base Component styles
 */
.c-icon--alcoholAge {
  height: 20px;
  width: 20px; }

.c-icon--alert {
  height: 16px;
  width: 16px; }

.c-icon--basket {
  height: 24px;
  width: 24px; }

.c-icon--basket--large {
  height: 30px;
  width: 30px; }

.c-icon--collection {
  height: 24px;
  width: 24px; }

.c-icon--cuisine {
  height: 19px;
  width: 17px; }

.c-icon--delivery {
  height: 24px;
  width: 24px; }

.c-icon--delivery--small {
  height: 16px;
  width: 16px; }

.c-icon--driver {
  height: 28px;
  width: 28px; }

.c-icon--driver--small {
  height: 20px;
  width: 20px; }

.c-icon--info {
  height: 22px;
  width: 22px; }

.c-icon--nutAllergy {
  height: 28px;
  width: 28px; }

.c-icon--offer {
  height: 28px;
  width: 28px; }

.c-icon--offer--small {
  height: 20px;
  width: 20px; }

.c-icon--spicy {
  height: 20px;
  width: 20px; }

.c-icon--questionMark {
  height: 28px;
  width: 28px; }

.c-icon--questionMark--small {
  height: 20px;
  width: 20px; }

.c-icon--receipt {
  height: 28px;
  width: 28px; }

.c-icon--receipt--small {
  height: 20px;
  width: 20px; }

.c-icon--restaurant {
  height: 28px;
  width: 28px; }

.c-icon--restaurant--small {
  height: 20px;
  width: 20px; }

.c-icon--tick {
  height: 12px;
  width: 16px; }

.c-icon--tick--blue {
  fill: #266abd; }

.c-icon--tick--green {
  fill: #04822c; }

.c-icon--vegetarian {
  height: 20px;
  width: 20px; }

.c-icon--collectionBag {
  height: 20px;
  width: 20px; }

.c-icon--padlock {
  height: 20px;
  width: 20px; }

.c-icon--localLegend {
  height: 32px;
  width: 87px; }

.c-icon--localLegend--condensed {
  height: 32px;
  width: 56px; }

.c-icon--warning {
  height: 12px;
  width: 14px; }

/**
 * Operator icons
 * =================================
 *
 */
/**
 * Base Component styles
 */
.c-icon--plus {
  height: 14px;
  width: 14px; }

.c-icon--plus--large {
  height: 20px;
  width: 20px; }

.c-icon--plus--thin {
  height: 21px;
  width: 20px; }

.c-icon--minus {
  height: 2px;
  width: 11px; }

.c-icon--minus--large {
  height: 20px;
  width: 20px; }

.c-icon--cross {
  height: 8px;
  width: 8px; }

.c-icon--cross--large {
  height: 16px;
  width: 16px; }

/**
 * Tool icons
 * =================================
 *
 * Icons for searching, control and adjustment
 *
 */
.c-icon--eyeglass {
  height: 19px;
  width: 16px; }

.c-icon--slidersHorizontal {
  height: 19px;
  width: 22px; }

.c-icon--sortAmount {
  height: 16px;
  width: 16px; }

.c-icon--mapPin {
  height: 10px;
  width: 10px; }

/**
 * Star icons
 * =================================
 *
 */
/**
 * Base Component styles
 */
.c-icon--star {
  height: 14px;
  width: 14px; }

.c-icon--star--medium {
  height: 28px;
  width: 28px; }

.c-icon--star--large {
  height: 42px;
  width: 42px; }

.c-icon--star--fill,
.c-rating--fill,
.c-ratingSelectable-input:checked + label,
.c-ratingSelectable-input:checked ~ input + label,
.c-icon--star:hover,
.c-icon--star:hover ~ label {
  background-image: inline("dist/img/icons/stars/star--filled.svg"); }

.c-icon--star--fill--orange {
  background-image: inline("dist/img/icons/stars/star--filled--orange.svg"); }

.c-icon--star--fill--yellow {
  background-image: inline("dist/img/icons/stars/star--filled--yellow.svg"); }

.c-icon--star--empty,
.c-rating,
.c-icon--star {
  background-image: inline("dist/img/icons/stars/star--empty.svg"); }

/**
 * Social icons
 * =================================
 *
 */
/**
 * Base Component styles
 */
.c-icon--social {
  height: 28px;
  width: 28px; }

/**
 * Lunch icons
 * =================================
 *
 */
.c-icon--loyaltyStamp {
  height: 30px;
  width: 30px; }

/**
 * Base Component styles
 */
[class*='c-icon'] {
  speak: none;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat; }

/**
 * Base Icon Class
 *
 * Used when we have a standalone icon
 */
.c-icon {
  display: block; }

.c-icon--inline {
  display: inline-block;
  vertical-align: middle; }

.c-icon--pushLeft {
  float: left; }

.c-icon--white * {
  fill: #fff; }

.c-icon--blue * {
  fill: #266abd; }

.c-icon--green * {
  fill: #04822c; }

.c-icon--orange * {
  fill: #cd390c; }

.c-icon--grey--darkest * {
  fill: #333; }

.c-icon--semiTransparent {
  opacity: 0.5; }

/**
 * f-header
 * =================================
 * Header Component for Just Eat projects
 *
 * This component allows a project to 'install' via a mixin the styles required
 * to show the Just Eat header at the top of their site or application.
 *
 * Documentation: tbc
 * Repo: https://github.com/justeat/f-header
 *
 * Index:
 * - Components Variables
 * - Imports
 *  - Colour palette
 *  - Partials
 * - Base Component styles
 *
 */
/**
 * Infobar Styling
 * =================================
 * A full page width bar that can contain information relevant to the page
 *
 * Examples of its use include the cookie banner and the langauge switcher banner
 */
.c-infoBar {
  width: 100%;
  background-color: #f5f5f5;
  z-index: 99; }
  .c-infoBar,
  .c-infoBar p,
  .c-infoBar .btn {
    font-size: 14px;
    font-size: 1rem;
    line-height: 1.29; }
  .c-infoBar p {
    margin-top: 0; }
  .c-infoBar a {
    color: #000; }
  .c-infoBar p > a {
    text-decoration: underline; }

.c-infoBar-row {
  padding-top: 4px;
  padding-bottom: 4px; }
  @media (min-width: 48em) {
    .c-infoBar-row {
      padding-top: 8px;
      padding-bottom: 8px; } }

.c-infoBar-btn {
  float: right;
  margin-left: 8px; }

.c-infoBar--dark {
  background-color: #333; }
  .c-infoBar--dark,
  .c-infoBar--dark a {
    color: #fff; }
  .c-infoBar--dark .btn {
    color: initial; }

.c-infoBar--hideByDefault {
  max-height: 0;
  overflow: hidden;
  transition: all 1000ms ease-in-out; }
  .c-infoBar--hideByDefault.is-active {
    max-height: 200px;
    transition: 1s all ease-out 1.5s; }

/**
 * Language Switcher Styling
 * =================================
 *
 */
.c-languageSwitcher {
  position: relative;
  display: block;
  color: #fff;
  padding: 8px 0;
  text-align: right; }

.c-languageSwitcher-action {
  font-weight: 500; }

.c-languageSwitcher-icon {
  display: inline-block; }

.c-languageSwitcher-countryCode {
  position: absolute;
  left: 14px;
  padding: 0 5px;
  border: 1px solid #fff;
  border-radius: 1px;
  text-transform: uppercase; }

.c-header {
  background-color: #fff;
  min-width: 300px;
  position: relative;
  z-index: 100; }
  @media (max-width: 47.99em) {
    .is-navInView .c-header {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 9000; } }
  @media (max-width: 47.99em) {
    .c-header.is-sticky {
      left: 0;
      top: -60px;
      position: fixed; }
    .c-header.is-sticky-scrollingUp {
      top: 0; } }
  @media (min-width: 48em) {
    .c-header {
      border-bottom: 4px solid #eaeaea; } }

.c-header--bordered {
  border-bottom: 4px solid #eaeaea; }

.c-header--transparent {
  background-color: transparent;
  border: none;
  position: absolute;
  width: 100%; }

.c-header--gradient:before {
  content: '';
  height: 115px;
  opacity: 0.7;
  background-image: linear-gradient(to top, transparent, #000);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; }

.c-header-wrap {
  position: relative;
  min-height: 56px; }
  @media (min-width: 48em) {
    .c-header-wrap {
      display: flex;
      min-height: 76px; } }

.c-header-button {
  top: 0;
  right: 0;
  border: 0;
  padding: 0;
  line-height: 1;
  background: none;
  appearance: none;
  position: absolute;
  width: 56px;
  height: 56px;
  z-index: 8999; }
  .is-sticky .c-header-button {
    top: -56px; }

.c-header-buttonIcon {
  width: 28px;
  height: 15px;
  display: inline-block; }
  .c-header-buttonIcon svg {
    fill: #266abd; }

.c-header-buttonCount {
  top: 0;
  right: 0;
  min-width: 16px;
  padding: 1px 3px 0;
  text-align: center;
  border-radius: 8px;
  position: absolute;
  font-size: 12px;
  font-size: 0.85714rem;
  color: #fff;
  background: #266abd;
  border: 1px solid #fff; }

.c-header-button--primary {
  display: block;
  width: 40px;
  padding-right: 8px; }

.c-header-button--secondary {
  right: 40px; }

/**
 * Logo Styling
 * =================================
 */
.c-logo {
  text-align: center; }
  .c-logo > a {
    display: block;
    display: flex;
    justify-content: center;
    height: 56px;
    padding-top: 22px; }
    @media (min-width: 48em) {
      .c-logo > a {
        justify-content: left;
        height: 76px;
        padding-top: 24px; } }

.c-logo-img {
  width: 82px;
  height: 16px; }
  @media (min-width: 48em) {
    .c-logo-img {
      width: 165px;
      height: 32px; } }

/**
 * Global Page Navigation
 * =================================
 * Styles relating to the global site navigation
 *
 */
@media (max-width: 47.99em) {
  .is-navInView {
    overflow: hidden; }
    .is-navInView body {
      position: fixed;
      width: 100%;
      padding-top: 56px; }
      .is-multiLanguage.is-navInView body {
        padding-top: 90px; } }

.is-navInView--noPad body {
  padding-top: 0; }

@media (min-width: 48em) {
  .c-nav--global {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1; } }

@media (max-width: 47.99em) {
  .c-nav--global .c-nav-container {
    position: fixed;
    top: 56px;
    left: -99999px;
    width: 100%;
    padding: 0;
    height: calc(100% - (56px));
    border-top: 4px solid #eaeaea;
    background: #fff;
    z-index: -1;
    opacity: 0;
    transition: opacity 250ms ease-in-out, z-index 0s linear 250ms, left 0s linear 400ms; }
    .c-nav--global .c-nav-container.is-visible {
      overflow-y: auto;
      left: 0;
      opacity: 1;
      z-index: 9000;
      transition: opacity 250ms ease-in-out, z-index 0s linear; }
    .is-multiLanguage .c-nav--global .c-nav-container {
      top: 90px;
      height: calc(100% - (90px)); } }

.c-nav-list {
  position: relative; }

@media (max-width: 47.99em) {
  .c-nav-list,
  .c-nav-popoverList {
    display: flex;
    flex-direction: column; } }

.c-nav-list-item {
  margin-bottom: 0; }
  @media (min-width: 48em) {
    .c-nav--global .c-nav-list-item {
      float: left; } }

@media (max-width: 47.99em) {
  .c-nav-list-item--forceLast {
    position: absolute;
    top: 100%;
    width: 100%; } }

.c-nav-list-link,
.c-nav-list-text {
  display: block;
  padding: 12px 16px;
  margin: 0;
  font-family: "Hind Vadodara", "Helvetica Neue", Helvetica, sans-serif;
  color: #535353;
  font-size: 14px;
  font-size: 1rem;
  line-height: 1.29;
  font-weight: 300;
  text-decoration: none;
  border-bottom: 1px solid #eaeaea; }
  @media (min-width: 48em) {
    .c-nav-list-link,
    .c-nav-list-text {
      font-family: "Ubuntu";
      font-size: 16px;
      font-size: 1.14286rem;
      line-height: 1.5;
      font-weight: 500;
      color: #266abd;
      border-bottom: none;
      display: flex;
      align-items: center;
      height: 76px; }
      .c-header--transparent .c-nav-list-link, .c-header--transparent
      .c-nav-list-text {
        color: #fff; } }

.c-nav-list-text-sub {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 300px; }

.c-nav-list-link:hover, .c-nav-list-link:focus, .c-nav-list-link:active {
  text-decoration: none; }
  @media (min-width: 48em) {
    .c-nav-list-link:hover, .c-nav-list-link:focus, .c-nav-list-link:active {
      color: #266abd;
      text-decoration: underline; }
      .c-header--transparent .c-nav-list-link:hover, .c-header--transparent .c-nav-list-link:focus, .c-header--transparent .c-nav-list-link:active {
        color: #fff; }
      .c-header--transparent .c-nav-popoverList .c-nav-list-link:hover, .c-header--transparent .c-nav-popoverList .c-nav-list-link:focus, .c-header--transparent .c-nav-popoverList .c-nav-list-link:active {
        color: inherit; } }

@media (min-width: 48em) {
  .has-sublist {
    position: relative;
    cursor: pointer; } }

.c-nav-icon {
  float: left;
  margin-right: 8px; }
  @media (min-width: 48em) {
    .c-nav-icon path {
      fill: #266abd; }
      .c-header--transparent .c-nav-icon path {
        fill: #fff; } }

@media (min-width: 48em) {
  .c-nav-popoverList {
    position: absolute;
    top: 100%;
    right: 99999px;
    width: 300px;
    background-color: #fff;
    border: 1px solid #eaeaea;
    box-shadow: 0 2px 40px rgba(0, 0, 0, 0.2);
    border-top: 0;
    border-radius: 0 0 3px 3px;
    padding: 0 16px;
    opacity: 0;
    z-index: -1;
    transition: opacity 200ms ease-in-out 200ms, z-index 0s linear 400ms, right 0s linear 400ms; }
    .c-nav-popoverList:before {
      bottom: 100%;
      right: 10%;
      border: 10px solid transparent;
      border-bottom: 10px solid #fff;
      content: '';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none; }
    .c-nav-popoverList .c-nav-list-item {
      float: none;
      border-bottom: 1px solid #eaeaea; }
      .c-nav-popoverList .c-nav-list-item:last-child {
        border-bottom: none; }
        .c-nav-popoverList .c-nav-list-item:last-child .c-nav-list-link,
        .c-nav-popoverList .c-nav-list-item:last-child .c-nav-list-text {
          border-radius: 0 0 3px 3px; }
    .c-nav-popoverList .c-nav-list-link,
    .c-nav-popoverList .c-nav-list-text {
      font-family: "Hind Vadodara", "Helvetica Neue", Helvetica, sans-serif;
      font-weight: 300;
      color: #535353;
      height: auto; }
      .c-nav-popoverList .c-nav-list-link:hover, .c-nav-popoverList .c-nav-list-link:focus,
      .c-nav-popoverList .c-nav-list-text:hover,
      .c-nav-popoverList .c-nav-list-text:focus {
        font-weight: 500;
        text-decoration: none; }
    .has-sublist:hover .c-nav-popoverList,
    .has-sublist:focus .c-nav-popoverList,
    .has-sublist:active .c-nav-popoverList {
      opacity: 1;
      z-index: 9000;
      right: 0;
      transition: opacity 200ms ease-in-out, z-index 0s linear; } }

.c-nav-trigger {
  position: absolute;
  top: -100px;
  left: -100px; }
  @media (min-width: 48em) {
    .c-nav-trigger {
      display: none; } }
  @media (max-width: 47.99em) {
    .c-nav-trigger:checked ~ .c-nav-container {
      overflow-y: auto;
      left: 0;
      opacity: 1;
      z-index: 9000;
      transition: opacity 250ms ease-in-out, z-index 0s linear; } }
  .c-nav-trigger:focus ~ .c-nav-toggle {
    background-color: #edf5ff;
    box-shadow: 0 0 6px 0 #266abd; }
    .c-header--transparent .c-nav-trigger:focus ~ .c-nav-toggle {
      background-color: transparent; }

.c-nav-toggle {
  position: absolute;
  top: 0;
  left: 0;
  width: 56px;
  height: 56px;
  cursor: pointer; }
  @media (min-width: 48em) {
    .c-nav-toggle {
      display: none; } }

.c-nav-toggle-icon {
  display: block;
  top: 50%;
  left: 16px;
  width: 21px;
  text-indent: -150px;
  white-space: nowrap;
  transition: background-color 250ms ease-in; }
  .c-nav-toggle-icon, .c-nav-toggle-icon:before, .c-nav-toggle-icon:after {
    position: absolute;
    height: 2px;
    background-color: #266abd;
    border-radius: 1px; }
    .c-header--transparent .c-nav-toggle-icon, .c-header--transparent .c-nav-toggle-icon:before, .c-header--transparent .c-nav-toggle-icon:after {
      background-color: #fff; }
  .c-nav-toggle-icon:before, .c-nav-toggle-icon:after {
    content: '';
    width: 100%;
    left: 0;
    transition: all 250ms ease-in-out; }
  .c-nav-toggle-icon:before {
    top: -7px; }
  .c-nav-toggle-icon:after {
    top: 7px; }

.c-nav-trigger:checked ~ .c-nav-toggle > .c-nav-toggle-icon,
.c-nav-toggle.is-open > .c-nav-toggle-icon {
  background-color: transparent; }
  .c-nav-trigger:checked ~ .c-nav-toggle > .c-nav-toggle-icon:before, .c-nav-trigger:checked ~ .c-nav-toggle > .c-nav-toggle-icon:after,
  .c-nav-toggle.is-open > .c-nav-toggle-icon:before,
  .c-nav-toggle.is-open > .c-nav-toggle-icon:after {
    top: 0; }
  .c-nav-trigger:checked ~ .c-nav-toggle > .c-nav-toggle-icon:before,
  .c-nav-toggle.is-open > .c-nav-toggle-icon:before {
    transform: rotate(45deg); }
  .c-nav-trigger:checked ~ .c-nav-toggle > .c-nav-toggle-icon:after,
  .c-nav-toggle.is-open > .c-nav-toggle-icon:after {
    transform: rotate(-45deg); }

/**
 * Skip To Styling
 * =================================
 * Styles relating to the "skip link" element.
 */
.c-skipTo {
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  z-index: 9000; }
  .c-skipTo .is-visuallyHidden.focusable:active, .c-skipTo .is-visuallyHidden.focusable:focus {
    display: block;
    margin: 4px;
    padding: 4px; }

.c-skipTo--whiteLink .is-visuallyHidden.focusable:active, .c-skipTo--whiteLink .is-visuallyHidden.focusable:focus {
  color: #fff; }

/**
 * f-footer
 * =================================
 * Footer Component for Just Eat projects
 *
 * This component allows a project to 'install' via a mixin the styles required
 * to show the Just Eat footer at the bottom of their site or application.
 *
 * Documentation: tbc
 * Repo: https://github.com/justeat/f-footer
 *
 * Index:
 * - Imports
 * - Component Variables
 * - Partials
 * - Base Component styles
 *
 */
/**
 * Imports
 */
/**
 * Component Variables
 */
/**
 * Partials
 */
/**
 * Country Selector styling
 * =================================
 * Styles relating to the country selector
 *
 */
/**
 * Component Variables
 */
/**
 * Base Component styles
 */
.c-countrySelector {
  position: relative; }

.c-countrySelector-list {
  background-color: #f5f5f5;
  box-shadow: 0 2px 28px rgba(51, 51, 51, 0.08);
  list-style: none;
  min-width: 190px;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 9000; }
  .c-countrySelector-list li:first-child {
    margin-top: 8px; }
  .c-countrySelector-list li:last-child {
    border-top: solid 1px #eaeaea;
    display: flex;
    margin-top: 8px;
    justify-content: space-between; }

.c-countrySelector-link {
  align-items: center;
  color: #535353;
  display: flex;
  padding: 8px 16px;
  position: relative;
  text-decoration: none; }
  .c-countrySelector-link p {
    margin: 0 0 0 8px; }

.c-countrySelector-link--selected {
  font-weight: 500;
  padding: 16px 0 16px 16px; }

.c-countrySelector-flag {
  height: 16px;
  width: 24px; }

.c-countrySelector-chevron {
  height: 6px;
  margin-left: 8px;
  width: 14px;
  fill: #535353; }

.c-countrySelector-cross {
  align-self: center;
  fill: #266abd;
  height: 24px;
  margin-left: auto;
  margin-right: 8px;
  padding: 8px;
  width: 24px; }
  .c-countrySelector-cross:hover {
    cursor: pointer; }

/**
 * Base Component styles
 */
.c-footer {
  background-color: #eaeaea;
  color: #535353; }

.c-footer-heading {
  font-size: 20px;
  font-size: 1.42857rem;
  line-height: 1.2;
  padding: 16px;
  position: relative;
  user-select: none; }
  @media (min-width: 64.0625em) {
    .c-footer-heading {
      padding-left: 0;
      padding-top: 0; } }

.c-footer-row {
  padding: 0; }
  @media (min-width: 64.0625em) {
    .c-footer-row {
      padding: 32px;
      position: relative; } }

.c-footer-row--light {
  background-color: #f5f5f5;
  margin: 0;
  padding: 16px; }
  @media (max-width: 64.0525em) {
    .c-footer-row--light .c-footer-heading {
      padding: 0 0 8px; }
    .c-footer-row--light .c-footer-list {
      margin-left: 0; } }
  @media (min-width: 64.0625em) {
    .c-footer-row--light {
      margin: 0;
      padding: 0; } }

@media (max-width: 64.0525em) {
  .c-footer-panel {
    border-bottom: 1px solid #cacaca;
    cursor: pointer; }
    .c-footer-panel .c-footer-chevron {
      transform: rotate(180deg); }
    .c-footer-panel.is-collapsed .c-footer-chevron {
      transform: rotate(0); }
    .c-footer-panel.is-collapsed .c-footer-list {
      display: none; } }

.c-footer-panel:last-of-type {
  border-bottom: none; }

@media (min-width: 64.0625em) {
  .c-footer-panel .c-footer-heading {
    padding: 0; } }

.c-footer-panel a {
  color: #535353;
  display: inline-block;
  padding: 8px 16px;
  text-decoration: none;
  width: 100%; }
  @media (min-width: 64.0625em) {
    .c-footer-panel a {
      padding: 0 0 8px;
      width: auto; } }
  .c-footer-panel a:hover {
    text-decoration: underline; }

.c-footer-chevron {
  position: absolute;
  top: 24px;
  right: 16px;
  fill: #535353;
  height: 11px;
  width: 20px; }

.c-footer-list {
  margin-bottom: 16px;
  margin-left: 16px; }
  @media (min-width: 64.0625em) {
    .c-footer-list {
      margin: 16px 0 0 0; } }

.c-footer-list--inline {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  margin-top: 0; }
  @media (min-width: 64.0625em) {
    .c-footer-list--inline {
      margin-bottom: 0; } }

.c-footer-list-item + .c-footer-list-item {
  margin-left: 16px; }

.c-footer-list-item > a {
  display: block; }

@media (min-width: 64.0625em) {
  .c-footer-social,
  .c-footer-cards {
    order: 2; } }

@media (min-width: 64.0625em) {
  .c-footer-feedback,
  .c-footer-countries {
    order: 1; } }

@media (max-width: 48em) {
  .c-footer-feedback.is-invisible {
    display: none; } }

.c-footer-feedbackText {
  margin: 0 16px 0 0; }

.c-footer-countries {
  display: flex;
  flex-direction: column;
  padding: 0; }
  @media (min-width: 64.0625em) {
    .c-footer-countries {
      align-items: center;
      flex-direction: row;
      padding: 0; } }
  .c-footer-countries .c-footer-vatNumber {
    flex-basis: 50%;
    margin: 16px 0 0 0; }
    @media (min-width: 64.0625em) {
      .c-footer-countries .c-footer-vatNumber {
        margin: 0 0 0 16px; } }

.c-footer-cards {
  border-bottom: solid 1px #cacaca;
  padding: 24px 16px; }
  @media (min-width: 64.0625em) {
    .c-footer-cards {
      border: none;
      display: flex;
      justify-content: flex-end;
      padding: 0; } }
  .c-footer-cards .c-footer-list {
    margin: 0; }
    @media (min-width: 64.0625em) {
      .c-footer-cards .c-footer-list {
        float: right; } }

@media (max-width: 64.0525em) {
  .c-footer-certificate {
    flex-basis: 100%;
    margin-top: 24px;
    order: 1; } }

.c-footer-icon {
  display: block; }
  @media (min-width: 64.0625em) {
    .c-footer-icon {
      margin-bottom: 16px; } }

.c-footer-icon--social {
  height: 28px;
  fill: #535353;
  transition: all 150ms ease-in-out;
  width: 28px; }
  .c-footer-icon--social:hover {
    opacity: 0.6; }

.c-footer-icon--card {
  height: 25px;
  margin-bottom: 0; }
  @media (min-width: 64.0625em) {
    .c-footer-icon--card {
      margin: 0 0 0 32px; } }

.c-footer-icon--certificate {
  height: 50px;
  margin: 0 32px 0 0; }
  @media (min-width: 64.0625em) {
    .c-footer-icon--certificate {
      margin: 0 16px 0 32px; } }

/**
 * Fozzie Extensions
 * =================================
 * Fozzie includes a number of generic component styles that can
 * be accessed via mixins
 *
 */
.c-badge {
  background-color: #f5f5f5;
  padding: 1px 5px;
  position: relative;
  white-space: nowrap; }

.c-badge--rounded {
  border-radius: 14px;
  padding: 4px 16px; }

.c-badge--light {
  background-color: #fff; }

.c-badge--transparent {
  background: none; }

.c-badge--info {
  color: #04822c; }

.c-badge--success {
  background-color: #f2fae2;
  color: #04822c; }

.c-badge--success--alt {
  background-color: #04822c;
  color: #fff; }

.c-badge--warning {
  color: #cd390c; }

.c-badge--alert {
  color: #d50525; }

.c-badge--important {
  background-color: #cd390c;
  color: #fff; }

.c-badge--award {
  background-color: #ead9f0;
  color: #3b1249; }

.c-badge--dark {
  background-color: #333;
  color: #fff; }

.c-badge--indicator {
  background-color: #fff;
  color: #266abd; }

.c-badge--large {
  font-size: 16px;
  font-size: 1.14286rem;
  line-height: 1.5; }

.c-badge--noPad {
  padding: 0; }

.c-badge--angled {
  display: inline-block;
  margin: 0 8px; }
  .c-badge--angled:before, .c-badge--angled:after {
    background-color: inherit;
    border-radius: 2px;
    content: '';
    height: 100%;
    position: absolute;
    top: 0;
    transform-origin: 100% 0;
    transform: skew(-15deg); }
  .c-badge--angled:before {
    left: -1px;
    width: 8px; }
  .c-badge--angled:after {
    right: -8px;
    width: 9px; }

.c-badge--angledEnd {
  margin-right: 0;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px; }
  .c-badge--angledEnd:after {
    display: none; }

.c-badge-icon:after {
  content: "•";
  display: inline-block;
  color: #bbb; }

.o-formToggle {
  position: relative;
  display: inline-block;
  margin-bottom: 8px;
  border-radius: 8px;
  padding: 4px 8px;
  border: 1px solid #eaeaea; }
  @media (min-width: 48em) {
    .o-formToggle {
      background: #fff; } }

.o-formToggle--button {
  padding-left: 32px;
  color: #266abd;
  background: #edf5ff;
  border-color: #edf5ff; }

.o-formToggle--buttonIcon {
  top: 50%;
  position: absolute;
  left: 8px;
  transform: translateY(-50%);
  fill: #266abd; }

@media (max-width: 47.99em) {
  .o-formToggle--largeTouchArea {
    margin: 8px 0 8px 4px; } }

.o-formToggle--fullWidth {
  width: 100%;
  display: block; }
  @media (min-width: 48em) {
    .o-formToggle--fullWidth {
      display: inline-block; } }

@media (min-width: 48em) {
  .o-formToggle--fullWidthAboveMid {
    width: 100%; } }

.o-formToggle--withBg {
  background: #fff; }

.o-formToggle--mediumSized {
  padding: 8px 12px; }

@media (min-width: 48em) {
  .o-formToggle--mediumAboveMid {
    padding: 8px 12px; } }

.o-formToggle--largeSized {
  height: 48px;
  width: calc(50% - 4px); }
  .o-formToggle--largeSized:nth-child(2n) {
    margin-left: 8px; }

@media (min-width: 48em) {
  .o-formToggle--largeAboveMid {
    float: left;
    margin: 0 0 8px;
    width: calc(50% - 4px); }
    .o-formToggle--largeAboveMid:nth-child(2n) {
      margin-left: 8px; } }

.o-formToggle-text {
  width: 100%;
  display: block;
  transition: padding 100ms ease-in-out; }
  @media (min-width: 48em) {
    .o-formToggle-text {
      transition-duration: 200ms; } }
  .o-formToggle-text:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block; }
  .o-formToggle-input:checked ~ .o-formToggle-text {
    font-weight: 500;
    color: #04822c;
    padding-left: 20px;
    font-weight: 500; }
  @media (min-width: 48em) {
    .o-formToggle-input:not(:disabled):hover ~ .o-formToggle-text,
    .o-formToggle-input:not(:disabled):focus ~ .o-formToggle-text {
      padding-left: 20px;
      font-weight: 500; } }
  @media (min-width: 48em) {
    .o-formToggle:not(.o-formToggle--disabled):hover .o-formToggle-text {
      cursor: pointer; }
      .o-formToggle:not(.o-formToggle--disabled):hover .o-formToggle-text:after {
        content: '';
        top: -1px;
        left: -1px;
        display: block;
        position: absolute; }
      .o-formToggle:not(.o-formToggle--disabled):hover .o-formToggle-text:after {
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        border-radius: 8px;
        border: 1px solid #cacaca; } }
  @media (min-width: 48em) {
    .o-formToggle:not(.o-formToggle--disabled):focus .o-formToggle-text {
      cursor: pointer; }
      .o-formToggle:not(.o-formToggle--disabled):focus .o-formToggle-text:after {
        content: '';
        top: -1px;
        left: -1px;
        display: block;
        position: absolute; }
      .o-formToggle:not(.o-formToggle--disabled):focus .o-formToggle-text:after {
        left: 1px;
        top: 1px;
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        border-radius: 8px;
        border: 1px solid #cacaca; } }
  .o-formToggle-input:not([disabled]):hover ~ .o-formToggle-text,
  .o-formToggle-input:not(:disabled):hover ~ .o-formToggle-text {
    cursor: pointer; }
    .o-formToggle-input:not([disabled]):hover ~ .o-formToggle-text:after,
    .o-formToggle-input:not(:disabled):hover ~ .o-formToggle-text:after {
      content: '';
      top: -1px;
      left: -1px;
      display: block;
      position: absolute; }
    .o-formToggle-input:not([disabled]):hover ~ .o-formToggle-text:after,
    .o-formToggle-input:not(:disabled):hover ~ .o-formToggle-text:after {
      width: calc(100% + 2px);
      height: calc(100% + 2px);
      border-radius: 8px;
      border: 1px solid #cacaca; }
  .o-formToggle-input:not([disabled]):focus ~ .o-formToggle-text,
  .o-formToggle-input:not(:disabled):focus ~ .o-formToggle-text {
    cursor: pointer; }
    .o-formToggle-input:not([disabled]):focus ~ .o-formToggle-text:after,
    .o-formToggle-input:not(:disabled):focus ~ .o-formToggle-text:after {
      content: '';
      top: -1px;
      left: -1px;
      display: block;
      position: absolute; }
    .o-formToggle-input:not([disabled]):focus ~ .o-formToggle-text:after,
    .o-formToggle-input:not(:disabled):focus ~ .o-formToggle-text:after {
      left: 1px;
      top: 1px;
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      border-radius: 8px;
      border: 1px solid #cacaca; }
  .o-formToggle-input:not([disabled]):checked ~ .o-formToggle-text,
  .o-formToggle-input:not(:disabled):checked ~ .o-formToggle-text {
    cursor: pointer; }
    .o-formToggle-input:not([disabled]):checked ~ .o-formToggle-text:after,
    .o-formToggle-input:not(:disabled):checked ~ .o-formToggle-text:after {
      content: '';
      top: -1px;
      left: -1px;
      display: block;
      position: absolute; }
    .o-formToggle-input:not([disabled]):checked ~ .o-formToggle-text:after,
    .o-formToggle-input:not(:disabled):checked ~ .o-formToggle-text:after {
      width: calc(200% + 4px);
      height: calc(200% + 4px);
      border-radius: 16px;
      border: 1px solid #04822c;
      transform: scale(0.5) translate(-50%, -50%); }
  .o-formToggle--largeSized .o-formToggle-text {
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center; }
  @media (min-width: 48em) {
    .o-formToggle--largeAboveMid .o-formToggle-text {
      height: 40px;
      display: flex;
      flex-direction: column;
      justify-content: center; } }

.o-formToggle-icon svg {
  top: 50%;
  opacity: 0;
  width: 16px;
  height: 10px;
  position: absolute;
  margin-top: -4px;
  left: 8px;
  display: inline-block;
  transform: rotate(-45deg) scale(0.5);
  transition: transform 200ms ease, opacity 250ms ease;
  fill: #04822c; }
  @media (min-width: 48em) {
    .o-formToggle-input:not([disabled]):hover ~ .o-formToggle-icon svg,
    .o-formToggle-input:not([checked]):hover ~ .o-formToggle-icon svg,
    .o-formToggle-input:not([disabled]):focus ~ .o-formToggle-icon svg,
    .o-formToggle-input:not([checked]):focus ~ .o-formToggle-icon svg {
      fill: #cacaca; } }
  .o-formToggle-input:checked ~ .o-formToggle-icon svg {
    opacity: 1;
    transform: rotate(0) scale(1); }
    .o-formToggle-input:checked ~ .o-formToggle-icon svg:hover, .o-formToggle-input:checked ~ .o-formToggle-icon svg:focus {
      fill: #04822c; }
  @media (min-width: 48em) {
    .o-formToggle-input:not(:disabled):focus ~ .o-formToggle-icon svg,
    .o-formToggle-input:not(:disabled):hover ~ .o-formToggle-icon svg {
      opacity: 1;
      transform: rotate(0) scale(1); } }

.o-formToggle--hasCount {
  display: flex;
  vertical-align: middle; }

.o-formToggle-count {
  pointer-events: none;
  padding-left: 8px; }
  @media (min-width: 48.01em) {
    .o-formToggle-count {
      top: 50%;
      padding-left: 0;
      right: 8px;
      position: absolute;
      transform: translateY(-50%); } }

.o-formToggle--disabled {
  background: none;
  color: #cacaca; }

.c-listing {
  border-radius: 4px; }
  @media (max-width: 47.99em) {
    .c-listing {
      border: 0;
      padding: 0;
      border-radius: 0; } }
  .c-listing.has-noItems {
    display: none; }

.c-listing--subsequent,
.c-listing--inactive {
  margin-top: 24px; }

.c-listing--subsequent {
  overflow: hidden;
  padding: 0 16px 16px;
  background: #eaeaea; }

.c-listing-loader {
  opacity: 1;
  transition: opacity 200ms ease, transform 200ms ease; }
  @media (max-width: 47.99em) {
    .c-listing-loader.is-loading {
      opacity: 0;
      transform: translateY(20px); } }

.c-listing-item {
  margin-bottom: 16px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  padding-bottom: 8px; }
  @media (min-width: 48.01em) {
    .c-listing-item {
      min-height: 96px;
      padding: 4px; } }
  .c-listing-item.is-active {
    display: block; }
  .c-listing-item.is-inactive {
    display: none; }
  .c-listing--inactive .c-listing-item {
    opacity: 0.6; }

.c-listing-item--withHeader {
  overflow: hidden;
  padding-top: calc(37.69% + 4px); }
  @media (min-width: 48.01em) {
    .c-listing-item--withHeader {
      padding-top: 8px; } }

.c-listing-item-header {
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  position: absolute;
  padding-top: 37.69%; }
  @media (min-width: 48.01em) {
    .c-listing-item-header {
      width: 87px;
      height: 87px;
      padding-top: 0;
      top: 4px;
      left: 4px;
      border-radius: 4px; } }
  @media (min-width: 64.0625em) {
    .c-listing-item-header {
      width: 17.5%; } }
  .c-listing-item-header:not(.c-listing-item-header--noImage) {
    border-radius: 2px;
    background: linear-gradient(-45deg, #f5f5f5, #f5f5f5, #eaeaea, #f5f5f5, #f5f5f5);
    background-size: 2000px 600%;
    animation: skeletonGradient 2s ease infinite;
    animation-fill-mode: forwards; }

.c-listing-item-header-img {
  top: 50%;
  left: 50%;
  width: 100%;
  height: auto;
  position: absolute;
  transform: translate(-50%, -50%); }
  @media (min-width: 48.01em) {
    .c-listing-item-header-img {
      width: auto;
      height: 100%;
      max-width: none !important; } }

.c-listing-item-header-frame {
  left: 0;
  bottom: 0;
  position: absolute; }
  .c-listing-item-header-frame svg {
    height: 45px;
    vertical-align: bottom; }
  @media (min-width: 48.01em) {
    .c-listing-item-header-frame {
      display: none; } }

.c-listing-item-link {
  display: block;
  color: #535353;
  text-decoration: none; }
  .c-listing-item-link:hover, .c-listing-item-link:active, .c-listing-item-link:focus {
    color: currentColor;
    text-decoration: none; }
  .c-listing-item-link:hover {
    text-decoration: none; }
    .c-listing-item-link:hover .c-listing-item-title {
      text-decoration: underline; }
  @media (min-width: 48.01em) {
    .c-listing-item-link {
      display: flex;
      min-height: 80px;
      flex-flow: row wrap; } }

.c-listing-item-img {
  width: 55px;
  height: 55px;
  top: 16px;
  left: 16px;
  position: absolute;
  border: 1px solid #eaeaea;
  border-radius: 2px; }
  @media (min-width: 64.0625em) {
    .c-listing-item-img {
      width: 65px;
      height: 65px; } }
  .c-listing-item--withHeader .c-listing-item-img {
    top: auto;
    transform: translateY(-4px); }
    @media (min-width: 48.01em) {
      .c-listing-item--withHeader .c-listing-item-img {
        top: 20px;
        left: 60px;
        transform: none; } }
    @media (min-width: 64.0625em) {
      .c-listing-item--withHeader .c-listing-item-img {
        top: 16px;
        left: calc(17.5% - 33px); } }

.c-listing-item-imgItem {
  width: 100%;
  height: auto;
  border-radius: 2px; }

.c-listing-item-info {
  position: relative;
  color: #535353;
  padding-left: 70px;
  min-height: 60px; }
  @media (min-width: 48.01em) {
    .c-listing-item-info {
      width: 55%;
      padding-top: 8px; } }
  @media (min-width: 64.0625em) {
    .c-listing-item-info {
      padding-left: 92px; } }
  .c-listing-item--withHeader .c-listing-item-info {
    top: -4px; }
  @media (min-width: 48.01em) {
    .c-listing-item--withHeader .c-listing-item-info {
      padding-left: 126px; } }
  @media (min-width: 64.0625em) {
    .c-listing-item--withHeader .c-listing-item-info {
      padding-left: calc(17.5% + 46px); } }

.c-listing-item-title {
  word-break: break-word;
  padding-right: 8px;
  font-size: 16px;
  font-size: 1.14286rem; }
  @media (min-width: 48.01em) {
    .c-listing-item-title {
      margin: 4px 0;
      padding-right: 16px; } }

.c-listing-item-new {
  top: -4px;
  color: #04822c;
  position: relative;
  margin-right: 4px; }

.c-listing-item-details {
  width: 100%;
  position: relative; }
  @media (min-width: 48.01em) {
    .c-listing-item-details {
      width: 45%;
      margin-top: auto;
      padding: 8px 8px 0 16px; } }
  @media (min-width: 64.0625em) {
    .c-listing-item-details {
      width: 45%; } }

.c-listing-item-detailsRow {
  display: flex;
  align-items: center;
  margin-bottom: 4px; }

.c-listing-item-detailsRow-icon {
  width: 20px;
  height: 20px;
  fill: #535353; }

.c-listing-item-detailsRow-text {
  white-space: initial;
  margin-left: 8px; }

@media (min-width: 48.01em) {
  .c-listing-item-badge span {
    display: block;
    font-size: 0; } }

@media (max-width: 64.0525em) {
  .c-listing-item-badge span {
    display: block;
    font-size: 0; } }

@media (max-width: 48em) {
  .c-listing-item-badge span {
    display: inline-block;
    font-size: 1em; } }

@media (min-width: 64.0625em) {
  .c-listing-item-badge span {
    display: inline-block;
    font-size: 1em; } }

.c-listing-item-promo svg,
.c-listing-item-alert svg {
  fill: #cd390c; }

.c-listing-item-promo-text {
  color: #cd390c; }

.c-listing-item-promo-text--earned {
  color: #036823; }

.c-listing-item-rating {
  padding: 2px 0 3px; }

.c-listing-item-ratingText {
  top: -4px;
  position: relative;
  font-size: 12px;
  font-size: 0.85714rem;
  line-height: 1.33; }

.c-listing-item-text {
  margin-top: 0; }

.c-listing-item-meta {
  text-align: left; }
  @media (min-width: 48.01em) {
    .c-listing-item-meta {
      padding-left: 27px; } }
  @media (min-width: 64.0625em) {
    .c-listing-item-meta {
      padding-left: 0;
      text-align: right; } }

.c-listing-item-label {
  padding: 1px 4px;
  margin-bottom: 8px;
  font-size: 12px;
  font-size: 0.85714rem; }
  @media (min-width: 48.01em) {
    .c-listing-item-label {
      font-size: 14px;
      font-size: 1rem; } }

.c-listing-item-premier {
  display: block;
  text-align: center; }
  @media (min-width: 48em) {
    .c-listing-item-premier {
      top: 0;
      right: 8px;
      position: absolute; } }
  .c-listing--inactive .c-listing-item-premier {
    display: none; }

.c-listing-item-premierImage svg {
  height: 32px; }

.c-menu {
  font-size: 16px;
  font-size: 1.14286rem;
  line-height: 1.5; }

.c-menu--condensed .c-menu-link {
  padding-bottom: 4px;
  padding-top: 4px; }

.c-menu--horizontal {
  display: flex; }
  .c-menu--horizontal .c-menu-item {
    border-left: none;
    border-bottom: solid 1px #eaeaea; }
  .c-menu--horizontal .c-menu-link {
    padding: 8px; }
  .c-menu--horizontal .c-menu-item:active,
  .c-menu--horizontal .c-menu-item:focus,
  .c-menu--horizontal .c-menu-item:hover,
  .c-menu--horizontal .c-menu-item--active {
    border-left: none;
    border-bottom: solid 2px #535353;
    font-weight: 300;
    margin-left: 0; }

@media (max-width: 47.99em) {
  .c-menu--expandable {
    background-color: #fff;
    border-bottom: solid 1px #eaeaea;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.12);
    height: auto; }
    .c-menu--expandable .c-menu-link {
      display: none;
      font-family: "Ubuntu";
      font-size: 14px;
      font-size: 1rem;
      line-height: 1.29;
      text-align: center; }
      .c-menu--expandable .c-menu-link, .c-menu--expandable .c-menu-link:active, .c-menu--expandable .c-menu-link:focus, .c-menu--expandable .c-menu-link:hover {
        border: 0;
        font-weight: 300; }
    .c-menu--expandable .c-menu-link--active {
      display: block;
      font-size: 16px;
      font-size: 1.14286rem;
      line-height: 1.5;
      text-decoration: none;
      width: 100vw; } }

@media (max-width: 47.99em) {
  .c-menu--expandable--expanded {
    max-height: 70vh;
    overflow: scroll; }
    .c-menu--expandable--expanded:after {
      content: ''; }
    .c-menu--expandable--expanded .c-menu-link {
      display: block; }
    .c-menu--expandable--expanded .c-menu-link--active {
      text-decoration: underline; } }

.c-menu-toggleBtn {
  background-color: transparent;
  border: 0;
  padding: 16px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 100; }
  .c-menu-toggleBtn:hover {
    cursor: pointer; }

.c-menu-toggleBtn--fullWidth {
  width: 100%; }

.c-menu-toggleIcon {
  float: right; }

@media (min-width: 48em) {
  .c-menu--spacingTop--aboveMid {
    margin-top: 170px; } }

@media (min-width: 48em) {
  .c-menu-item.has-icon {
    display: flex;
    align-items: center; }
    .c-menu-item.has-icon .c-menu-link {
      padding-right: 4px; } }

.c-menu-link {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-left: solid 1px #eaeaea;
  display: block;
  padding: 8px 16px;
  text-decoration: none; }
  .c-menu-link, .c-menu-link:hover, .c-menu-link:active, .c-menu-link:focus {
    color: #535353; }

.c-menu-link:active,
.c-menu-link:focus,
.c-menu-link:hover,
.c-menu-link--active {
  border-left: solid 2px #535353;
  font-weight: 500;
  margin-left: -1px; }

.c-menu-icon {
  flex-shrink: 0; }

.c-pageBanner {
  background-color: #fff;
  max-height: 300px;
  min-height: 100px;
  overflow: hidden;
  position: relative; }
  @media (min-width: 64.0625em) {
    .c-pageBanner {
      max-height: 400px; } }

.c-pageBanner--tall {
  max-height: 220px; }
  @media (min-width: 25.875em) {
    .c-pageBanner--tall {
      max-height: 450px; } }
  @media (min-width: 64.0625em) {
    .c-pageBanner--tall {
      max-height: 600px; } }
  .c-pageBanner--tall.c-pageBanner--withRays--coloured .c-pageBanner-img {
    margin-bottom: 60px; }
    @media (min-width: 48em) {
      .c-pageBanner--tall.c-pageBanner--withRays--coloured .c-pageBanner-img {
        margin-bottom: 164px; } }
    @media (min-width: 64.0625em) {
      .c-pageBanner--tall.c-pageBanner--withRays--coloured .c-pageBanner-img {
        margin-bottom: 148px; } }

.c-pageBanner--narrow {
  max-height: 300px; }
  @media (min-width: 80em) {
    .c-pageBanner--narrow {
      max-height: 370px; } }
  @media (min-width: 48em) {
    .c-pageBanner--narrow .c-pageBanner-img {
      margin-top: -8%; } }

@supports (position: absolute) {
  .c-pageBanner--withRays--white:after {
    background: url("/assets/img/decoration/rays--white--narrow.svg") no-repeat bottom left;
    content: '';
    height: 100%;
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    z-index: 1; }
    @media (min-width: 48em) {
      .c-pageBanner--withRays--white:after {
        background: url("/assets/img/decoration/rays--white--wide.svg") no-repeat bottom left; } } }

.c-pageBanner--withRays--coloured:after {
  background: url("/assets/img/decoration/rays--coloured--narrow.svg") no-repeat bottom left;
  content: '';
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  width: 100.1%;
  z-index: 1;
  bottom: -60px; }
  @media (min-width: 48em) {
    .c-pageBanner--withRays--coloured:after {
      bottom: -164px; } }
  @media (min-width: 64.0625em) {
    .c-pageBanner--withRays--coloured:after {
      background: url("/assets/img/decoration/rays--coloured--wide.svg") no-repeat bottom left;
      bottom: -148px; } }
  @media (min-width: 80em) {
    .c-pageBanner--withRays--coloured:after {
      bottom: -230px; } }
  @supports (position: absolute) {
    .c-pageBanner--withRays--coloured:after {
      bottom: 0; }
    @media (min-width: 48em) and (max-width: 64.0525em) {
      .c-pageBanner--withRays--coloured:after {
        background: url("/assets/img/decoration/rays--coloured--mid.svg") no-repeat bottom left; } } }

@media (min-width: 48em) {
  .c-pageBanner--negativeBottom--aboveMid {
    margin-bottom: -100px; } }

@media (min-width: 64.0625em) {
  .c-pageBanner--negativeBottom--aboveMid {
    margin-bottom: -132px; } }

.c-pageBanner-img {
  vertical-align: bottom;
  width: 100%; }

.c-rating {
  background-repeat: repeat-x;
  display: inline-block;
  height: 16px;
  width: 96px; }
  .c-rating,
  .c-rating .c-rating--fill {
    background-size: 16px 16px; }
  .c-rating.c-rating--spaced {
    width: 144px; }
    .c-rating.c-rating--spaced,
    .c-rating.c-rating--spaced .c-rating--fill {
      background-size: 24px 16px; }

.c-rating--small {
  height: 12px;
  width: 72px; }
  .c-rating--small,
  .c-rating--small .c-rating--fill {
    background-size: 12px 12px; }
  .c-rating--small.c-rating--spaced {
    width: 108px; }
    .c-rating--small.c-rating--spaced,
    .c-rating--small.c-rating--spaced .c-rating--fill {
      background-size: 18px 12px; }

.c-rating--default--scaleUp {
  height: 22px;
  width: 132px; }
  .c-rating--default--scaleUp,
  .c-rating--default--scaleUp .c-rating--fill {
    background-size: 22px 22px; }
  .c-rating--default--scaleUp.c-rating--spaced {
    width: 198px; }
    .c-rating--default--scaleUp.c-rating--spaced,
    .c-rating--default--scaleUp.c-rating--spaced .c-rating--fill {
      background-size: 33px 22px; }

.c-rating--medium {
  height: 28px;
  width: 168px; }
  .c-rating--medium,
  .c-rating--medium .c-rating--fill {
    background-size: 28px 28px; }
  .c-rating--medium.c-rating--spaced {
    width: 252px; }
    .c-rating--medium.c-rating--spaced,
    .c-rating--medium.c-rating--spaced .c-rating--fill {
      background-size: 42px 28px; }

.c-rating--large {
  height: 42px;
  width: 252px; }
  .c-rating--large,
  .c-rating--large .c-rating--fill {
    background-size: 42px 42px; }
  .c-rating--large.c-rating--spaced {
    width: 378px; }
    .c-rating--large.c-rating--spaced,
    .c-rating--large.c-rating--spaced .c-rating--fill {
      background-size: 63px 42px; }

.c-rating--fill {
  vertical-align: top;
  background-repeat: repeat-x;
  display: inline-block;
  height: 100%;
  width: 100%;
  /* stylelint-disable max-nesting-depth */
  /* stylelint-enable max-nesting-depth */
  /* stylelint-disable max-nesting-depth */
  /* stylelint-enable max-nesting-depth */
  /* stylelint-disable max-nesting-depth */
  /* stylelint-enable max-nesting-depth */
  /* stylelint-disable max-nesting-depth */
  /* stylelint-enable max-nesting-depth */
  /* stylelint-disable max-nesting-depth */
  /* stylelint-enable max-nesting-depth */
  /* stylelint-disable max-nesting-depth */
  /* stylelint-enable max-nesting-depth */
  /* stylelint-disable max-nesting-depth */
  /* stylelint-enable max-nesting-depth */
  /* stylelint-disable max-nesting-depth */
  /* stylelint-enable max-nesting-depth */
  /* stylelint-disable max-nesting-depth */
  /* stylelint-enable max-nesting-depth */
  /* stylelint-disable max-nesting-depth */
  /* stylelint-enable max-nesting-depth */
  /* stylelint-disable max-nesting-depth */
  /* stylelint-enable max-nesting-depth */
  /* stylelint-disable max-nesting-depth */
  /* stylelint-enable max-nesting-depth */
  /* stylelint-disable max-nesting-depth */
  /* stylelint-enable max-nesting-depth */
  /* stylelint-disable max-nesting-depth */
  /* stylelint-enable max-nesting-depth */
  /* stylelint-disable max-nesting-depth */
  /* stylelint-enable max-nesting-depth */
  /* stylelint-disable max-nesting-depth */
  /* stylelint-enable max-nesting-depth */
  /* stylelint-disable max-nesting-depth */
  /* stylelint-enable max-nesting-depth */
  /* stylelint-disable max-nesting-depth */
  /* stylelint-enable max-nesting-depth */
  /* stylelint-disable max-nesting-depth */
  /* stylelint-enable max-nesting-depth */
  /* stylelint-disable max-nesting-depth */
  /* stylelint-enable max-nesting-depth */
  /* stylelint-disable max-nesting-depth */
  /* stylelint-enable max-nesting-depth */
  /* stylelint-disable max-nesting-depth */
  /* stylelint-enable max-nesting-depth */
  /* stylelint-disable max-nesting-depth */
  /* stylelint-enable max-nesting-depth */
  /* stylelint-disable max-nesting-depth */
  /* stylelint-enable max-nesting-depth */
  /* stylelint-disable max-nesting-depth */
  /* stylelint-enable max-nesting-depth */
  /* stylelint-disable max-nesting-depth */
  /* stylelint-enable max-nesting-depth */
  /* stylelint-disable max-nesting-depth */
  /* stylelint-enable max-nesting-depth */
  /* stylelint-disable max-nesting-depth */
  /* stylelint-enable max-nesting-depth */
  /* stylelint-disable max-nesting-depth */
  /* stylelint-enable max-nesting-depth */
  /* stylelint-disable max-nesting-depth */
  /* stylelint-enable max-nesting-depth */
  /* stylelint-disable max-nesting-depth */
  /* stylelint-enable max-nesting-depth */
  /* stylelint-disable max-nesting-depth */
  /* stylelint-enable max-nesting-depth */
  /* stylelint-disable max-nesting-depth */
  /* stylelint-enable max-nesting-depth */
  /* stylelint-disable max-nesting-depth */
  /* stylelint-enable max-nesting-depth */
  /* stylelint-disable max-nesting-depth */
  /* stylelint-enable max-nesting-depth */
  /* stylelint-disable max-nesting-depth */
  /* stylelint-enable max-nesting-depth */
  /* stylelint-disable max-nesting-depth */
  /* stylelint-enable max-nesting-depth */
  /* stylelint-disable max-nesting-depth */
  /* stylelint-enable max-nesting-depth */
  /* stylelint-disable max-nesting-depth */
  /* stylelint-enable max-nesting-depth */
  /* stylelint-disable max-nesting-depth */
  /* stylelint-enable max-nesting-depth */
  /* stylelint-disable max-nesting-depth */
  /* stylelint-enable max-nesting-depth */
  /* stylelint-disable max-nesting-depth */
  /* stylelint-enable max-nesting-depth */
  /* stylelint-disable max-nesting-depth */
  /* stylelint-enable max-nesting-depth */
  /* stylelint-disable max-nesting-depth */
  /* stylelint-enable max-nesting-depth */
  /* stylelint-disable max-nesting-depth */
  /* stylelint-enable max-nesting-depth */
  /* stylelint-disable max-nesting-depth */
  /* stylelint-enable max-nesting-depth */
  /* stylelint-disable max-nesting-depth */
  /* stylelint-enable max-nesting-depth */
  /* stylelint-disable max-nesting-depth */
  /* stylelint-enable max-nesting-depth */
  /* stylelint-disable max-nesting-depth */
  /* stylelint-enable max-nesting-depth */
  /* stylelint-disable max-nesting-depth */
  /* stylelint-enable max-nesting-depth */
  /* stylelint-disable max-nesting-depth */
  /* stylelint-enable max-nesting-depth */
  /* stylelint-disable max-nesting-depth */
  /* stylelint-enable max-nesting-depth */
  /* stylelint-disable max-nesting-depth */
  /* stylelint-enable max-nesting-depth */
  /* stylelint-disable max-nesting-depth */
  /* stylelint-enable max-nesting-depth */
  /* stylelint-disable max-nesting-depth */
  /* stylelint-enable max-nesting-depth */
  /* stylelint-disable max-nesting-depth */
  /* stylelint-enable max-nesting-depth */
  /* stylelint-disable max-nesting-depth */
  /* stylelint-enable max-nesting-depth */
  /* stylelint-disable max-nesting-depth */
  /* stylelint-enable max-nesting-depth */
  /* stylelint-disable max-nesting-depth */
  /* stylelint-enable max-nesting-depth */
  /* stylelint-disable max-nesting-depth */
  /* stylelint-enable max-nesting-depth */ }
  .c-rating--fill.c-ratings--fill-00 {
    width: 0; }
  .c-rating--fill.c-ratings--fill-01 {
    width: 1.66667%; }
  .c-rating--fill.c-ratings--fill-02 {
    width: 3.33333%; }
  .c-rating--fill.c-ratings--fill-03 {
    width: 5%; }
  .c-rating--fill.c-ratings--fill-04 {
    width: 6.66667%; }
  .c-rating--fill.c-ratings--fill-05 {
    width: 8.33333%; }
  .c-rating--fill.c-ratings--fill-06 {
    width: 10%; }
  .c-rating--fill.c-ratings--fill-07 {
    width: 11.66667%; }
  .c-rating--fill.c-ratings--fill-08 {
    width: 13.33333%; }
  .c-rating--fill.c-ratings--fill-09 {
    width: 15%; }
  .c-rating--fill.c-ratings--fill-10 {
    width: 16.66667%; }
  .c-rating--fill.c-ratings--fill-11 {
    width: 18.33333%; }
  .c-rating--fill.c-ratings--fill-12 {
    width: 20%; }
  .c-rating--fill.c-ratings--fill-13 {
    width: 21.66667%; }
  .c-rating--fill.c-ratings--fill-14 {
    width: 23.33333%; }
  .c-rating--fill.c-ratings--fill-15 {
    width: 25%; }
  .c-rating--fill.c-ratings--fill-16 {
    width: 26.66667%; }
  .c-rating--fill.c-ratings--fill-17 {
    width: 28.33333%; }
  .c-rating--fill.c-ratings--fill-18 {
    width: 30%; }
  .c-rating--fill.c-ratings--fill-19 {
    width: 31.66667%; }
  .c-rating--fill.c-ratings--fill-20 {
    width: 33.33333%; }
  .c-rating--fill.c-ratings--fill-21 {
    width: 35%; }
  .c-rating--fill.c-ratings--fill-22 {
    width: 36.66667%; }
  .c-rating--fill.c-ratings--fill-23 {
    width: 38.33333%; }
  .c-rating--fill.c-ratings--fill-24 {
    width: 40%; }
  .c-rating--fill.c-ratings--fill-25 {
    width: 41.66667%; }
  .c-rating--fill.c-ratings--fill-26 {
    width: 43.33333%; }
  .c-rating--fill.c-ratings--fill-27 {
    width: 45%; }
  .c-rating--fill.c-ratings--fill-28 {
    width: 46.66667%; }
  .c-rating--fill.c-ratings--fill-29 {
    width: 48.33333%; }
  .c-rating--fill.c-ratings--fill-30 {
    width: 50%; }
  .c-rating--fill.c-ratings--fill-31 {
    width: 51.66667%; }
  .c-rating--fill.c-ratings--fill-32 {
    width: 53.33333%; }
  .c-rating--fill.c-ratings--fill-33 {
    width: 55%; }
  .c-rating--fill.c-ratings--fill-34 {
    width: 56.66667%; }
  .c-rating--fill.c-ratings--fill-35 {
    width: 58.33333%; }
  .c-rating--fill.c-ratings--fill-36 {
    width: 60%; }
  .c-rating--fill.c-ratings--fill-37 {
    width: 61.66667%; }
  .c-rating--fill.c-ratings--fill-38 {
    width: 63.33333%; }
  .c-rating--fill.c-ratings--fill-39 {
    width: 65%; }
  .c-rating--fill.c-ratings--fill-40 {
    width: 66.66667%; }
  .c-rating--fill.c-ratings--fill-41 {
    width: 68.33333%; }
  .c-rating--fill.c-ratings--fill-42 {
    width: 70%; }
  .c-rating--fill.c-ratings--fill-43 {
    width: 71.66667%; }
  .c-rating--fill.c-ratings--fill-44 {
    width: 73.33333%; }
  .c-rating--fill.c-ratings--fill-45 {
    width: 75%; }
  .c-rating--fill.c-ratings--fill-46 {
    width: 76.66667%; }
  .c-rating--fill.c-ratings--fill-47 {
    width: 78.33333%; }
  .c-rating--fill.c-ratings--fill-48 {
    width: 80%; }
  .c-rating--fill.c-ratings--fill-49 {
    width: 81.66667%; }
  .c-rating--fill.c-ratings--fill-50 {
    width: 83.33333%; }
  .c-rating--fill.c-ratings--fill-51 {
    width: 85%; }
  .c-rating--fill.c-ratings--fill-52 {
    width: 86.66667%; }
  .c-rating--fill.c-ratings--fill-53 {
    width: 88.33333%; }
  .c-rating--fill.c-ratings--fill-54 {
    width: 90%; }
  .c-rating--fill.c-ratings--fill-55 {
    width: 91.66667%; }
  .c-rating--fill.c-ratings--fill-56 {
    width: 93.33333%; }
  .c-rating--fill.c-ratings--fill-57 {
    width: 95%; }
  .c-rating--fill.c-ratings--fill-58 {
    width: 96.66667%; }
  .c-rating--fill.c-ratings--fill-59 {
    width: 98.33333%; }
  .c-rating--fill.c-ratings--fill-60 {
    width: 100%; }

/*
    Selectable stars rating component
    */
.c-ratingSelectable {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end; }

.c-ratingSelectable-input {
  display: none; }

.c-ratingSelectable-input:disabled + label,
.c-ratingSelectable-input[disabled] + label {
  cursor: default;
  pointer-events: none; }

.c-icon--star {
  display: block;
  cursor: pointer; }

@keyframes toastSlideUp {
  0% {
    transform: translateY(0); }
  100% {
    transform: translateY(-90px); } }

@keyframes toastSlideUp--aboveNarrow {
  0% {
    transform: translateY(0); }
  100% {
    transform: translateY(-120px); } }

@keyframes toastSlideUp--aboveMid {
  0% {
    transform: translateY(0); }
  100% {
    transform: translateY(-90px); } }

.c-toast {
  animation: toastSlideUp 0.5s;
  background-color: #333;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  box-shadow: 0 -3px 4px 0 rgba(0, 0, 0, 0.12);
  color: #fff;
  height: 95px;
  opacity: 0.9;
  padding: 8px;
  position: absolute;
  text-align: center;
  transform: translateY(-90px);
  width: calc(100% - 16px); }
  @media (min-width: 25.875em) {
    .c-toast {
      animation: toastSlideUp--aboveNarrow 0.5s;
      height: 125px;
      transform: translateY(-120px); } }
  @media (min-width: 48em) {
    .c-toast {
      animation: toastSlideUp--aboveMid 0.5s;
      height: 95px;
      transform: translateY(-90px); } }
  .c-toast p {
    margin: 0; }

.c-toast--alert {
  background-color: #b40e27; }

.c-cuisinesWidget {
  min-height: 96px;
  max-width: 392px;
  display: block;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  background-color: #f50028;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 16px; }
  .c-cuisinesWidget:hover, .c-cuisinesWidget:focus {
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16), -2px -2px 4px 0 rgba(0, 0, 0, 0.04); }
    .c-cuisinesWidget:hover .c-cuisinesWidget-name, .c-cuisinesWidget:focus .c-cuisinesWidget-name {
      text-decoration: underline; }
  @media (min-width: 570px) {
    .c-cuisinesWidget:nth-child(odd) {
      margin-right: 16px; }
    .c-cuisinesWidget:nth-child(even), .c-cuisinesWidget:last-child:first-child {
      margin-right: 0; } }
  @media (min-width: 48em) {
    .c-cuisinesWidget {
      min-height: 133px; } }
  @media (min-width: 1240px) {
    .c-cuisinesWidget {
      min-height: 145px; }
      .c-cuisinesWidget:nth-child(1n), .c-cuisinesWidget:nth-child(4) {
        margin-right: 16px; }
      .c-cuisinesWidget:nth-child(3n), .c-cuisinesWidget:last-child {
        margin-right: 0; } }

.c-cuisinesWidget-name {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 16px;
  color: #fff;
  text-align: center;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0)); }
  @media (min-width: 1240px) {
    .c-cuisinesWidget-name {
      font-size: 24px;
      font-size: 1.71429rem;
      padding: 24px; } }

.c-cuisinesWidget-img {
  vertical-align: bottom;
  width: 100%; }

/* autoprefixer grid: autoplace */
.c-cuisinesGrid {
  max-width: 360px;
  margin: auto;
  display: grid;
  margin-bottom: -16px; }
  @media (min-width: 570px) {
    .c-cuisinesGrid {
      max-width: 800px; } }
  @media (min-width: 1240px) {
    .c-cuisinesGrid {
      max-width: none; } }

.c-cuisinesGrid--gridOfThree {
  grid-template-columns: auto;
  grid-template-rows: auto auto auto auto auto auto;
  justify-content: center; }
  @media (min-width: 570px) {
    .c-cuisinesGrid--gridOfThree {
      grid-template-columns: auto auto;
      grid-template-rows: auto auto auto; } }
  @media (min-width: 1240px) {
    .c-cuisinesGrid--gridOfThree {
      grid-template-columns: auto auto auto;
      grid-template-rows: auto auto; } }

/* autoprefixer grid: autoplace */
.c-appsBanner {
  text-align: left;
  position: relative;
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto; }
  @media (min-width: 600px) {
    .c-appsBanner {
      text-align: center;
      justify-items: center; } }
  .c-appsBanner .c-appsBanner-heading {
    grid-column: 1 / 3;
    grid-row: 1;
    text-align: center; }
    @media (min-width: 600px) {
      .c-appsBanner .c-appsBanner-heading {
        grid-column: 2;
        align-self: end; } }
    @media (min-width: 48em) {
      .c-appsBanner .c-appsBanner-heading {
        max-width: 570px;
        font-size: 32px;
        font-size: 2.28571rem; } }
  .c-appsBanner .c-appsBanner-image {
    overflow: hidden;
    max-width: 120px;
    margin-top: 16px;
    grid-column: 1;
    grid-row: 2; }
    @media (min-width: 600px) {
      .c-appsBanner .c-appsBanner-image {
        max-width: 324px;
        margin-top: 0;
        grid-column: 1;
        grid-row: 1 / 3; } }
    @media (min-width: 64.0625em) {
      .c-appsBanner .c-appsBanner-image {
        max-width: 492px; } }
    .c-appsBanner .c-appsBanner-image img {
      width: 100%; }
  .c-appsBanner .c-appsBanner-content {
    margin-left: 16px;
    grid-column: 2;
    grid-row: 2; }
    @media (min-width: 600px) {
      .c-appsBanner .c-appsBanner-content {
        align-self: start; } }
    @media (min-width: 48em) {
      .c-appsBanner .c-appsBanner-content {
        text-align: center; } }
    .c-appsBanner .c-appsBanner-content p {
      margin-top: 16px; }
      @media (min-width: 48em) {
        .c-appsBanner .c-appsBanner-content p {
          margin-top: 8px; } }

.c-appsBanner-buttons {
  margin-top: 24px; }

.c-appsBanner-appBtn {
  display: block; }
  @media (min-width: 48em) {
    .c-appsBanner-appBtn {
      display: inline-block; } }

.c-cookieWarning {
  background-color: #333;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 9000; }
  .c-cookieWarning p {
    font-size: 12px;
    font-size: 0.85714rem;
    color: #fff;
    text-align: center;
    margin: 0 auto; }

.c-cookieWarning-inner {
  margin: 0 auto;
  padding: 8px;
  padding-right: 24px;
  overflow: hidden; }

.c-cookieWarning-btn {
  display: block;
  position: absolute;
  top: 8px;
  right: 8px;
  width: 10px;
  height: 10px;
  background: url("//dy3erx8o0a6nh.cloudfront.net/images/icon-close-banner.png") no-repeat center center;
  background-size: 10px 10px;
  border: none; }
  .c-cookieWarning-btn:hover, .c-cookieWarning-btn:active, .c-cookieWarning-btn:focus {
    background-color: #757575;
    cursor: pointer; }

.c-cookieWarning-link {
  color: #fff; }
  .c-cookieWarning-link:hover, .c-cookieWarning-link:active, .c-cookieWarning-link:focus {
    color: #fff; }

@media (max-width: 47.99em) {
  .c-overflowCarousel {
    margin: 0 -16px; } }

@media (max-width: 47.99em) {
  .c-overflowCarousel-inner {
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    width: 100%; }
    .c-overflowCarousel-inner.is-active {
      overflow: visible; } }

@media (max-width: 48em) {
  .c-overflowCarousel--fromMid .c-overflowCarousel-inner {
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    width: 100%; }
    .c-overflowCarousel--fromMid .c-overflowCarousel-inner.is-active {
      overflow: visible; } }

@media (max-width: 47.99em) {
  .c-overflowCarousel-content {
    display: inline-flex;
    white-space: nowrap;
    padding-left: 16px;
    padding-right: 16px; } }

@media (max-width: 48em) {
  .c-overflowCarousel--fromMid .c-overflowCarousel-content {
    display: inline-flex;
    white-space: nowrap;
    padding-left: 16px;
    padding-right: 16px; } }

.c-overflowCarousel--withBlockElements {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding: 8px 16px;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none; }
  .c-overflowCarousel--withBlockElements:after {
    content: '';
    flex: 0 0 16px; }
  @media (min-width: 48.01em) {
    .c-overflowCarousel--withBlockElements {
      padding: 16px 32px 8px; } }
  .c-overflowCarousel--withBlockElements .c-overflowCarousel-item {
    flex: 1 0 auto;
    margin-right: 8px; }
    @media (min-width: 48em) {
      .c-overflowCarousel--withBlockElements .c-overflowCarousel-item {
        margin-right: 16px; } }
    .c-overflowCarousel--withBlockElements .c-overflowCarousel-item:first-child {
      margin-left: auto; }
    .c-overflowCarousel--withBlockElements .c-overflowCarousel-item:last-child {
      margin-right: auto; }

.c-orderCard {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  overflow: hidden;
  text-decoration: none;
  width: 280px;
  max-width: 280px;
  min-height: 259px; }
  @media (min-width: 48em) {
    .c-orderCard {
      width: 392px;
      max-width: 392px;
      min-height: 302px; } }
  .c-orderCard:hover, .c-orderCard:focus {
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16), -2px -2px 4px 0 rgba(0, 0, 0, 0.04), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
    text-decoration: none;
    color: #757575; }

.c-orderCard--inactive {
  min-height: 220px;
  margin: auto; }
  @media (min-width: 48em) {
    .c-orderCard--inactive {
      min-height: 254px; } }
  .c-orderCard--inactive:hover, .c-orderCard--inactive:focus {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1); }
  .c-orderCard--inactive .c-orderCard-image {
    background-color: #eaeaea; }

.c-orderCard-image {
  width: 100%;
  height: 103px;
  overflow: hidden;
  position: relative; }
  @media (min-width: 48em) {
    .c-orderCard-image {
      height: 145px; } }
  .c-orderCard-image img {
    vertical-align: bottom;
    width: 100%; }
  .c-orderCard-image:after {
    content: '';
    width: 100%;
    height: 36px;
    background: url("/assets/img/decoration/rays--white--narrow.svg") no-repeat bottom left;
    position: absolute;
    bottom: -1px;
    left: 0;
    z-index: 1; }

.c-orderCard-iconContainer {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: #fff; }
  .c-orderCard-iconContainer svg {
    width: 40px;
    height: 40px;
    fill: #f74c00; }

.c-orderCard-order {
  text-align: center;
  padding: 12px;
  color: #266abd;
  font-weight: 500;
  border-top: 1px solid #f5f5f5;
  line-height: 1.5;
  font-size: 16px;
  font-size: 1.14286rem;
  margin-top: 8px; }

.c-orderCard-title {
  font-family: "Hind Vadodara", "Helvetica Neue", Helvetica, sans-serif;
  font-weight: 500;
  font-size: 16px;
  font-size: 1.14286rem;
  line-height: 1.5; }

.c-orderCard-showMore {
  font-weight: 500; }

.c-orderCard-date {
  margin-top: 0;
  margin-bottom: 8px;
  color: #757575;
  display: block; }

.c-orderCard-summary-grid {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  flex: 1 0 auto; }

.c-orderCard-summary {
  line-height: 1.44;
  width: 78%; }

.c-orderCard-orderTotal {
  margin-right: 8px;
  margin-left: 24px;
  align-self: flex-end; }

.c-orderCard-content {
  padding: 8px 16px 0;
  color: #333;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column; }

.c-orderCard-content--defaultMessage {
  padding: 0 16px;
  font-size: 16px;
  font-size: 1.14286rem; }

/**
 * Objects
 * =================================
 * Styles apply to an object if they are directly associated to a HTML element.
 * If not, the styles should be defined as a component.
 *
 * For example, it would be expected to have:
 * 	links.scss > styles relating to links (<a> tag)
 * 	tables.scss > styles relating to tables
 *
 * Object styles should be prefixed with `.o-`
 */
/**
 * Components:
 * =================================
 * Components are our resuable chunks of styling that aren’t directly associated to a base tag
 *
 * This is where the majority of our day-to-day styling will take place.  Component styles should be prefixed with `.c-`
 */
/**
 * Experiments
 * =================================
 *
 * These styles relate to experiments running on the platform.
 *
 * The filename should _always include the JIRA number so that its status can be checked
 */
/**
 * Utility classes
 * =====================================
 * These should always come last as they should 'trump' other properties
 */
/**
 * Trumps > Utilities
 * =================================
 * Non-semantic helper classes
 */
/* PrismJS 1.11.0
http://prismjs.com/download.html?themes=prism&languages=markup+css+clike+javascript+bash+scss */
/**
 * prism.js default theme for JavaScript, CSS and HTML
 * Based on dabblet (http://dabblet.com)
 * @author Lea Verou
 */
code[class*='language-'],
pre[class*='language-'] {
  color: black;
  background: none;
  text-shadow: 0 1px white;
  font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.5;
  tab-size: 4;
  hyphens: none; }

pre[class*='language-']::selection,
pre[class*='language-'] ::selection,
code[class*='language-']::selection,
code[class*='language-'] ::selection {
  text-shadow: none;
  background: #b3d4fc; }

@media print {
  code[class*='language-'],
  pre[class*='language-'] {
    text-shadow: none; } }

/* Code blocks */
pre[class*='language-'] {
  padding: 1em;
  margin: 1em 0;
  overflow: auto; }

:not(pre) > code[class*='language-'],
pre[class*='language-'] {
  background: #f5f2f0; }

/* Inline code */
:not(pre) > code[class*='language-'] {
  padding: 0.1em;
  border-radius: 0.3em;
  white-space: normal; }

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
  color: slategray; }

.token.punctuation {
  color: #999; }

.namespace {
  opacity: 0.7; }

.token.property,
.token.tag,
.token.boolean,
.token.number,
.token.constant,
.token.symbol,
.token.deleted {
  color: #905; }

.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin,
.token.inserted {
  color: #690; }

.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string {
  color: #a67f59;
  background: rgba(255, 255, 255, 0.5); }

.token.atrule,
.token.attr-value,
.token.keyword {
  color: #07a; }

.token.function {
  color: #dd4a68; }

.token.regex,
.token.important,
.token.variable {
  color: #e90; }

.token.important,
.token.bold {
  font-weight: bold; }

.token.italic {
  font-style: italic; }

.token.entity {
  cursor: help; }

/**
 * Define associated Code variables - not Prism CSS
 */
code,
pre {
  border: 1px solid #cacaca;
  padding: 10px;
  font-family: Menlo, Monaco, "Courier New", monospace;
  font-size: 14px;
  font-size: 1rem;
  color: #444;
  tab-size: 3;
  overflow: auto; }

code {
  padding: 2px 4px;
  color: #444;
  background-color: #f9f8f8;
  white-space: nowrap; }

pre {
  display: block;
  padding: 8px;
  margin: 16px 0 16px;
  color: #444;
  background-color: #f9f8f8;
  white-space: pre; }
  pre code {
    padding: 0;
    color: #444;
    background-color: inherit;
    border: 0; }

.pre--scrollable {
  max-height: 340px;
  overflow-y: scroll; }

/**
 * Settings > Variables
 * =================================
 * Variables specific to the styleguide
 */
/**
 * Base > Layout
 * =================================
 * Layout definitions
 * Specific to styleguide
 */
/**
 * Where it makes sense, prefix all styleguide classnames with .sg
 * This makes it very clear which ones are specifically styleguide related
 */
.body-docs {
  background-color: #f5f5f5;
  display: flex;
  min-height: 100vh;
  flex-direction: column; }

/**
 * Styleguide .page styles
 */
.sg-page {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden; }
  .sg-page h1:first-child,
  .sg-page h2:first-child {
    margin-top: 8px; }

.sg-page--start {
  background-color: #d50525;
  padding: 20px 0;
  padding: 6vh 0; }
  .sg-page--start * {
    color: #fff; }

.sg-headerBlock {
  padding: 24px 0; }

.sg-content {
  padding-bottom: 48px; }

.sg-footer {
  background-color: #fff;
  color: #535353;
  padding: 30px;
  margin-top: 30px; }
  .sg-footer :last-child {
    margin-bottom: 0; }
  .sg-footer a {
    color: currentColor;
    text-decoration: underline; }
  .sg-footer p {
    margin: 0; }

.sg-wip {
  position: fixed;
  z-index: 100;
  bottom: 0;
  width: 100%;
  text-align: center;
  background-color: #cd390c;
  color: #fff;
  padding: 48px;
  border: 10px solid #ff9e16;
  text-shadow: 1px 1px #000; }

.sg-darken {
  background: #333; }

.sg-stickToBottom {
  flex: 1; }

.sg--disabled {
  position: relative; }
  .sg--disabled:hover:after {
    content: 'Coming Soon';
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px 10px;
    background-color: #757575;
    color: #fff; }

.js-options td {
  max-width: 450px; }
  .js-options td p:first-child {
    margin-top: 0; }

.sectionSplitter {
  border-color: #d50525;
  margin: 40px 40px; }

td > ul,
td > ol {
  margin-top: 0;
  margin-bottom: 0; }

/**
 * Base > Type
 * =================================
 * Type definitions
 * Specific to styleguide
 */
.sg-sectionTitle > span {
  display: block; }

@media (min-width: 48em) {
  .sg-sectionTitle {
    font-size: 50px;
    font-size: 3.57143rem; } }

.sg-sectionTitle + .sg-text {
  margin-top: 16px; }

.sg-index ul ul {
  margin-top: 8px; }

.sg-index a {
  text-decoration: underline; }
  .sg-index a:hover {
    color: #14509c; }

.sg-content > p,
.sg-content > ul,
.sg-content > ol,
.sg-content > ul p,
.sg-content > ol p {
  font-size: 16px;
  font-size: 1.14286rem;
  line-height: 1.5; }

.sg-content > p {
  max-width: 50em; }
  .sg-content > p > code {
    display: inline-flex; }

.sg-content > ul,
.sg-content > ol {
  line-height: 1.2; }
  .sg-content > ul li,
  .sg-content > ol li {
    margin-bottom: 0;
    max-width: 50em; }

.sg-content > p > img,
.sg-content > ul img,
.sg-content > ol img {
  display: block;
  border: 1px solid #bbb;
  margin: 24px auto 0; }

.sg-interfaceType, .sg-typeSpecimen:after {
  font-weight: 300; }

.sg-interfaceType--subtle, .sg-typeSpecimen:after {
  color: #888; }

.sg-sectionHeading {
  color: #d50525;
  padding: 16px 0;
  border-top: 1px solid #bbb;
  border-bottom: 1px solid #bbb; }

h2.sg-sectionHeading:first-child {
  margin-top: 0;
  border-top: 0;
  padding-top: 0; }

h3.sg-sectionHeading {
  border-top: 0; }

.sg-sectionHeading--main {
  padding: 6vh 10px;
  text-align: center; }

/**
 * Typography
 */
.sg-type {
  margin-bottom: 1em; }

.sg-type-small .sg-type-item {
  font-size: 12px;
  font-size: 0.85714rem; }

.sg-type-small code:after {
  content: " :: smallpx"; }

.sg-type-base .sg-type-item {
  font-size: 14px;
  font-size: 1rem; }

.sg-type-base code:after {
  content: " :: basepx"; }

.sg-type-mid .sg-type-item {
  font-size: 20px;
  font-size: 1.42857rem; }

.sg-type-mid code:after {
  content: " :: midpx"; }

.sg-type-large .sg-type-item {
  font-size: 24px;
  font-size: 1.71429rem; }

.sg-type-large code:after {
  content: " :: largepx"; }

.sg-type-jumbo .sg-type-item {
  font-size: 32px;
  font-size: 2.28571rem; }

.sg-type-jumbo code:after {
  content: " :: jumbopx"; }

.sg-typeSpecimen {
  margin-bottom: 0;
  margin-top: 0;
  -ms-word-wrap: break-word;
  word-wrap: break-word; }
  .sg-typeSpecimen:after {
    display: block;
    font-size: 16px;
    font-size: 1.14286rem;
    margin-top: 5px;
    border-top: 1px solid #ddd;
    font-weight: normal; }

.sg-typeSpecimen--primary {
  font-size: 30px;
  font-size: 2.14286rem;
  font-family: "Ubuntu";
  font-weight: 300; }

.sg-typeSpecimen--secondary {
  font-size: 30px;
  font-size: 2.14286rem;
  font-family: "Hind Vadodara", "Helvetica Neue", Helvetica, sans-serif; }

.sg-typeSpecimen--giant {
  font-size: 120px;
  font-size: 8.57143rem;
  line-height: 1; }

/**
 * Components > Nav
 * =================================
 * Stylguide specific Navigation styles
 */
.c-header-wrap {
  flex-wrap: wrap; }

@media (min-width: 48em) and (max-width: 64.0625em) {
  .c-logo--sg,
  .c-nav--sg {
    flex-basis: 100%;
    justify-content: center; } }

.c-logo--sg > a {
  justify-content: center; }

.nav--sg {
  float: none; }

.sg-subnav {
  font-size: 14px;
  font-size: 1rem;
  line-height: 1.29; }
  @media (max-width: 48em) {
    .sg-subnav {
      flex-basis: 100%;
      justify-content: left; } }

.sg-subnav-item {
  margin-left: 8px; }
  @media (max-width: 48em) {
    .sg-subnav-item {
      margin-left: 0; } }
  .sg-subnav-item a {
    display: block;
    padding: 8px 8px 8px;
    color: #535353;
    border-bottom: 3px solid transparent;
    text-decoration: none;
    font-weight: 500; }
  .sg-subnav-item a:hover,
  .sg-subnav-item a:focus,
  .sg-subnav-item.is-active a {
    border-bottom: 3px solid #d50525;
    text-decoration: none; }

.sg-secondaryBar {
  background-color: #fff;
  padding: 8px 0 0;
  margin-bottom: 8px; }

.sg-secondaryBar-title {
  margin-top: 0;
  line-height: 1.8; }

/*
   Sidebar Nav
   ========================================================================== */
.sg-sideNav {
  font-family: "Ubuntu";
  background-color: #eaeaea; }
  .sg-sideNav a {
    background-color: #fff;
    display: block;
    border-left: 3px solid transparent;
    margin: 1px 0;
    padding: 0.4em 0.8em 0.5em;
    color: #000;
    text-decoration: none; }
  .sg-sideNav .is-active,
  .sg-sideNav a:hover {
    border-left: 3px solid #d50525; }
  .sg-sideNav a.is-incomplete {
    background-color: #bbb;
    cursor: default; }
    .sg-sideNav a.is-incomplete:hover {
      border-left: 3px solid transparent; }
    .sg-sideNav a.is-incomplete:focus {
      outline: none; }

.sg-sideNav-subnav {
  margin-top: 0;
  border-left: 15px solid #eaeaea; }

/**
 * Components > demoBlock
 * =================================
 * Component used to display demo markup on documentation pages
 */
.demo {
  position: relative;
  margin-top: 16px;
  margin-bottom: 48px; }
  .demo + .demo {
    margin-top: -40px; }

.demo-title {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  margin-top: 0;
  padding: 6px 10px;
  background-color: #d50525;
  color: #fff;
  font-size: 12px;
  font-size: 0.85714rem; }

.demo-visual {
  border: 1px solid #cacaca;
  padding: 40px 40px;
  position: relative; }

.demo-visual--noSpacing {
  padding: 0; }

.demo-visual--spacingBottom {
  padding-bottom: 160px; }
  @media (max-width: 47.99em) {
    .demo-visual--spacingBottom {
      padding-bottom: 40px; } }

.demo-visual--spacingTopAndBottom {
  padding: 40px 0; }

.demo-code {
  margin-bottom: 0; }
  .demo-code pre {
    margin: 0; }

.o-btn--codeToggle {
  width: 100%;
  border-radius: 0;
  border: 1px solid #cacaca;
  border-top: 0;
  background-color: #f5f5f5; }
  .o-btn--codeToggle.is-clicked {
    border-bottom: 0; }

/**
 * Components > Placeholder
 * =================================
 * Used to define a placeholder (greyed out) block
 * when putting together templated pages
 */
.componentPlaceholder {
  border: 3px dashed #eaeaea;
  margin: 5px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center; }

.componentPlaceholder--1xRow {
  height: 32px; }

.componentPlaceholder--2xRow {
  height: 64px; }

.componentPlaceholder--3xRow {
  height: 96px; }

/**
 * Components > swatch
 * =================================
 * Displays colour swatches
 *
 * E.g. on the colour documentation page
 */
.sg-swatch {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 24px 0; }

.sg-swatch-color {
  position: relative;
  width: 100px;
  height: 100px;
  padding: 15px;
  font-size: 13px;
  font-size: 0.92857rem;
  font-family: Menlo, Monaco, "Courier New", monospace;
  border-radius: 50%; }
  .sg-swatch-color:before {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

.sg-swatch-desc {
  flex-grow: 1; }

.sg-swatch-subText {
  display: block;
  margin-top: 8px; }

.sg-color-red .sg-swatch-color {
  background-color: #d50525;
  color: #fff;
  text-shadow: 0 0 6px #757575; }
  .sg-color-red .sg-swatch-color:before {
    content: "#d50525"; }

.sg-color-blue .sg-swatch-color {
  background-color: #266abd;
  color: #fff;
  text-shadow: 0 0 6px #757575; }
  .sg-color-blue .sg-swatch-color:before {
    content: "#266abd"; }

.sg-color-green .sg-swatch-color {
  background-color: #04822c;
  color: #fff;
  text-shadow: 0 0 6px #757575; }
  .sg-color-green .sg-swatch-color:before {
    content: "#04822c"; }

.sg-color-orange .sg-swatch-color {
  background-color: #cd390c;
  color: #fff;
  text-shadow: 0 0 6px #757575; }
  .sg-color-orange .sg-swatch-color:before {
    content: "#cd390c"; }

.sg-color-grey--dark .sg-swatch-color {
  background-color: #535353;
  color: #fff;
  text-shadow: 0 0 6px #757575; }
  .sg-color-grey--dark .sg-swatch-color:before {
    content: "#535353"; }

.sg-color-grey--darkest .sg-swatch-color {
  background-color: #333;
  color: #fff;
  text-shadow: 0 0 6px #757575; }
  .sg-color-grey--darkest .sg-swatch-color:before {
    content: "#333"; }

.sg-color-grey--mid .sg-swatch-color {
  background-color: #757575;
  color: #fff;
  text-shadow: 0 0 6px #757575; }
  .sg-color-grey--mid .sg-swatch-color:before {
    content: "#757575"; }

.sg-color-grey--offWhite .sg-swatch-color {
  background-color: #f5f5f5;
  color: #535353;
  border: #eaeaea solid 1px; }
  .sg-color-grey--offWhite .sg-swatch-color:before {
    content: "#f5f5f5"; }

.sg-color-blue--dark .sg-swatch-color {
  background-color: #14509c;
  color: #fff;
  text-shadow: 0 0 6px #757575; }
  .sg-color-blue--dark .sg-swatch-color:before {
    content: "#14509c"; }

.sg-color-blue--darkest .sg-swatch-color {
  background-color: #14427d;
  color: #fff;
  text-shadow: 0 0 6px #757575; }
  .sg-color-blue--darkest .sg-swatch-color:before {
    content: "#14427d"; }

.sg-color-white .sg-swatch-color {
  background-color: #fff;
  color: #535353;
  border: #eaeaea solid 1px; }
  .sg-color-white .sg-swatch-color:before {
    content: "#fff"; }

.sg-color-yellow--light .sg-swatch-color {
  background-color: #fff1df;
  color: #535353;
  border: #eaeaea solid 1px; }
  .sg-color-yellow--light .sg-swatch-color:before {
    content: "#fff1df"; }

.sg-color-green--offWhite .sg-swatch-color {
  background-color: #f2fae2;
  color: #535353;
  border: #eaeaea solid 1px; }
  .sg-color-green--offWhite .sg-swatch-color:before {
    content: "#f2fae2"; }

.sg-color-blue--offWhite .sg-swatch-color {
  background-color: #edf5ff;
  color: #535353;
  border: #eaeaea solid 1px; }
  .sg-color-blue--offWhite .sg-swatch-color:before {
    content: "#edf5ff"; }

.sg-color-red--offWhite .sg-swatch-color {
  background-color: #fee;
  color: #535353;
  border: #eaeaea solid 1px; }
  .sg-color-red--offWhite .sg-swatch-color:before {
    content: "#fee"; }

.sg-color-grey--lightest .sg-swatch-color {
  background-color: #eaeaea;
  color: #535353;
  border: #eaeaea solid 1px; }
  .sg-color-grey--lightest .sg-swatch-color:before {
    content: "#eaeaea"; }

.sg-color-grey--lighter .sg-swatch-color {
  background-color: #cacaca;
  color: #535353; }
  .sg-color-grey--lighter .sg-swatch-color:before {
    content: "#cacaca"; }

.sg-color-purple .sg-swatch-color {
  background-color: #3b1249;
  color: #fff;
  text-shadow: 0 0 6px #757575; }
  .sg-color-purple .sg-swatch-color:before {
    content: "#3b1249"; }

.sg-color-purple--light .sg-swatch-color {
  background-color: #ead9f0;
  color: #535353;
  border: #eaeaea solid 1px; }
  .sg-color-purple--light .sg-swatch-color:before {
    content: "#ead9f0"; }

.sg-color-grey--light .sg-swatch-color {
  background-color: #bbb;
  color: #535353; }
  .sg-color-grey--light .sg-swatch-color:before {
    content: "#bbb"; }

.sg-color-red--dark .sg-swatch-color {
  background-color: #b40e27;
  color: #fff;
  text-shadow: 0 0 6px #757575; }
  .sg-color-red--dark .sg-swatch-color:before {
    content: "#b40e27"; }

.sg-color-red--darkest .sg-swatch-color {
  background-color: #900b1f;
  color: #fff;
  text-shadow: 0 0 6px #757575; }
  .sg-color-red--darkest .sg-swatch-color:before {
    content: "#900b1f"; }

.sg-color-red--offwhite .sg-swatch-color {
  background-color: #fee;
  color: #535353;
  border: #eaeaea solid 1px; }
  .sg-color-red--offwhite .sg-swatch-color:before {
    content: "#fee"; }

.sg-color-red--brand .sg-swatch-color {
  background-color: #f50028;
  color: #fff;
  text-shadow: 0 0 6px #757575; }
  .sg-color-red--brand .sg-swatch-color:before {
    content: "#f50028"; }

.sg-color-green--dark .sg-swatch-color {
  background-color: #036823;
  color: #fff;
  text-shadow: 0 0 6px #757575; }
  .sg-color-green--dark .sg-swatch-color:before {
    content: "#036823"; }

.sg-color-green--darkest .sg-swatch-color {
  background-color: #02531c;
  color: #fff;
  text-shadow: 0 0 6px #757575; }
  .sg-color-green--darkest .sg-swatch-color:before {
    content: "#02531c"; }

.sg-color-orange--light .sg-swatch-color {
  background-color: #ff9e16;
  color: #fff;
  text-shadow: 0 0 6px #757575; }
  .sg-color-orange--light .sg-swatch-color:before {
    content: "#ff9e16"; }

.sg-color-brandPink .sg-swatch-color {
  background-color: #f85656;
  color: #fff;
  text-shadow: 0 0 6px #757575; }
  .sg-color-brandPink .sg-swatch-color:before {
    content: "#f85656"; }

.sg-color-brandBlue .sg-swatch-color {
  background-color: #279ed2;
  color: #fff;
  text-shadow: 0 0 6px #757575; }
  .sg-color-brandBlue .sg-swatch-color:before {
    content: "#279ed2"; }

.sg-color-brandGreen .sg-swatch-color {
  background-color: #00a33d;
  color: #fff;
  text-shadow: 0 0 6px #757575; }
  .sg-color-brandGreen .sg-swatch-color:before {
    content: "#00a33d"; }

.sg-color-brandOrange .sg-swatch-color {
  background-color: #f74c00;
  color: #fff;
  text-shadow: 0 0 6px #757575; }
  .sg-color-brandOrange .sg-swatch-color:before {
    content: "#f74c00"; }

.sg-color-blue--light .sg-swatch-color {
  background-color: #2bace4;
  color: #fff;
  text-shadow: 0 0 6px #757575; }
  .sg-color-blue--light .sg-swatch-color:before {
    content: "#2bace4"; }

.sg-color-blue--lighter .sg-swatch-color {
  background-color: #7dcaeb;
  color: #535353; }
  .sg-color-blue--lighter .sg-swatch-color:before {
    content: "#7dcaeb"; }

.sg-color-yellow .sg-swatch-color {
  background-color: #ffd700;
  color: #fff;
  text-shadow: 0 0 6px #757575; }
  .sg-color-yellow .sg-swatch-color:before {
    content: "#ffd700"; }

/**
 * Components > icons
 * =================================
 * Used to show icons within the documentation site
 */
.icon-sg {
  display: inline-block;
  width: 50px;
  height: 50px;
  vertical-align: middle;
  margin-right: 10px;
  margin-bottom: 10px; }

.sg-favicon {
  padding: 5px; }
  .sg-favicon img {
    vertical-align: middle;
    margin-right: 15px; }

.sg-logos {
  background-color: #f5f5f5;
  padding: 6vh 10px; }
  .sg-logos > img {
    margin-top: 16px; }

/**
 * Components > list--tiled
 * =================================
 * Creates a tiled horizontal list
 * E.g. On the homepage  of the documentation site
 */
.list--tiled {
  margin-top: 16px; }
  .list--tiled > li {
    margin-bottom: 16px; }

.list--tiled-item {
  color: #fff; }
  .list--tiled-item:nth-child(1) .list--tiled-link {
    background-color: #f3cc2c; }
    .list--tiled-item:nth-child(1) .list--tiled-link:hover {
      background-color: #dfb60d; }
  .list--tiled-item:nth-child(2) .list--tiled-link {
    background-color: #eb6015; }
    .list--tiled-item:nth-child(2) .list--tiled-link:hover {
      background-color: #bd4d10; }
  .list--tiled-item:nth-child(3) .list--tiled-link {
    background-color: #396ab2; }
    .list--tiled-item:nth-child(3) .list--tiled-link:hover {
      background-color: #2d538b; }
  .list--tiled-item:nth-child(4) .list--tiled-link {
    background-color: #3da432; }
    .list--tiled-item:nth-child(4) .list--tiled-link:hover {
      background-color: #2e7d26; }
  .list--tiled-item:nth-child(5) .list--tiled-link {
    background-color: #7fc6ef; }
    .list--tiled-item:nth-child(5) .list--tiled-link:hover {
      background-color: #52b2e9; }
  .list--tiled-item:nth-child(6) .list--tiled-link {
    background-color: #f7b64e; }
    .list--tiled-item:nth-child(6) .list--tiled-link:hover {
      background-color: #f5a21d; }

.list--tiled-item--dark {
  color: #333; }

.list--tiled-link {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  font-family: "Ubuntu";
  font-size: 20px;
  font-size: 1.42857rem;
  color: currentColor;
  text-decoration: none;
  text-align: center; }
  @media (min-width: 48em) {
    .list--tiled-link {
      min-height: 300px; } }
  .list--tiled-link:hover {
    text-decoration: none;
    color: currentColor; }

/**
 * Components > logo
 * =================================
 * Extension styles specific to the documentation site logo
 */
.c-logo-fozzie {
  width: 45px;
  height: 45px;
  margin-top: -11.25px;
  margin-right: 8px; }
  @media (min-width: 48.01em) {
    .c-logo-fozzie {
      width: 60px;
      height: 60px;
      margin-top: -15px; } }

.c-logo > a {
  text-decoration: none; }

.c-logo-subText {
  font-style: italic;
  display: block;
  margin-top: -4px;
  margin-right: 4px;
  color: #d50525; }

/**
 * Components > Matrix
 * =================================
 * Used to extend the base table classes to define
 * something that looks like a browser matrix
 */
.c-matrix {
  border: 1px solid #cacaca; }
  .c-matrix th {
    background-color: #edf5ff; }
    .c-matrix th:first-child {
      border-radius: 4px 0 0; }
    .c-matrix th:last-child {
      border-radius: 0 4px 0 0; }
  .c-matrix td {
    border-top: 1px solid #cacaca; }
  .c-matrix tr:last-child td:last-child {
    border-radius: 0 0 4px; }

.c-matrix-gradeA {
  background-color: #f2fae2; }

.c-matrix-gradeB {
  background-color: #fff1df; }

.c-matrix-gradeC {
  background-color: #fee; }

/**
 * Components > themeToggle
 * =================================
 * The toggle button slideout for the documentation theme toggle
 */
.sg-themeToggle {
  position: fixed;
  bottom: 0;
  z-index: 100;
  right: 10px;
  padding: 10px;
  background-color: #fff;
  border-left: 1px solid #535353;
  border-right: 1px solid #535353;
  transform: translateY(100%);
  transition: transform ease-in-out 250ms; }
  .sg-themeToggle:before {
    content: 'Toggle Theme';
    position: fixed;
    bottom: 100%;
    left: -1px;
    right: -1px;
    transform-origin: 100% 100%;
    border: 1px solid #535353;
    border-bottom: 0;
    font-weight: 500;
    text-align: center;
    padding: 10px;
    border-radius: 8px 8px 0 0;
    background: inline("dist/img/icons/arrows/chevron.svg") #fff 90% center no-repeat; }
  .sg-themeToggle:hover {
    transform: translateX(0%); }

.sg-themeToggle-btn {
  min-width: 200px; }
