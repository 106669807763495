/**
 * Components > icons
 * =================================
 * Used to show icons within the documentation site
 */

.icon-sg {
    display: inline-block;
    width: 50px;
    height: 50px;
    vertical-align: middle;
    margin-right: 10px;
    margin-bottom: 10px;
}

.sg-favicon {
    padding: 5px;

    img {
        vertical-align: middle;
        margin-right: 15px;
    }
}


.sg-logos {
    background-color: $grey--offWhite;
    padding: 6vh 10px;

    & > img {
        margin-top: spacing(x2);
    }
}
